import { useEffect } from 'react'

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import { useSettings } from '~/providers/SettingsProvider'

import useCreateWishlist from '~/hooks/account/wishlist/useCreateWishlist'
import useGetWishlist from '~/hooks/account/wishlist/useGetWishlist'

export default function useAutoCreateWishlist() {
  const { wishlistEnabled } = useSettings()
  const { customerAccessToken } = useCustomerContext()
  const { data: wishlist, isSuccess: wishlistQueryComplete } = useGetWishlist()
  const { mutate: createWishlist } = useCreateWishlist()

  useEffect(() => {
    if (
      !wishlist?.id &&
      wishlistQueryComplete &&
      wishlistEnabled &&
      customerAccessToken
    ) {
      createWishlist({})
    }
  }, [wishlist])
}

import React, { DOMAttributes, forwardRef } from 'react'
import { UrlObject } from 'url'

import { Link, LinkProps } from '@unlikelystudio/react-abstract-components'
import { useIsHover } from '@unlikelystudio/react-hooks'

import Spinner from '~/components/Abstracts/Spinner'

import useDeviceType, { DeviceType } from '~/hooks/useDeviceType'

import { getCSSThemeClassName } from '~/utils/get-css-theme-classname'

export type BaseProps = Omit<DOMAttributes<any>, 'children'> & {
  className?: string
  href?: string | UrlObject
  children?: any
  theme?: string
  isLoading?: boolean
  withBackground?: boolean
  withBorder?: boolean
  withHover?: boolean
  disabled?: boolean
  isDiv?: boolean
  innerRef?: any
  negativeColor?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  cx?: (...args: any[]) => string
  css?: Record<string, string>
  spinnerClassName?: string
}

export type CtaProps = BaseProps | (LinkProps & BaseProps)

function Cta({
  className,
  children,
  href,
  withBackground,
  theme,
  onMouseEnter,
  onMouseLeave,
  withBorder,
  withHover,
  isLoading,
  isDiv,
  negativeColor,
  disabled,
  cx,
  innerRef,
  css,
  spinnerClassName,
  ...rest
}: CtaProps) {
  const [isHover, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
  })

  const device = useDeviceType()
  const themeClassName = getCSSThemeClassName(css, theme, { suffix: 'Theme' })

  const classNames = cx(className, {
    withBackground,
    withBorder,
    hovered: device === DeviceType.mobile || !withHover ? false : isHover,
    loading: isLoading,
    [`${themeClassName}`]: themeClassName,
    disabled,
  })
  const CtaChildren = (
    <>
      {isLoading && (
        <div className={cx(css.spinner, spinnerClassName)} key="spinner">
          <Spinner color={isHover ? theme : negativeColor} />
        </div>
      )}
      <div key="children">
        {!isLoading &&
          (typeof children === 'function' ? children({ isHover }) : children)}
      </div>
    </>
  )
  return href ? (
    <Link
      ref={innerRef}
      href={href}
      className={classNames}
      {...mouseEvents}
      {...rest}>
      {CtaChildren}
    </Link>
  ) : isDiv ? (
    <div ref={innerRef} className={classNames} {...mouseEvents} {...rest}>
      {CtaChildren}
    </div>
  ) : (
    <button
      className={classNames}
      ref={innerRef}
      {...mouseEvents}
      {...rest}
      disabled={disabled}>
      {CtaChildren}
    </button>
  )
}

Cta.defaultProps = {
  withBackground: true,
  withBorder: false,
  withHover: true,
}

function CtaForwarded(props, ref) {
  return <Cta innerRef={ref} {...props} />
}

export default forwardRef<HTMLAnchorElement | HTMLButtonElement, CtaProps>(
  CtaForwarded,
)

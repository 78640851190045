import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  cleanGoogleRecaptcha,
  injectGoogleReCaptchaScript,
  logWarningMessage,
} from './utils'

const GoogleRecaptchaError = {
  SCRIPT_NOT_AVAILABLE: 'Recaptcha script is not available',
} as const

type GoogleReCaptchaProviderProps = {
  reCaptchaKey?: string
  language?: string
  useRecaptchaNet?: boolean
  useEnterprise?: boolean
  scriptProps?: {
    nonce?: string
    defer?: boolean
    async?: boolean
    appendTo?: 'head' | 'body'
    id?: string
  }
  children: JSX.Element
}

export type GoogleReCaptchaConsumerProps = {
  executeRecaptcha?: (action?: string) => Promise<string>
  loadRecaptcha?: () => void
  cleanRecaptcha?: () => void
}

const GoogleReCaptchaContext = createContext<GoogleReCaptchaConsumerProps>({
  executeRecaptcha: () => {
    // This default context function is not supposed to be called
    throw Error(
      'GoogleReCaptcha Context has not yet been implemented, if you are using useGoogleReCaptcha hook, make sure the hook is called inside component wrapped by GoogleRecaptchaProvider',
    )
  },
  loadRecaptcha: () => {
    throw Error('No load recatpcha')
  },
  cleanRecaptcha: () => {
    throw Error('No clean recatpcha')
  },
})

const { Consumer: GoogleReCaptchaConsumer } = GoogleReCaptchaContext

export function GoogleReCaptchaProvider({
  reCaptchaKey,
  useEnterprise = false,
  useRecaptchaNet = false,
  scriptProps,
  language,
  children,
}: GoogleReCaptchaProviderProps) {
  const [greCaptchaInstance, setGreCaptchaInstance] = useState<null | {
    execute: (recaptchaKey: string, { action: string }) => Promise<any>
  }>(null)

  useEffect(() => {
    if (!reCaptchaKey) {
      logWarningMessage(
        '<GoogleReCaptchaProvider /> recaptcha key not provided',
      )

      return
    }
  }, [useEnterprise, useRecaptchaNet, scriptProps, language, reCaptchaKey])

  const onLoad = useCallback(() => {
    if (!window || !(window as any).grecaptcha) {
      logWarningMessage(
        `<GoogleRecaptchaProvider /> ${GoogleRecaptchaError.SCRIPT_NOT_AVAILABLE}`,
      )

      return
    }

    const grecaptcha = useEnterprise
      ? (window as any).grecaptcha.enterprise
      : (window as any).grecaptcha

    grecaptcha.ready(() => {
      setGreCaptchaInstance(grecaptcha)
    })
  }, [useEnterprise])

  const onError = useCallback(() => {
    logWarningMessage('Error loading google recaptcha script')
  }, [])

  const cleanRecaptcha = useCallback(() => {
    const scriptId = scriptProps?.id || 'google-recaptcha-v3'
    cleanGoogleRecaptcha(scriptId)
  }, [])

  const loadRecaptcha = useCallback(() => {
    injectGoogleReCaptchaScript({
      reCaptchaKey,
      useEnterprise,
      useRecaptchaNet,
      scriptProps,
      language,
      onLoad,
      onError,
    })
  }, [
    reCaptchaKey,
    useEnterprise,
    useRecaptchaNet,
    scriptProps,
    language,
    onLoad,
    onError,
  ])

  const executeRecaptcha = useCallback(
    async (action?: string) => {
      if (!greCaptchaInstance || !greCaptchaInstance.execute) {
        throw new Error(
          '<GoogleReCaptchaProvider /> Google Recaptcha has not been loaded',
        )
      }

      const result = await greCaptchaInstance.execute(reCaptchaKey, { action })

      return result
    },
    [greCaptchaInstance, reCaptchaKey],
  )

  const googleReCaptchaContextValue = useMemo(
    () => ({
      executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined,
      loadRecaptcha,
      cleanRecaptcha,
    }),
    [executeRecaptcha, greCaptchaInstance, loadRecaptcha, cleanRecaptcha],
  )

  return (
    <GoogleReCaptchaContext.Provider value={googleReCaptchaContextValue}>
      {children}
    </GoogleReCaptchaContext.Provider>
  )
}

export { GoogleReCaptchaConsumer, GoogleReCaptchaContext }

import { SortByValues } from '~/providers/FilterProvider'

export const ORDERING_DATA = () => [
  {
    type: 'order_by',
    values: [
      {
        value: SortByValues.DEFAULT,
      },
      {
        value: SortByValues.SUSTAINABILITY,
      },
      {
        value: SortByValues.PRICE_ASC,
      },
      {
        value: SortByValues.PRICE_DESC,
      },
    ],
  },
]

import { getQueryParams } from '~/utils/get-query-params'

function checkIfStoryblokPreview() {
  const queryParams = getQueryParams()

  if (queryParams?.[`_storyblok`] && queryParams?.[`secret`]) {
    if (queryParams?.[`secret`] === process.env.NEXT_PUBLIC_STORYBLOK_TOKEN) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const isStoryblokPreview = checkIfStoryblokPreview()

export { isStoryblokPreview }

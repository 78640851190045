import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import React, {
  MouseEventHandler,
  ReactNode,
  useContext,
  useEffect,
} from 'react'
import { GLOBAL_BREAKPOINTS } from '~/@types/breakpoints'
import { GlobalTextPreset } from '~/@types/text-preset'

import { CloseIcon } from '~/components/UI/Icons'

import { useStyle } from '~/providers/StyleProvider'
import { ToastContext } from '~/providers/ToastCenterProvider'

import useBreakpoint from '~/hooks/useBreakpoint'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ToastProps = {
  className?: string
  children?: ReactNode | ReactNode[]
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  onClose?: (e) => void
  uid: string
}

const ease = {
  type: 'ease',
  ease: 'easeInOut',
  duration: 1.1,
}

export const MOTION_TOAST_TRANSITION = {
  type: 'spring',
  damping: 25,
  stiffness: 150,
  layoutY: {
    ...ease,
    duration: 0.8,
  },
}

export function Toast({
  className,
  children,
  onMouseEnter,
  onMouseLeave,
  onClose,
}: ToastProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12GroteskMedium,
  })
  const isMobile = useBreakpoint(GLOBAL_BREAKPOINTS.MD)

  const toastVariant = {
    initialOpacity: { opacity: 0 },
    animateOpacity: {
      opacity: 1,
    },
    initial: { y: isMobile ? 64 : null, x: isMobile ? null : '100%' },
    animate: {
      y: isMobile ? 0 : null,
      x: isMobile ? null : '0%',
    },
    exit: {
      opacity: 0,
      transition: {
        delay: 0,
        ease: 'easeIn',
        duration: 0.2,
      },
    },
  }

  return (
    <m.div
      variants={toastVariant}
      initial={['initialOpacity', 'initial']}
      animate={['animateOpacity', 'animate']}
      exit="exit"
      layout="position"
      transition={MOTION_TOAST_TRANSITION}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(css.Toast, className, textStyle)}>
      <button className={css.close} onClick={onClose}>
        <CloseIcon className={css.icon} />
      </button>
      {children}
    </m.div>
  )
}

export type ToastCenterProps = {
  className?: string
}
export function ToastCenter({ className }: ToastCenterProps) {
  const { alerts, setAlerts } = useContext(ToastContext)

  useEffect(() => {
    return () => {
      setAlerts([])
    }
  }, [])

  const onClose = (e) => {
    setAlerts([])
  }

  return (
    <m.ul
      className={cx(css.ToastCenter, className, {
        hasToasts: !!(alerts?.length > 0),
      })}>
      <li className={cx(css.grid)}>
        <AnimatePresence>
          {alerts?.map?.((alert: ToastProps, index) => {
            return <Toast key={index} onClose={onClose} {...alert} />
          })}
        </AnimatePresence>
      </li>
    </m.ul>
  )
}

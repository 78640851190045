import { GlobalThemeColors, ThemePageColors } from '~/@types/colors'

import { useGlobalData } from '~/providers/GlobalDataProvider'

export type UseTextColorFromThemeReturnType = {
  textColor?: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
}

function useTextColorFromTheme(
  componentTheme?: ThemePageColors,
): UseTextColorFromThemeReturnType {
  const { theme } = useGlobalData()

  const globalTheme = theme?.page ?? GlobalThemeColors.Black
  let textColor

  switch (componentTheme ?? globalTheme) {
    case GlobalThemeColors.Black:
      textColor = GlobalThemeColors.White
      break
    case GlobalThemeColors.White:
      textColor = GlobalThemeColors.Black
      break
  }

  return { textColor }
}

export default useTextColorFromTheme

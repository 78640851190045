import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import ConditionalCrawlerDetector from '~/components/Abstracts/ConditionalCrawlerDetector'
import HackImageLcp from '~/components/Abstracts/HackImageLcp'
import PanelManager from '~/components/Abstracts/Panels/PanelManager'
import PopInManager from '~/components/Abstracts/PopInManager'
import Navigation from '~/components/Navigation/Navigation'
import { useNavBannerContext } from '~/components/Navigation/NavigationBanner'
import { CustomAlerts } from '~/components/UI/Alerts'
import { ToastCenter } from '~/components/UI/Toast'

import { useBanner } from '~/providers/BannerProvider'

import useAutoCreateWishlist from '~/hooks/account/wishlist/useAutoCreateWishlist'
import useMarketPopin from '~/hooks/useMarketPopin'
import useStoryblok from '~/hooks/useStoryblok'

import { DefaultPageData } from '~/data/page-data/serializer'

import Meta from '../Abstracts/Meta'
import { PAGES_WITHOUT_FOOTER_BREADCRUMB } from './pages-without-footer-breadcrumb'
import {
  SLICES_WITHOUT_MARGIN_BOTTOM,
  SLICES_WITHOUT_MARGIN_TOP,
} from './slices-without-margin'
import css from './styles.module.scss'

const DynamicFooter = dynamic(() => import('~/components/UI/Footer'))
const DyanmicPopInNewsletterHandler = dynamic(
  () => import('~/components/UI/PopInNewsletterHandler'),
)

const DynamicPushNewsletter = dynamic(
  () => import('~/components/UI/PushNewsletter'),
)

const cx = classnames.bind(css)

export type PageProps = DefaultPageData & {
  children?: JSX.Element | JSX.Element[]
}
function Page({
  children,
  metas,
  global,
  slices,
  preview,
  document,
}: PageProps) {
  useStoryblok(preview)
  useAutoCreateWishlist()

  const withoutMarginTop =
    SLICES_WITHOUT_MARGIN_TOP.indexOf(slices?.[0]?.type) > -1
  const withoutMarginBottom =
    SLICES_WITHOUT_MARGIN_BOTTOM.indexOf(slices?.[slices.length - 1]?.type) > -1
  const withBreadcrumb =
    PAGES_WITHOUT_FOOTER_BREADCRUMB.indexOf(document?.type ?? ('' as any)) ===
    -1
  const { isVisible: isBannerVisible } = useBanner()
  const isHomepage = metas?.type === STORYBLOK_TYPES.HOMEPAGE

  const [isVisible] = useNavBannerContext()

  useMarketPopin(global?.popins?.markets)

  return (
    <>
      <Meta {...metas} />
      <Navigation
        {...global?.navigation}
        banner={global?.navigationBanner}
        isHomePage={isHomepage}
        locale={metas?.locale}
      />
      <HackImageLcp />

      {/* Adding a wrapper to make the NewsletterTrigger positionned much better */}
      <div className={css.PageWrapper}>
        {!document?.preview && (
          <ConditionalCrawlerDetector>
            <DyanmicPopInNewsletterHandler />
          </ConditionalCrawlerDetector>
        )}
        <main
          className={cx(css.Page, {
            withoutMarginTop,
            withoutMarginBottom,
            forcedMarginFromBanner: isBannerVisible,
            withBanner: !isHomepage && isVisible,
          })}>
          {children}
        </main>
      </div>

      <DynamicPushNewsletter {...global?.newsletter} />
      <DynamicFooter {...global?.footer} withBreadCrumb={withBreadcrumb} />

      <ConditionalCrawlerDetector>
        <>
          <PopInManager />
          <CustomAlerts />
          <ToastCenter />
          <PanelManager />
        </>
      </ConditionalCrawlerDetector>
    </>
  )
}

Page.defaultProps = {}

export default Page

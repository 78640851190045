import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { NavigationLogoProps } from '~/@types/navigation'

import { Link } from '@unlikelystudio/react-abstract-components'

import Logotype from '~/components/UI/LogoTypes/logo.svg'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function NavigationLogo({
  className,
  theme,
  locale,
  ...rest
}: NavigationLogoProps) {
  const t = useTranslate()

  const linkProps = {
    className: cx(css.NavigationLogo, className),
    title: t(GENERAL.LOGO_TITLE),
    href: `/${locale}`,
  }

  return (
    <Link {...linkProps} {...rest}>
      <Logotype
        className={cx(css.NavigationLogo, className, css?.[`${theme}-theme`])}
      />
    </Link>
  )
}

NavigationLogo.defaultProps = {}

export default NavigationLogo

import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalTextPreset } from '~/@types/text-preset'

import { Alerts } from '@unlikelystudio/react-abstract-components'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export function CustomAlerts() {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Text14GroteskRegular,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Grotesk,
  })

  const t = useTranslate()

  return (
    <Alerts
      className={css.Alerts}
      alertClassName={css.Alert}
      animate={{
        opacity: [0, 1],
        y: ['100%', '0%'],
        transition: {
          duration: 5,
          times: [0, 0.2],
          ease: ['easeOut'],
        },
      }}
      exit={{ opacity: 0, transition: { duration: 0.5, ease: 'easeOut' } }}
      closeButtonClassName={css.CloseIcon}
      titleClassName={titleStyle}
      textClassName={cx(textStyle, css.text)}
    />
  )
}

import { ALERT_PRESETS } from '~/@types/alert'
import { GlobalGender } from '~/@types/gender'

export const POPIN = {
  CONTACT_US: 'popin.contact_us',
  SEND_MESSAGE: 'popin.send_message',
  MARKET_COUNTRIES: 'popin.market_countries',
  MARKET_LANGS: 'popin.market_langs',
  MARKET_CHOOSE_COUNTRY: 'popin.market_choose_country',
  MARKET_CHOOSE_LANG: 'popin.market_choose_lang',
}

export const GENERAL = {
  ARTICLE: 'general.article',
  BLOG: 'general.blog',
  BURGER_ARIA_LABEL: 'general.burger_aria_label',
  CATEGORIES: 'general.categories',
  CONTINUE: 'general.continue',
  CONTACT: 'general.contact',
  CONTAINED_IN: 'general.contained_in',
  COPYRIGHT: 'general.copyright',
  DISCOVER_MORE: 'general.discover_more',
  ERROR: 'general.error',
  FAQ_PAGE: 'general.faq_page',
  FAQ_PAGE_ITEM: 'general.faq_page_item',
  FREE: 'general.free',
  FROM: 'general.from',
  HOME_PAGE: 'general.homepage',
  PAGE: 'general.page',
  LEGAL: 'general.legal',
  LEGAL_ITEM: 'general.legal_item',
  LOGO_TITLE: 'general.logo_title',
  MENU: 'general.menu',
  SEARCH: 'general.search',
  RANKING_DISCOVER: 'general.ranking_discover',
  SIZE: 'general.size',
  SHOPS: 'general.shops',
  WISHLIST: 'general.wishlist',
  EMAIL: 'general.email',
}

export const GENDER = {
  // When a customer hasn't specified their gender
  [GlobalGender.UNKNOWN]: 'gender.unknown',
  [GlobalGender.MALE]: 'gender.male',
  [GlobalGender.FEMALE]: 'gender.female',
  [GlobalGender.OTHER]: 'gender.other',
}

export const PRODUCT = {
  INTENSITY: 'product.intensity',
  SHOP_NOW: 'product.shop_now',
  NEAREST_STORE: 'product.nearest_store',
  NEW: 'product.new',
  SOLDOUT: 'product.soldout',
  SIZE_UNIQUE: 'product.size_unique',
  SIZE_THREE: 'product.size_three',
  SIZE_ADJUSTABLE: 'product.size_adjustable',
  SIZE_ADJUSTABLE_NOT_POSSIBLE: 'product.adjustable_impossible',
  SIZE_AVAILABLE: 'product.size_available',
  SPECIAL_ORDER: 'product.special_order',
  EXPAND_PRODUCT_DETAILS: 'product.expand_product_details',
  EXPAND_PRODUCT_SHOPS: 'product.expand_product_shops',
  RECOMMENDED_PRODUCT: 'product.recommended_products',
  RECENTLY_VIEWED: 'product.recently_viewed',
  TAXES_US: 'product.taxes_us',
  TAXES_UK: 'product.taxes_uk',
}

export const PRODUCT_STATUS = {
  NEW: 'product_status.new',
  BACK_IN_STOCK: 'product_status.back_in_stock',
  EXCLUSIVITY: 'product_status.exclusivity',
  UNIQUE_PIECE: 'product_status.unique_piece',
}

export const FAQ = {
  VIEW_ALL: 'faq.view_all',
}

export const FORM = {
  ACCEPT_MARKETING: 'form.accept_marketing',
  ACCEPT_TERMS: 'form.accept_terms',
  ADDRESS_NAME: 'form.address_name',
  BIRTH_DATE: 'form.birth_date',
  CATEGORY: 'form.category',
  CITY: 'form.city',
  CREATE_ADDRESS: 'form.create_address',
  CONFIRM_PASSWORD: 'form.confirm_password',
  CONTACT_SUCCESS: 'form.contact_success',
  COUNTRY: 'form.country',
  DEFAULT_ADDRESS: 'form.default_address',
  EMAIL: 'form.email',
  FEMALE: 'form.female',
  FIRST_NAME: 'form.first_name',
  GENDER: 'form.gender',
  INFOS_ADDRESS: 'form.infos_address',
  LAST_NAME: 'form.last_name',
  MALE: 'form.male',
  MANDATORY: 'form.mandatory',
  MESSAGE: 'form.message',
  NEW_PASSWORD: 'form.new_password',
  NEWSLETTER_EMAIL: 'form.newsletter_email',
  NEWSLETTER_SUCCESS: 'form.newsletter_success',
  PASSWORD: 'form.password',
  PASSWORD_RESTRICTION: 'form.password_restriction',
  PRIVACY_POLICY: 'form.privacy_policy',
  REGION: 'form.region',
  RESET: 'form.reset',
  REPEAT_PASSWORD: 'form.repeat_password',
  REPEAT_NEW_PASSWORD: 'form.repeat_password',
  SAVE: 'form.save',
  SEARCH: 'form.search',
  SAVE_PREFERENCES: 'form.save_preferences',
  SEND: 'form.send',
  STATE: 'form.state',
  STREET_NAME: 'form.street_name',
  SUBJECT: 'form.subject',
  SUBMIT: 'form.submit',
  TEL: 'form.tel',
  TITLE: 'form.title',
  ZIP_CODE: 'form.zip_code',
}

export const ACCOUNT = {
  ADD_ADDRESS: 'account.add_address',
  ALREADY_REGISTERED: 'account.already_registered',
  EDIT_ADDRESS: 'account.edit_address',
  DELETE_ADDRESS: 'account.delete_address',
  CHANGE_PASSWORD: 'account.change_password',
  CLOSE: 'account.close_details',
  CUSTOMER: 'account.customer',
  DATE: 'account.date',
  DEFAULT_ADDRESS: 'account.default_address',
  DELETE: 'account.delete',
  DELETE_ACCOUNT: 'account.delete_account',
  DELIVERY: 'account.delivery_status',
  DETAILS: 'account.details',
  ORDER_DETAILS: 'account.order_details',
  EDIT: 'account.edit',
  FIRST_VISIT: 'account.first_visit',
  FORGOT_PASSWORD: 'account.forgot_password',
  GREETING: 'account.greeting, %{name}.',
  LOGIN: 'account.login',
  LOGOUT: 'account.logout',
  MY_ACCOUNT: 'account.my_account',
  ORDER: 'account.order',
  ORDER_ITEMS: 'account.items',
  ORDER_SHORT_QTY: 'account.order_qty',
  ORDER_PRICE: 'account.order_price',
  ORDER_SIZE: 'account.order_size',
  ORDER_BILLING_DETAILS: 'account.order_billing_details',
  ORDER_BILLING_ADDRESS: 'account.order_billing_address',
  ORDER_SHIPPING_ADDRESS: 'account.order_shipping_address',
  ORDER_SHIPPING_PRICE: 'account.order_shipping_price',
  ORDER_STATUS: 'account.order_status',
  ORDER_NUMBER: 'account.order_number',
  QUANTITY: 'account.quantity',
  REGISTER: 'account.register',
  RETURN_REPLACE: 'account.return_replace',
  TOTAL: 'account.total',
  TRACK_PACKAGE: 'account.track_package',
  VIEW: 'account.view_details',
  VIEW_ORDER: 'account.view_order',
}

export const CTA = {
  ADD: 'cta.add',
  ADD_TO_CART: 'cta.add_to_cart',
  CART: 'cta.cart',
  COPY: 'cta.copy',
  BACK: 'cta.back',
  BACK_TO_SHOPPING: 'cta.back_to_shopping',
  CONTINUE_TO_CHECKOUT: 'cta.continue_to_checkout',
  FILTER_SORT: 'cta.filter_sort',
  CLOSE: 'cta.close',
  LEARN_MORE: 'cta.learn_more',
  NEXT: 'cta.next',
  OUT_OF_STOCK: 'cta.out_of_stock',
  PREVIOUS: 'cta.previous',
  REDUCE: 'cta.reduce',
  REMOVE_FROM_CART: 'cta.remove',
  CONFIRM_CART: 'cta.confirm_cart',
  CONTINUE_SHOPPING: 'cta.continue_shopping',
  ENABLE_ACCOUNT: 'cta.enable_account',
  MENU_LABEL: 'cta.menu_label',
  SEE_PRODUCT: 'cta.see_product',
  SHOW_ALL: 'cta.show_all',
  READ_MORE: 'cta.read_more',
  READ_LESS: 'cta.read_less',
  WHY_ASK: 'cta.why_ask',
  QUICKBUY: 'cta.quickbuy',
  QUICKBUY_STOCK_ALERT: 'cta.quickbuy_stock_alert',
  QUICKBUY_SPECIAL_ORDER: 'cta.quickbuy_special_order',
  VIEW_MORE: 'cta.view_more',
  VIEW_ALL: 'cta.view_all',
  LOGIN: 'cta.login',
  REGISTER: 'cta.register',
  VALIDATE: 'cta.validate',
  CREATE_PASSWORD: 'cta.create_password',
  RESET_PASSWORD: 'cta.reset_password',
  VIEW_LESS: 'cta.view_less',
  SIZE_GUIDE: 'cta.size_guide',
  STOCK_ALERT: 'cta.stock_alert',
  SPECIAL_ORDER: 'cta.special_order',
  PRE_ORDER: 'cta.pre_order',
  CANCEL: 'cta.cancel',
  REMOVE: 'cta.remove',
}

export const CART = {
  COMPLETE_UPSELLS: 'cart.complete_upsells',
  DISCOUNT_CODE: 'cart.discount_code',
  PRICE_TOTAL: 'cart.price_total',
  PRICE_WITH_VAT: 'cart.price_with_vat',
  PRE_ORDER: 'cart.preorder',
  UNIQUE_SIZE: 'cart.unique_size',
  ADJUSTED_SIZE: 'cart.adjusted_size',
  NORMAL_SIZE: 'cart.normal_size',
  SUBTOTAL: 'cart.subtotal',
  LAST_VIEWED: 'cart.last_viewed',
  REMOVE_TITLE: 'cart.remove_title',
  SHIPPING: 'cart.shipping',
  SHIPPING_CALCULATED: 'cart.shipping_calculated',
  TAXES_INCLUDED: 'cta.taxes_included',
  TAXES_EXCLUDED: 'cta.taxes_excluded',
  TAXES_UK: 'cart.taxes_uk',
  TAXES_US: 'cart.taxes_us',
}

export const PANEL = {
  CLOSE: 'panel.close',
}

export const FILTER = {
  CLOSE: 'filters.close',
  PANEL_TITLE: 'filters.panel_title',
  NO_RESULTS: 'filters.no_results',
  SELECT_LABEL: (type: string) => `filters.select_${type}`,
  FACET: (type: string) => `filter.facet.${type?.toLocaleLowerCase()}`,
  SUBMIT: 'filters.submit',
  VIEW_ALL: 'filters.view_all',
  RESET: 'filters.reset',
}

export const FILTERED_PAGE_HEADER_TITLES = {
  ORIGIN: 'filtered_page_header.origin',
  MATERIALS: 'filtered_page_header.materials',
  RESPONSIBILITIES: 'filtered_page_header.responsibilities',
  STORE: 'filtered_page_header.store',
  FAMILY: 'filtered_page_header.family',
  COLORS: 'filtered_page_header.colors',
}

export const ERROR = {
  REQUIRED: 'error.required',
  TEL: 'error.tel',
}

export const SEARCH = {
  COUNT_SINGLE_RESULT: 'search.count_single_result',
  COUNT_MULTIPLE_RESULTS: 'search.count_multiple_results',
  PLACEHOLDER: 'search.placeholder',
  MOST_POPULAR: 'search.most_popular',
  COUNT_AVAILABILITY: 'search.count_availability',
  EMPTY_RESULTS: 'search.search_no_results',
  LOAD_MORE: 'search.search_load_more',
}

export const ORDER = {
  DELIVERY_TRACK: 'order.delivery_track',
  DELIVERY_AWAITING_SHIPMENT: 'order.delivery_awaiting_shipment',
  FREE_DELIVERY: 'order.free_delivery',

  /* Shopify order status */
  STATUS_UNFULFILLED: 'order.status_unfulfilled',
  STATUS_PARTIALLYFULFILLED: 'order.status_partially_fulfilled',
  STATUS_FULFILLED: 'order.status_fulfilled',
  STATUS_RESTOCKED: 'order.status_restocked',
  STATUS_PENDINGFULFILLMENT: 'order.status_pending_fulfillment',
  STATUS_OPEN: 'order.status_open',
  STATUS_INPROGRESS: 'order.status_in_progress',
  STATUS_ONHOLD: 'order.status_on_hold',
  STATUS_SCHEDULED: 'order.status_scheduled',
  STATUS_UNKNOWN: 'order.status_unknown',

  /* Shopify order payment status */
  PAYMENT_PENDING: 'order.payment_pending',
  PAYMENT_AUTHORIZED: 'order.payment_AUTHORIZED',
  PAYMENT_PARTIALLYPAID: 'order.payment_partially_paid',
  PAYMENT_PARTIALLYREFUNDED: 'order.payment_partially_refunded',
  PAYMENT_VOIDED: 'order.payment_voided',
  PAYMENT_PAID: 'order.payment_paid',
  PAYMENT_REFUNDED: 'order.payment_refunded',
}

export const ALERT = {
  ERROR_TITLE: 'alert.error_title',
  SUCCESS_TITLE: 'alert.success_title',
  CHECKOUT_NOTE_ERROR: 'alert.note_error',
  COPY_SUCCESS_TITLE: 'alert.copy_success',
}

export const LABEL = {
  ALL: 'label.all',
}

ALERT_PRESETS?.forEach(
  (alert) => (ALERT[alert] = `alert.${alert.toLowerCase()}`),
)

export const LANG_SWITCHER = {
  PANEL_TITLE: 'lang_switcher.panel_title',
  PANEL_COUNTRIES_CURRENCIES: 'lang_switcher.panel_countries_currencies',
  PANEL_COUNTRIES_MARKETS: 'lang_switcher.panel_countries_markets',
  LANGUAGE: (lang = 'en') => `language.${lang?.toLocaleLowerCase()}`,
  LANGUAGE_SYMBOL: (lang = 'en') =>
    `language_symbol.${lang?.toLocaleLowerCase()}`,
  CURRENCY_SYMBOL: (currency = 'usd') =>
    `currency_symbol.${currency?.toLocaleLowerCase()}`,
}

export const BREADCRUMB = {
  ROOT_CATEGORY: 'breadcrumb.root_category',
  DESIGNERS_LIST_PAGE: 'breadcrumb.designers_list',
  METAL_STONE_LIST_PAGE: 'breadcrumb.metal_stone_list_page',
  BLOG_LIST_PAGE: 'breadcrumb.blog_list_page',
  PRESS_LIST_PAGE: 'breadcrumb.press_list_page',
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { COOKIE_KEYS } from '~/lib/constants'
import {
  requestNextApiEndpoint,
  UNLIKELY_WISHLIST_GET_PARAMS,
  UNLIKELY_WISHLIST_GET_PUBLIC_WISHLIST_PARAMS,
} from '~/lib/handle-api'

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import { useSettings } from '~/providers/SettingsProvider'

import useGetWishlistQueryKey from '~/hooks/account/wishlist/useGetWishlistQueryKey'
import useCurrency from '~/hooks/useCurrency'
import useLocale from '~/hooks/useLocale'

// Getting the simpleql types out of the package in order to keep the types for the mutation response
type GraphQLError = {
  message: string
  locations: { line: number; column: number }[]
  path: string[]
  [key: string]: any
}

type GraphQLResponse<T> = {
  data?: T
  errors?: GraphQLError[]
  extensions?: any
  status: number
  [key: string]: any
}

export type WishlistProduct = {
  id: string
  uuid: string
  variantId: string
  wishlistProductId?: string
  shop_id: string
  customer_id: string
  wishlist_id: string
  name?: string
  prismic_id: string
  shopify_admin_id: string
  created_at?: string
  updated_at?: string
  variants?: any[]
  rootProductId?: string
}
export type Wishlist = {
  created_at: string
  customer_id: string
  id: string
  isPublic: boolean
  products: WishlistProduct[]
  public_id: string
  shop_id: string
  title: string
  updated_at: string
  displayName: string
}

export type GetWishlistMutationResponse = GraphQLResponse<Wishlist>

export default function useGetWishlist(
  publicID?: string,
  options?: UseQueryOptions<any, any, any>,
) {
  const wishlistQueryKeys = useGetWishlistQueryKey()
  const currency = useCurrency()
  const locale = useLocale()
  const { wishlistEnabled } = useSettings()
  const { customerAccessToken } = useCustomerContext()

  return useQuery<Wishlist>(
    wishlistQueryKeys,
    async () => {
      const querySetting = publicID
        ? UNLIKELY_WISHLIST_GET_PUBLIC_WISHLIST_PARAMS
        : UNLIKELY_WISHLIST_GET_PARAMS

      const queryParams = publicID
        ? {
            payload: { publicID },
          }
        : {
            accessToken: customerAccessToken,
            queryParams: { locale },
          }

      //If we have no public Id, customer must be logged in and wishlist must be enabled
      if ((!customerAccessToken || !wishlistEnabled) && !publicID) return null

      const wishlist = await requestNextApiEndpoint(querySetting, queryParams)

      return {
        ...wishlist,
        products:
          wishlist?.products?.map((product) => {
            return {
              ...product,
              uuid: product?.prismic_id,
              variantId: product?.shopify_admin_id,
            }
          }) ?? null,
      }
    },
    {
      ...options,
      onSuccess: (wishlist) => {
        const wishlistId = wishlist?.id

        if (wishlistId) {
          Cookies.set(`${COOKIE_KEYS.wishlistId(currency, locale)}`, wishlistId)
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  )
}

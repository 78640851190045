import classnames from 'classnames/bind'
import { useEffect, useMemo, useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { TRACKING_EVENTS } from '~/lib/constants'

import { FiltersPanelProps } from '~/components/Panels/FiltersPanel'
import { ORDERING_DATA } from '~/components/Panels/FiltersPanel/data'
import Filter from '~/components/UI/FiltersForm/Filter'
import SquaredCta from '~/components/UI/SquaredCta'

import {
  FacetsFilters,
  FACETS_ORDER,
  getMinMaxValuesFromFacet,
  NumericFilters,
  OrderingFilters,
  SortByValues,
  useFilter,
} from '~/providers/FilterProvider'
import { usePagination } from '~/providers/PaginationProvider'
import { usePanel } from '~/providers/PanelProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useFormWithMutation from '~/hooks/useFormWithMutation'

import deepEqual from '~/utils/deep-equal'

import { FILTER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export function getResetFieldValue(field: string, facets) {
  switch (field) {
    case OrderingFilters.ORDER_BY:
      return SortByValues.DEFAULT
    case NumericFilters.PRICE:
      return JSON.stringify(
        getMinMaxValuesFromFacet(NumericFilters.PRICE, facets),
      )
    default:
      return []
  }
}

function FiltersForm({ className, shouldReset, onReset }: FiltersPanelProps) {
  const t = useTranslate()
  const { removeCurrent } = usePanel()
  const { setCurrentPage } = usePagination()
  const { filters, facets, setFilters } = useFilter()
  const tracker = useTracker()

  const handleSubmit = (props) => {
    // Update filters if deep comparison is different

    if (!deepEqual(filters, props)) {
      // Reset current page to 1 on filters submit
      setCurrentPage(1)

      if (deepEqual(props, resetValues)) {
        filtersToSetOnPanelClose.current = resetValues
        setFilters(filtersToSetOnPanelClose.current)
      } else {
        setFilters(filtersToSetOnPanelClose.current)
      }
    }

    tracker.emit(TRACKING_EVENTS.FILTERS_SUBMIT, {
      value: filtersToSetOnPanelClose.current?.[OrderingFilters.ORDER_BY],
    })

    removeCurrent()
  }

  const { form, onSubmit } = useFormWithMutation(handleSubmit)

  const values = form.getValues()
  const resetValues = Object.keys(values)?.reduce((acc, field) => {
    return {
      ...acc,
      [field]: getResetFieldValue(field, facets),
    }
  }, {})
  const { watch, reset } = form

  useEffect(() => {
    if (shouldReset) {
      const values = form.getValues()
      form.reset(
        Object.keys(values)?.reduce((acc, field) => {
          return {
            ...acc,
            [field]: getResetFieldValue(field, facets),
          }
        }, {}),
      )
      onReset?.()
    }
  }, [shouldReset])

  // Watch form to get the latest user selection
  const currentFieldsSelectedByUser = watch()
  // Keep a ref of the current user selection to set filters on panel close
  const filtersToSetOnPanelClose = useRef(filters)

  useEffect(() => {
    if (
      !deepEqual(filtersToSetOnPanelClose.current, currentFieldsSelectedByUser)
    ) {
      filtersToSetOnPanelClose.current = currentFieldsSelectedByUser
    }

    // Update the user selection ref every time they change a field
  }, [currentFieldsSelectedByUser])

  useEffect(() => {
    // Update filters on mount
    reset({ ...{ order_by: SortByValues.DEFAULT }, ...filters })

    filtersToSetOnPanelClose.current = filters

    return () => {
      /**
       * Only set the filters provider when the panel is closing as this indicated the user selection is complete
       * This cleanup function needs to stay dependanceless useEffect to prevent it from being executing each time a dep changes
       */
      if (!deepEqual(filtersToSetOnPanelClose.current, filters)) {
        setFilters?.(filtersToSetOnPanelClose.current)
      }
    }
  }, [])

  const orderedAndFilteredFacets = useMemo(() => {
    return (
      facets
        ?.filter(
          (item) =>
            item?.type !== FacetsFilters.SCORE &&
            item?.type !== FacetsFilters.IS_ARCHIVES &&
            item?.type !== FacetsFilters.HIDDEN_SELECTIONS &&
            item?.type !== FacetsFilters.SELECTIONS &&
            item?.type !== FacetsFilters.SUSTAINABILITY,
        )
        // Avoid showing price selection if there's only one price accross all displayed products
        ?.filter((item) => {
          if (item?.type !== NumericFilters.PRICE) return true
          const reducedPrices = item?.values.reduce((acc, { value }) => {
            if (acc.indexOf(value) === -1) {
              acc.push(value)
            }
            return acc
          }, [])
          return reducedPrices.length > 1
        })
        // Reorder filters following the FACETS_ORDER array
        ?.sort(
          (a, b) =>
            FACETS_ORDER.indexOf(a?.type) - FACETS_ORDER.indexOf(b?.type),
        )
    )
  }, [facets])

  const initialFilters = useMemo(
    () => [
      ...(orderedAndFilteredFacets ?? []),
      ...(ORDERING_DATA()?.map((entry) => ({
        ...entry,
        values: entry?.values?.map((value) => ({
          ...value,
          label: t(FILTER.FACET(value?.value)),
        })),
      })) ?? []),
    ],
    [facets],
  )

  return (
    <FormProvider {...form}>
      <form className={cx(css.Form, className)} onSubmit={onSubmit}>
        <Filter
          className={cx(css.filtersList)}
          filtersGroups={initialFilters}
        />
        <div className={cx(css.bottom)}>
          <SquaredCta
            type="submit"
            className={cx(css.submit)}
            theme={GlobalThemeColors.Black}
            textPreset={GlobalTextPreset.Cta12Grotesk}
            withBackground>
            {t(FILTER.SUBMIT)}
          </SquaredCta>
        </div>
      </form>
    </FormProvider>
  )
}

export default FiltersForm

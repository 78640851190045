import { useTranslate } from 'react-polyglot'
import { GlobalCheckboxPreset } from '~/@types/checkbox-preset'

import { CheckboxProps } from '~/components/Abstracts/Form/Checkbox'

import { FORM } from '~/data/dictionary'

const checkboxPresets = {
  [GlobalCheckboxPreset.DefaultAddress]: {
    required: false,
    name: 'isDefaultAddress',
    id: 'isDefaultAddress',
    label: FORM.DEFAULT_ADDRESS,
  },
  [GlobalCheckboxPreset.AcceptsMarketing]: {
    required: false,
    name: 'acceptsMarketing',
    id: 'acceptsMarketing',
    label: FORM.ACCEPT_MARKETING,
  },
  [GlobalCheckboxPreset.AcceptsTerms]: {
    required: true,
    name: 'acceptsTerms',
    id: 'acceptsTerms',
    label: FORM.ACCEPT_TERMS,
  },
} as const

export type TCheckboxPresets = keyof typeof checkboxPresets

export default function useCheckboxPreset<T extends CheckboxProps>(
  key: TCheckboxPresets,
  args: T,
): Partial<T> {
  const t = useTranslate()
  const currentPreset = checkboxPresets?.[key]

  if (!checkboxPresets[key]) return args

  return {
    ...currentPreset,
    label: args?.label || t(currentPreset?.label),
    ...args,
  }
}

import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import multistore from '~/../config/multistore.json'
import { DEFAULT_LOCALE, SINGLE_ROUTES, TRACKING_EVENTS } from '~/lib/constants'
import { ANALYTICS_PAGE_TYPES, STORYBLOK_TYPES } from '~/lib/storyblok-types'

import {
  useGetCustomer,
  useGetCustomerOrders,
} from '@unlikelystudio/react-ecommerce-hooks'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useCurrency from '~/hooks/useCurrency'
import useLang from '~/hooks/useLang'
import useLocale from '~/hooks/useLocale'

import { getReferenceIdFromAdminId } from '~/utils/shopify-ids'

declare global {
  interface Window {
    dataLayer: unknown[]
    OnetrustActiveGroups: any
    OneTrust: any
  }
}

export default function useGTMTracking() {
  const router = useRouter()
  const tracker = useTracker()
  const { document, tracking } = useGlobalData()
  const eventsQueue = useRef([])
  const locale = useLocale()
  const lang = useLang()
  const currency = useCurrency()
  const { data: customer, isFetching: isFetchingCustomer } = useGetCustomer()
  const { data: orders, isFetching: isFetchingOrders } = useGetCustomerOrders({
    ordersAmount: 20,
  })

  const getUserData = () => {
    const ordersAmounts =
      orders?.pages
        ?.map((page) =>
          page?.map(
            (pageOrder) => Number(pageOrder?.currentTotalPrice.amount) ?? 0,
          ),
        )
        .flat() ?? []
    const ordersTotalAverageAmount =
      ordersAmounts.length > 0
        ? ordersAmounts?.reduce(
            // trick to avoid adding floating numbers
            // Multiply by 100 to turn floating cents to int
            // We then divide by 100 to get the correct cents value
            (acc, curr) => acc + curr * 100,
            0,
          ) /
          100 /
          ordersAmounts?.length
        : null

    return {
      loginStatus: customer ? 'logged' : 'unlogged',
      shippingZone:
        multistore[locale]?.market ?? multistore[DEFAULT_LOCALE]?.market,
      userOrdersCount: ordersAmounts.length,
      userOrderAvgAmount: ordersTotalAverageAmount,
      userEmail: customer?.email ? customer?.email : null,
      userId: customer?.id ? getReferenceIdFromAdminId(customer?.id) : null,
    }
  }

  const getPageData = (): {
    pageType?: string
    page_title?: string
    page_path?: string
  } => {
    const uid = router.query.uids ?? router.query.uid
    const route = router.route
    const realPath = router.asPath

    if (!document || document?.type === STORYBLOK_TYPES.ERROR_PAGE) {
      return {
        pageType: '404',
        page_title: `404`,
      }
    }

    switch (route) {
      case '/':
      case 'global':
        return {
          pageType: ANALYTICS_PAGE_TYPES.HOMEPAGE,
          page_title: 'homepage',
        }
      case '/products/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.FICHE_PRODUIT,
          page_title: `${uid}`,
        }
      case '/designers/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.DESIGNER,
          page_title: `${uid}`,
        }
      case '/designers':
        return {
          pageType: ANALYTICS_PAGE_TYPES.DESIGNER_LIST,
          page_title: `Designers List`,
        }
      case '/journal/category/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.ARTICLE_CATEGORY,
          page_title: `${uid}`,
        }
      case '/journal/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.ARTICLE,
          page_title: `${uid}`,
        }
      case '/journal':
        return {
          pageType: ANALYTICS_PAGE_TYPES.JOURNAL,
          page_title: 'Journal',
        }
      case '/category':
        return {
          pageType: ANALYTICS_PAGE_TYPES.CATEGORY,
          page_title: tracking?.trackingTitle ?? 'Root Category',
        }
      case '/category/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.CATEGORY,
          page_title: tracking?.trackingTitle ?? `Category ${uid}`,
        }
      case '/cart':
        return {
          pageType: ANALYTICS_PAGE_TYPES.CART,
          page_title: `cart`,
        }
      case '/legal/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.LEGAL,
          page_title: `${uid}`,
        }
      case '/archives':
        return {
          pageType: ANALYTICS_PAGE_TYPES.ARCHIVES,
        }
      case '/new-in':
        return {
          pageType: ANALYTICS_PAGE_TYPES.NEW,
        }
      case '/metals-stones/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.METALS_STONES,
          page_title: `${uid}`,
        }
      case '/metals-stones':
        return {
          pageType: ANALYTICS_PAGE_TYPES.METALS_STONES_LIST,
          page_title: `Metals Stones List`,
        }
      case '/shop/[uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.SHOP,
          page_title: `${uid}`,
        }
      case '/practical-informations/[...uid]':
        return {
          pageType: ANALYTICS_PAGE_TYPES.SHOP,
          page_title: `${uid}`,
        }
      case SINGLE_ROUTES.search.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.SEARCH,
          page_title: 'Search',
        }
      case SINGLE_ROUTES.login.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.LOGIN,
          page_title: 'Login',
        }
      case SINGLE_ROUTES.register.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.REGISTER,
          page_title: 'Register',
        }
      case SINGLE_ROUTES.forgotPassword.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.FORGOT,
          page_title: 'Forgot password',
        }
      case SINGLE_ROUTES.resetPassword.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.RESET,
          page_title: 'Reset password',
        }
      case SINGLE_ROUTES.enableAccount.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.ENABLE,
          page_title: 'Enable account',
        }
      case SINGLE_ROUTES.account.href:
        return {
          pageType: ANALYTICS_PAGE_TYPES.USER,
          page_title: `user${
            router.query.section ? `/${router.query.section}` : ''
          }`,
        }

      default:
        return {
          pageType: ANALYTICS_PAGE_TYPES.EDITO,
          page_path: realPath,
          page_title: tracking?.trackingTitle ?? `${uid}`,
        }
    }
  }

  const pushToDataLayer = (event: any) => {
    // Uncomment to debug
    // console.log(event)
    window?.dataLayer?.push(event)
  }

  const eventReducerQueue = (event, eventPayload) => {
    if (!isFetchingOrders && !isFetchingCustomer) {
      eventReducer(event, eventPayload)
    } else {
      eventsQueue.current.push({ event, eventPayload })
    }
  }

  useEffect(() => {
    if (
      !isFetchingOrders &&
      !isFetchingCustomer &&
      eventsQueue.current?.length > 0
    ) {
      eventsQueue.current.forEach(({ event, eventPayload }) => {
        eventReducer(event, eventPayload)
      })
      eventsQueue.current = []
    }
  }, [isFetchingOrders, isFetchingCustomer])

  const eventReducer = (event: string, eventPayload: any) => {
    const { origin, item } = eventPayload ?? {}

    switch (event) {
      case TRACKING_EVENTS.PAGE_VIEW:
        pushToDataLayer({
          event,
          ...getUserData(),
          ...getPageData(),
        })
        break

      case TRACKING_EVENTS.PRODUCT_PAGE_VIEW:
        pushToDataLayer({
          event,
          productCurrency: currency?.toLocaleUpperCase(),
          productPrice: +item?.price ?? 0,
          productName: item?.item_name,
          // Remove variant related part of the SKU to create a product SKU
          productID: item?.item_sku?.split('_').slice(0, -1).join('_'),
          productCategory: item?.item_category,
          productSubCategory: item?.item_subcategory,
          productDesigner: item?.productDesigner,
          content_ids: item?.item_sku,
          content_name: item?.item_name,
          content_category: item?.item_category,
          content_type: 'product',
        })
        break

      case TRACKING_EVENTS.PRODUCT_ADD_TO_CART:
        pushToDataLayer({
          event: 'GAevent',
          category: 'add to cart',
          action: item?.productDesigner,
          label: item?.item_name,
          value: +item?.price ?? 0,
          productCurrency:
            multistore[locale]?.currency?.toLocaleUpperCase() ??
            multistore[DEFAULT_LOCALE]?.currency?.toLocaleUpperCase(),
          productID: item?.item_sku?.split('_').slice(0, -1).join('_'),
          productCategory: item?.item_category,
          productSubcategory: item?.item_subcategory,
        })
        break

      case TRACKING_EVENTS.ADD_TO_WISHLIST:
        pushToDataLayer({
          event: 'GAevent',
          category: 'add to wishlist',
          action: item?.productDesigner,
          label: item?.item_name,
          value: +item?.price ?? 0,
          productCurrency:
            multistore[locale]?.currency?.toLocaleUpperCase() ??
            multistore[DEFAULT_LOCALE]?.currency?.toLocaleUpperCase(),
          productID: item?.item_sku?.split('_').slice(0, -1).join('_'),
          productCategory: item?.item_category,
          productSubcategory: item?.item_subcategory,
        })
        break
      case TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART:
        pushToDataLayer({
          event: 'GAevent',
          category: 'remove from cart',
          action: item?.productDesigner,
          label: item?.item_name,
          value: +item?.price ?? 0,
          productCurrency:
            multistore[locale]?.currency?.toLocaleUpperCase() ??
            multistore[DEFAULT_LOCALE]?.currency?.toLocaleUpperCase(),
          productID: item?.item_sku?.split('_').slice(0, -1).join('_'),
          productCategory: item?.item_category,
          productSubcategory: item?.item_subcategory,
        })
        break

      case TRACKING_EVENTS.NEWSLETTER_SUBSCRIPTION:
        pushToDataLayer({
          event: 'GAevent',
          category: 'newsletter subscription',
          action: origin ?? '',
          label: '',
          value: 'O',
        })
        break

      case TRACKING_EVENTS.ACCOUNT_CREATION:
        pushToDataLayer({
          event: 'GAevent',
          category: 'account creation',
          action: origin ?? '',
          label: '',
          value: 'O',
        })
        break
      case TRACKING_EVENTS.FILTERS_CHECK_FACET:
      case TRACKING_EVENTS.FILTERS_UNCHECK_FACET:
        pushToDataLayer({
          event: 'GAevent',
          category: 'filters',
          action: event,
          label: eventPayload?.type,
          value: eventPayload?.value,
        })
        break
      case TRACKING_EVENTS.FILTERS_SUBMIT:
        pushToDataLayer({
          event: 'GAevent',
          category: 'filters',
          action: event,
          value: eventPayload?.value,
        })
        break
    }
  }

  useEffect(() => {
    tracker.on('*', eventReducerQueue)
    return () => {
      tracker.off('*', eventReducerQueue)
    }
  }, [router, lang, locale, customer, currency])
}

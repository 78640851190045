import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractSelect, {
  SelectProps as AbstractSelectprops,
} from '~/components/Abstracts/Form/Select'
import ErrorList from '~/components/Form/ErrorList'
import { DownIcon } from '~/components/UI/Icons'

import { useStyle } from '~/providers/StyleProvider'

import { getCSSThemeClassName } from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type SelectProps = AbstractSelectprops & {
  className?: string
  selectClassName?: string
  theme?: string
}

function Select({
  className,
  selectClassName,
  iconClassName,
  errorClassname,
  theme,
  ...rest
}: SelectProps) {
  const selectStyle = useStyle({
    color: theme ?? GlobalThemeColors.Black,
  })

  const optionsStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12GroteskRegular,
  })
  const themeClassName = getCSSThemeClassName(
    css,
    theme ?? GlobalThemeColors.Black,
    {
      suffix: 'Theme',
    },
  )

  return (
    <div className={cx(css.Select, className, themeClassName)}>
      <AbstractSelect
        selectClassName={cx(
          css.select,
          selectClassName,
          selectStyle,
          optionsStyle,
        )}
        icon={DownIcon}
        iconClassName={cx(css.icon, iconClassName)}
        optionClassName={cx(css.options, selectStyle, optionsStyle)}
        errorClassname={cx(css.error, errorClassname)}
        {...rest}
      />
      <ErrorList className={cx(css.errorList)} name={rest.name} />
    </div>
  )
}

Select.defaultProps = {}

export default Select

import { useEffect, useRef, useState } from 'react'

export function useINPTrick() {
  const [hasInteracted, setHasInteracted] = useState(false)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  function onInteract(callback?: () => void) {
    setHasInteracted(true)
    timeout.current = setTimeout(() => {
      callback?.()
      setHasInteracted(false)
    }, 1)
  }

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return { hasInteracted, onInteract }
}

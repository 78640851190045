import { STORYBLOK_SLICES } from '~/lib/storyblok-types'

const SLICES_WITHOUT_MARGIN_TOP = [
  STORYBLOK_SLICES.PUSH_DESIGNERS,
  STORYBLOK_SLICES.HERO_SLIDER,
]

const SLICES_WITHOUT_MARGIN_BOTTOM = [
  STORYBLOK_SLICES.PUSH_DESIGNERS,
  STORYBLOK_SLICES.HERO_SLIDER,
]

export { SLICES_WITHOUT_MARGIN_TOP, SLICES_WITHOUT_MARGIN_BOTTOM }

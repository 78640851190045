import classnames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalInputPreset } from '~/@types/input-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import ErrorList from '~/components/Form/ErrorList'
import Input from '~/components/Form/Input'
import Phone from '~/components/Form/Phone'
import InlineCta from '~/components/UI/InlineCta'
import Logotype from '~/components/UI/LogoTypes/logo-illu.svg'
import SquaredCta from '~/components/UI/SquaredCta'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { usePopIn } from '~/providers/PopInProvider'
import RecaptchaHandler from '~/providers/RecaptchaProvider/RecaptchaHandler'
import { useStyle } from '~/providers/StyleProvider'

import useAlerts from '~/hooks/useAlerts'
import useContactFormMutation from '~/hooks/useContactFormMutation'
import useFormWithMutation from '~/hooks/useFormWithMutation'

import { FORM, POPIN } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ContactUsServiceProps = {
  title?: RichTextBlock
  text?: RichTextBlock
  phoneContactTitle?: RichTextBlock
  phoneContactText?: RichTextBlock
  emailContactTitle?: RichTextBlock
  emailContactText?: RichTextBlock
}

export type TabContactUsProps = {
  customerService?: ContactUsServiceProps
  pressService?: ContactUsServiceProps
}

export type TabSendMessageProps = {
  title?: RichTextBlock
}

export type ContactPopinProps = {
  className?: string
  contactUs?: TabContactUsProps
  sendMessage?: TabSendMessageProps
  onSuccessMessage?(): void
}

function ContactUsColumn({
  title,
  text,
  phoneContactTitle,
  phoneContactText,
  emailContactTitle,
  emailContactText,
}: ContactUsServiceProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title24SangBleuLight,
    color: GlobalThemeColors.Black,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label12SangBleu,
    textStyling: GlobalTextStyling.Italic,
    color: GlobalThemeColors.Gray,
  })
  const phoneEmailTitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12SangBleu,
  })
  const phoneEmailTextStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })

  return (
    <div className={css.column}>
      <RichText className={cx(css.title, titleStyle)} render={title} />
      <RichText className={cx(css.text, textStyle)} render={text} />
      <div className={css.contacts}>
        {phoneContactTitle && (
          <RichText
            className={cx(css.contactTitle, phoneEmailTitleStyle)}
            render={phoneContactTitle}
          />
        )}
        {phoneContactText && (
          <RichText
            className={cx(css.contactText, phoneEmailTextStyle)}
            render={phoneContactText}
          />
        )}
        {emailContactTitle && (
          <RichText
            className={cx(css.contactTitle, phoneEmailTitleStyle)}
            render={emailContactTitle}
          />
        )}
        {emailContactText && (
          <RichText
            className={cx(css.contactText, phoneEmailTextStyle)}
            render={emailContactText}
          />
        )}
      </div>
    </div>
  )
}

function ContactPopin({ className }: ContactPopinProps) {
  const {
    global: {
      popins: { contact },
    },
  } = useGlobalData()
  const t = useTranslate()
  const [messageSent, setMessageSent] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState('contactUs')

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title24SangBleuLight,
    color: GlobalThemeColors.Black,
  })

  const headerStyle = useStyle({
    textPreset: GlobalTextPreset.Text12GroteskMedium,
    color: GlobalThemeColors.Gray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const { mutate: contactMutation, isLoading } = useContactFormMutation(
    {
      onSuccess: () => {
        setMessageSent(true)
      },
    },
    (...params) => form.setError(...params),
  )

  const { form, onSubmit } = useFormWithMutation(
    contactMutation,
    null,
    'contact',
  )

  useEffect(() => {
    if (
      form?.formState?.errors &&
      Object.keys(form.formState.errors).length > 0
    )
      setMessageSent(null)
  }, [form.formState])

  const onClickTab = (e, tabName) => {
    e.preventDefault()
    setActiveTab(tabName)
  }

  return (
    <div className={cx(css.ContactPopin, className)}>
      <div className={cx(css.header, headerStyle)}>
        <InlineCta
          className={css.tab}
          textPreset={GlobalTextPreset.Cta12Grotesk}
          lineProps={{
            initialLineState: activeTab === 'contactUs' ? 'underlined' : 'none',
          }}
          theme={
            activeTab === 'contactUs'
              ? GlobalThemeColors.Black
              : GlobalThemeColors.Gray
          }
          onClick={(e) => {
            onClickTab(e, 'contactUs')
          }}>
          {t(POPIN.CONTACT_US)}
        </InlineCta>
        <InlineCta
          className={css.tab}
          textPreset={GlobalTextPreset.Cta12Grotesk}
          lineProps={{
            initialLineState:
              activeTab === 'sendMessage' ? 'underlined' : 'none',
          }}
          theme={
            activeTab === 'sendMessage'
              ? GlobalThemeColors.Black
              : GlobalThemeColors.Gray
          }
          onClick={(e) => {
            onClickTab(e, 'sendMessage')
          }}>
          {t(POPIN.SEND_MESSAGE)}
        </InlineCta>
      </div>
      <div className={css.content}>
        <div className={css.logoHolder}>
          <Logotype className={cx(css.logo)} />
        </div>
        {activeTab === 'contactUs' && (
          <div className={cx(css.tabItem, css.tabContactUs)}>
            <div className={css.columns}>
              {contact?.contactUs?.customerService && (
                <ContactUsColumn {...contact?.contactUs?.customerService} />
              )}
              {contact?.contactUs?.pressService && (
                <ContactUsColumn {...contact?.contactUs?.pressService} />
              )}
            </div>
          </div>
        )}
        {activeTab === 'sendMessage' && (
          <div className={cx(css.tabItem, css.tabSendMessage)}>
            {contact?.sendMessage?.title && (
              <RichText
                className={cx(css.title, titleStyle)}
                render={contact?.sendMessage?.title}
              />
            )}

            {messageSent ? (
              <div className={cx(css.successMessage)}>
                {t(FORM.CONTACT_SUCCESS)}
              </div>
            ) : (
              <RecaptchaHandler>
                <FormProvider {...form}>
                  <form onSubmit={onSubmit}>
                    <div className={css.columns}>
                      <div className={css.column}>
                        <div className={cx(css.inputContainer)}>
                          <Input
                            className={cx(css.item, css.firstName)}
                            preset={GlobalInputPreset.FirstName}
                          />
                          <Input
                            className={cx(css.item, css.lastName)}
                            preset={GlobalInputPreset.LastName}
                          />
                          <Input
                            className={cx(css.item, css.email)}
                            preset={GlobalInputPreset.Email}
                          />
                          <Phone
                            name="phone"
                            className={cx(css.item, css.phone)}
                          />
                          <ErrorList className={cx(css.errorList)} />
                        </div>
                      </div>
                      <div className={css.column}>
                        <div className={cx(css.inputContainer)}>
                          <Input
                            required
                            name="message"
                            textarea
                            placeholder={t(FORM.MESSAGE)}
                            inputClassName={css.textarea}
                            className={cx(css.item, css.textarea)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={css.bottom}>
                      <SquaredCta
                        className={cx(css.button)}
                        isLoading={isLoading}
                        theme={GlobalThemeColors.Black}
                        withBackground
                        withBorder={false}
                        onClick={onSubmit}>
                        {t(FORM.SUBMIT)}
                      </SquaredCta>
                    </div>
                  </form>
                </FormProvider>
              </RecaptchaHandler>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

ContactPopin.defaultProps = {}

export default ContactPopin

import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { ctaNegativeTheme } from '~/lib/negative-theme-colors'

import Cta, { BaseProps } from '~/components/Abstracts/Cta'

import { useStyle } from '~/providers/StyleProvider'
import { useTheme } from '~/providers/ThemeProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type RoundedCtaTextPreset = typeof GlobalTextPreset.Cta16_12SangBleu

export type RoundedCtaColors = GlobalThemeColors
export type RoundedCtaProps = BaseProps & {
  className?: string
  theme?: RoundedCtaColors
  textPreset?: RoundedCtaTextPreset
}

function RoundedCta({ className, textPreset, ...rest }: RoundedCtaProps) {
  const { colors } = useTheme()
  const props = {
    css,
    cx,
    negativeColor: colors[ctaNegativeTheme(rest.theme)],
  }

  const textStyle = useStyle({
    textPreset,
  })

  return (
    <Cta
      className={cx(className, textStyle, css.RoundedCta)}
      {...props}
      {...rest}
    />
  )
}

RoundedCta.defaultProps = {
  theme: GlobalThemeColors.Black,
  textPreset: GlobalTextPreset.Cta16_12SangBleu,
  withBackground: false,
  withBorder: true,
}

export default RoundedCta

import linkResolver from '~/lib/link-resolver'

import { getLang } from '~/utils/locales'

export default function getSingleRouteHref(
  type: string,
  locale: string,
  slug?: string,
) {
  const lang = getLang(locale) ?? locale

  return linkResolver(
    {
      lang,
      content: {
        component: type,
      },
      slug: slug ?? null,
    },
    locale,
  )
}

import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import React, { forwardRef, useCallback, useRef, useState } from 'react'

import Ratio from '~/components/Abstracts/Ratio'

import isDev from '~/utils/is-dev'

import { Sizes, useSizesFromBreakpoints } from './maths'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ImageProps = Omit<NextImageProps, 'src' | 'width' | 'height'> & {
  sizesFromBreakpoints?: Sizes
  width?: number
  height?: number
  src: string
  resizeRect?: string
  local?: boolean
  ratio?: string
  asPlaceholder?: boolean
  placeholderClassName?: string
  loader?: (src, width, quality, rect, local) => string
}

function ImageForwarded(
  {
    sizes,
    layout,
    sizesFromBreakpoints,
    width,
    height,
    className,
    asPlaceholder = false,
    resizeRect,
    onLoadingComplete,
    ratio,
    local,
    onClick,
    placeholderClassName,
    loader,
    priority,
    ...props
  }: ImageProps,
  ref,
) {
  const processedSizes = useSizesFromBreakpoints(
    sizesFromBreakpoints,
    props.src,
  )

  const [loaded, setLoaded] = useState(false)

  if (!props.src)
    isDev &&
      console.warn(
        `[WARNING] Image-Component is use without SRC ${JSON.stringify(props)}`,
      )

  const placeholderRef = useRef(null)

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  }

  const onLoadingCompleteCallback = useCallback(
    (res) => {
      onLoadingComplete?.(res)
      asPlaceholder && setLoaded?.(true)
    },
    [onLoadingComplete, setLoaded, asPlaceholder],
  )

  const imageComponent = (
    <>
      {!priority && asPlaceholder && (
        <AnimatePresence>
          {asPlaceholder && (
            <m.span
              animate={loaded ? 'hide' : 'show'}
              variants={variants}
              ref={placeholderRef}
              className={cx(css.placeholder, placeholderClassName, {
                asPlaceholder,
              })}
            />
          )}
        </AnimatePresence>
      )}

      <NextImage
        sizes={processedSizes ? processedSizes : sizes}
        layout={layout}
        {...(layout === 'fill' ? {} : { width, height })}
        loader={
          loader ? (loaderProps) => loader({ ...loaderProps }) : undefined
        }
        onLoadingComplete={onLoadingCompleteCallback}
        unoptimized={props?.src?.indexOf('.svg') !== -1}
        priority={priority}
        {...props}
      />
    </>
  )

  return props.src ? (
    <div ref={ref} className={cx(css.Image, className)} onClick={onClick}>
      {ratio ? (
        <Ratio ratio={ratio} styleContainer={{ position: 'relative' }}>
          {() => imageComponent}
        </Ratio>
      ) : (
        imageComponent
      )}
    </div>
  ) : null
}

const Image = forwardRef<HTMLDivElement, ImageProps>(ImageForwarded)

Image.defaultProps = {}

export type { Size, Sizes } from './maths'
export default Image

import { UseQueryOptions } from '@tanstack/react-query'
import { CartProps } from '~/@types/cart'

import { useGetCart as useAbstractGetCart } from '@unlikelystudio/react-ecommerce-hooks'

import useLocale from '~/hooks/useLocale'

import serializeCart from '~/data/cart-data/serialize-cart'
import serializeCartProducts from '~/data/cart-data/serialize-cart-products'

export type CartPayload = ReturnType<typeof useAbstractGetCart>['data']

export default function useGetCart(
  mutationOptions?: UseQueryOptions<any, any, any>,
) {
  const locale = useLocale()

  const { data: cartPayload, ...rest } = useAbstractGetCart({
    refetchOnMount: false,
    // TODO: Say warning to Anto / Ves / Raoul
    onSuccess: (data) => {
      mutationOptions?.onSuccess && mutationOptions.onSuccess(data)
    },
  })

  const cart = serializeCart(cartPayload, locale)
  const products = serializeCartProducts(cartPayload?.lines, locale)

  const serializedCart: CartProps = {
    ...cart,
    products,
  }

  return { cart: serializedCart, ...rest, rawCart: cartPayload }
}

import { useRouter } from 'next/router'
import { SingleRouteParams } from '~/lib/constants'
import getSingleRouteHref from '~/lib/get-single-route-href'

import useLocale from '~/hooks/useLocale'

function useSingleRoutesRedirect(): (object: SingleRouteParams) => void {
  const router = useRouter()
  const locale = useLocale()

  const redirect = (object: SingleRouteParams) => {
    const url = getSingleRouteHref(object.type, locale)
    router.push(url)
  }

  return redirect
}

export default useSingleRoutesRedirect

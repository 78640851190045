export const GlobalTextStyling = {
  UpperCase: 'uppercase',
  LowerCase: 'lowercase',
  Capitalize: 'capitalize',
  Light: 'light',
  Medium: 'medium',
  Bold: 'bold',
  Italic: 'italic',
  None: 'none',
} as const

export type GlobalTextStyling =
  typeof GlobalTextStyling[keyof typeof GlobalTextStyling]

import classnames from 'classnames'
import React from 'react'

import { IconProps } from '~/components/Abstracts/Icons'

import ArrowLeft from './arrow-left.svg'
import Back from './back.svg'
import BigArrowLeft from './big-arrow-left.svg'
import Check from './check.svg'
import PlayRounded from './play-rounded.svg'
import Star from './star.svg'
import StoreLocator from './store-locator.svg'
import css from './styles.module.scss'

export function iconFromReactSvg<T>(
  Icon: React.FC<React.SVGProps<SVGSVGElement>>,
  style?: string,
): React.FC<T & IconProps> {
  const icon = ({ className, theme, ...props }: T & IconProps) => {
    return (
      <Icon
        className={classnames(style, className, css?.[`${theme}Theme`])}
        {...props}
      />
    )
  }
  return icon
}

export const PlayRoundedIcon = iconFromReactSvg(
  PlayRounded,
  css.PlayRoundedIcon,
)

export const BigArrowLeftIcon = iconFromReactSvg(
  BigArrowLeft,
  css.BigArrowLeftIcon,
)

export const BigArrowRightIcon = iconFromReactSvg(
  BigArrowLeft,
  css.BigArrowRightIcon,
)

export const ArrowLeftIcon = iconFromReactSvg(ArrowLeft, css.ArrowLeftIcon)

export const ArrowRightIcon = iconFromReactSvg(ArrowLeft, css.ArrowRightIcon)

export const CheckIcon = iconFromReactSvg(Check, css.Check)

export const StoreLocatorIcon = iconFromReactSvg(StoreLocator, css.StoreLocator)

export const StarIcon = iconFromReactSvg(Star, css.StarIcon)

export const BackIcon = iconFromReactSvg(Back, css.BackIcon)

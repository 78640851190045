import classNames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractPhone, {
  PhoneProps,
} from '~/components/Abstracts/Form/Account/Phone'
import ErrorList from '~/components/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classNames.bind(css)

function Phone({ className, theme, ...props }: PhoneProps) {
  const borderStyle = useStyle({
    borderColor: theme,
  })
  const inputStyle = useStyle({
    color: GlobalThemeColors.Black,
    textPreset: GlobalTextPreset.Input12GroteskRegular,
  })

  return (
    <div className={cx(className, css.PhoneWrapper)}>
      <AbstractPhone
        className={cx(css.Phone, borderStyle)}
        inputClassName={cx(css.input, inputStyle)}
        dropdownClassName={cx(css.dropdown, inputStyle)}
        {...props}
      />
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}
Phone.defaultProps = {}

export default Phone

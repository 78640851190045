import { CartProduct } from '~/@types/cart'
import { SHOPIFY_ATTRIBUTES } from '~/lib/constants'
import linkResolver from '~/lib/link-resolver'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { CartPayload } from '~/hooks/cart/useGetCart'

import { attributesAsObject } from '~/utils/attributes'

import serializePrice from '~/data/serialize-price'

export default function serializeCartProducts(
  products: CartPayload['lines'],
  locale: string,
): CartProduct[] {
  return products?.map((product) => {
    const attributes = attributesAsObject(product?.attributes)

    return {
      id: product?.id,
      merchandiseId: product?.merchandise?.id,
      price: serializePrice(
        locale,
        product?.cost?.totalAmount?.currencyCode,
        product?.cost?.totalAmount?.amount,
      ),
      priceAmount: product?.cost?.totalAmount?.amount,
      title: attributes[SHOPIFY_ATTRIBUTES.name],
      designer: attributes[SHOPIFY_ATTRIBUTES.designer],
      reference: attributes[SHOPIFY_ATTRIBUTES.reference],
      size: product?.merchandise?.title,
      quantity: product?.quantity,
      imageSrc: attributes[SHOPIFY_ATTRIBUTES.image],
      isAdjusting: attributes[SHOPIFY_ATTRIBUTES.isAdjusting] === 'true',
      isPreOrder: attributes[SHOPIFY_ATTRIBUTES.isPreOrder] === 'true',
      isUniqueSize: attributes[SHOPIFY_ATTRIBUTES.isUniqueSize] === 'true',
      selectedSize: attributes[SHOPIFY_ATTRIBUTES.selectedSize],
      attributes,
      href: attributes[SHOPIFY_ATTRIBUTES.slug]
        ? linkResolver(
            {
              content: { component: STORYBLOK_TYPES.PRODUCT },
              default_full_slug: attributes[SHOPIFY_ATTRIBUTES.slug],
            },
            locale,
          )
        : null,
    }
  }) ?? []
}

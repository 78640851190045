import { useForm } from 'react-hook-form'
import { ALERT_CODES } from '~/@types/alert'
import {
  GOOGLE_RECAPTCHA_PARAMS,
  requestNextApiEndpoint,
} from '~/lib/handle-api'

import { useGoogleReCaptcha } from '~/providers/RecaptchaProvider/use-google-recaptcha'

import useAlerts from '~/hooks/useAlerts'

export default function useFormWithMutation(
  mutation?: any,
  formOptions?: any,
  action?: string,
) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const triggerAlert = useAlerts()

  const form = useForm({
    ...formOptions,
  })

  const submitHandler = form.handleSubmit(async (data) => {
    if (action) {
      try {
        const token = await executeRecaptcha(action)
        const handleCaptcha = await requestNextApiEndpoint(
          GOOGLE_RECAPTCHA_PARAMS,
          {
            payload: { token, action },
          },
        )
        if (handleCaptcha?.success) mutation(data)
        else triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
      } catch (err) {
        console.error('Recaptcha Error', err)
        triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
      }
    } else {
      mutation?.(data)
    }
  })

  return { form, onSubmit: submitHandler }
}

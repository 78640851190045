import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { COOKIE_KEYS } from '~/lib/constants'

import { useMarketContext } from '~/providers/MarketProvider'

export type MarketCookie = {
  countryCode: string
  locale: string
  marketName: string
}

export default function useMarketCookie() {
  const POPIN_DISPLAYED_KEY = COOKIE_KEYS.globalMarketPopinDisplayed
  const POPIN_USER_PREFERENCES = COOKIE_KEYS.globalMarketPopinUserPreferences

  const { setDisplayMarketPopin } = useMarketContext()

  const setMarketPopinUserPreferences = useCallback((data: MarketCookie) => {
    Cookies.set('NEXT_LOCALE', data.locale, { expires: 365 })
    Cookies.set(POPIN_USER_PREFERENCES, JSON.stringify(data), { expires: 365 })
    setDisplayMarketPopin(false)
  }, [])

  const setMarketPopinDisplayed = useCallback((value: boolean) => {
    Cookies.set(POPIN_DISPLAYED_KEY, JSON.stringify(value), { expires: 365 })
  }, [])

  const getMarketPopinUserPreferences = useCallback(() => {
    return (
      Cookies.get(POPIN_USER_PREFERENCES) &&
      JSON.parse(Cookies.get(POPIN_USER_PREFERENCES))
    )
  }, [])

  const getMarketPopinDisplayed = useCallback(() => {
    return (
      (Cookies.get(POPIN_DISPLAYED_KEY) &&
        JSON.parse(Cookies.get(POPIN_DISPLAYED_KEY))) ??
      false
    )
  }, [])

  return {
    setMarketPopinUserPreferences,
    getMarketPopinUserPreferences,
    setMarketPopinDisplayed,
    getMarketPopinDisplayed,
  }
}

import jwtDecode from 'jwt-decode'
import { DEFAULT_STORE_KEY } from '~/lib/constants'

import type { GetShopifyStorefrontClientOptions } from '@unlikelystudio/commerce-connector'

/**
 * Given a url, replace the domain name of the url with the new domain name
 * @param url - The URL of the page to be loaded.
 * @returns the url with the domain name changed.
 */
export function changeDomainUrlCheckout(url) {
  if (url?.indexOf('whitebird-jewellery.myshopify.com') > -1)
    return url.replace(
      'whitebird-jewellery.myshopify.com',
      'checkout.whitebirdjewellery.com',
    )
  else return url
}

/**
 * It takes a locale and returns the credentials for the store associated with that locale
 * @param {string} locale - The locale of the store.
 * @returns A JSON object with the following keys:
 */
function decodeJWTPublicToken(withoutDomainChange?: boolean) {
  const publicToken = process.env.NEXT_PUBLIC_SHOPIFY_TOKEN

  const decodedPublicToken = jwtDecode(publicToken) ?? {}

  if (!decodedPublicToken?.[DEFAULT_STORE_KEY]) return null

  const currentUrl = decodedPublicToken?.[DEFAULT_STORE_KEY].url
  const url = withoutDomainChange
    ? currentUrl
    : changeDomainUrlCheckout(currentUrl)

  return (
    {
      ...decodedPublicToken[DEFAULT_STORE_KEY],
      url,
    } ?? null
  )
}

/**
 * This method is used to return a decoded object with PUBLIC credentials
 * with the necessary store properties to use commerce-connector or ecommerce-hooks
 * @returns {storeUrl, storefrontAccessToken}
 */
export function getStorefrontShopObject(
  withoutDomainChange?: boolean,
): GetShopifyStorefrontClientOptions {
  const credentials = decodeJWTPublicToken(withoutDomainChange)

  if (!credentials) return null

  return {
    storeUrl: credentials?.url,
    storefrontAccessToken: credentials?.storeFrontAccessToken,
  }
}

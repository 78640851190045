export type LoaderParams = {
  src: string
  quality?: number
  width: number
  rect?: string
}

export const imageLoaderStoryblok = ({
  src,
  width,
  quality,
  rect,
}: LoaderParams) => {
  return src.indexOf('.svg') === -1
    ? `${src}/m/${width}x0/filters:quality(${quality || 80})`
    : src
}

export const imageLoaderShopify = ({
  src,
  width,
  quality,
  rect,
}: LoaderParams) => {
  return src.indexOf('.svg') === -1 ? `${src}?width=${width}` : src
}

import { CartProps } from '~/@types/cart'

import { CartPayload } from '~/hooks/cart/useGetCart'

import { attributesAsObject } from '~/utils/attributes'

import serializePrice from '~/data/serialize-price'

function applyLocaleToCart(url, locale) {
  if (!url) return
  const [lang] = locale?.split('-') ?? [locale]
  const processedUrl = new URL(url)
  processedUrl.searchParams.append('locale', lang)
  return processedUrl?.href
}

export default function serializeCart(
  cart: CartPayload,
  locale: string,
): Omit<CartProps, 'products'> {
  const attributes = attributesAsObject(cart?.attributes ?? [])

  return {
    cartId: cart?.id,
    lineItems: cart?.lines,
    attributes,
    totalAmount: cart?.cost?.totalAmount?.amount,
    totalWithCurrency: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalAmount?.currencyCode,
          cart?.cost?.totalAmount?.amount,
        )
      : null,
    subtotal: cart
      ? serializePrice(
          locale,
          cart?.cost?.subtotalAmount?.currencyCode,
          cart?.cost?.subtotalAmount?.amount,
        )
      : null,
    totalTax:
      cart && cart?.cost?.totalTaxAmount
        ? serializePrice(
            locale,
            cart?.cost?.totalTaxAmount?.currencyCode,
            cart?.cost?.totalTaxAmount?.amount,
          )
        : null,
    checkoutUrl: applyLocaleToCart(cart?.checkoutUrl, locale) ?? null,
    productsQuantity: cart?.lines?.reduce?.((previousValue, lineItem) => {
      return previousValue + lineItem?.quantity ?? 0
    }, 0),
  }
}

export const ALGOLIA_HITS_PER_PAGE = 32
export const ALGOLIA_PRODUCT_INDEX = 'horizon_product'
export const ALGOLIA_SHOPIFY_ID_PROPERTY_KEY = 'objectID'
export const ALGOLIA_STORYBLOK_ID_PROPERTY_KEY = 'storyID'

export const REPLICAS_SUFFIX = {
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  RECOMMENDATIONS: 'recommendations',
  PUBLISHED_AT: 'published_at',
  SUSTAINABILITY: 'sustainability',
  DEFAULT: '',
}

import linkResolver from '~/lib/link-resolver'

const defaultLink = { href: '/', rel: 'noopener' }

/**
 * Link serializer
 * @param link
 * @param locale
 * @returns Serialized link
 */
export default function serializeLink(link, locale, query?) {
  if (!link?.id && !link?.url) return null
  if (link?.id && link?.id?.length === 0 && link?.url?.length === 0) return null
  if (link?.story) {
    return {
      href: linkResolver(link?.story, locale, query) ?? null,
      rel: 'noopener',
    }
  } else if (link?.content?.component) {
    return {
      href: linkResolver(link, locale, query) ?? null,
      rel: 'noopener',
    }
  } else if (link?.linktype === 'url') {
    return {
      href: link?.href ?? link?.url,
      isExternal: true,
      target: 'blank',
      rel: 'noopener nofollow',
    }
  } else {
    if (link?.href) {
      return {
        href: link?.href ?? link?.url,
        isExternal: true,
        target: 'blank',
        rel: 'noopener nofollow',
      }
    }

    return defaultLink
  }
}

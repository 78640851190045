import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractCheckbox, {
  CheckboxProps as AbstractCheckboxProps,
} from '~/components/Abstracts/Form/Checkbox'
import {
  TCheckboxPresets,
  useCheckboxPreset,
} from '~/components/Abstracts/Form/Checkbox/hooks'
import ErrorList from '~/components/Form/ErrorList'
import { CheckIcon } from '~/components/Icons'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type CheckBoxTheme = GlobalThemeColors

type CheckBoxTextPreset =
  | typeof GlobalTextPreset.Input12GroteskRegular
  | typeof GlobalTextPreset.Text12GroteskMedium

export const CheckBoxType = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
} as const

export type CheckBoxType = typeof CheckBoxType[keyof typeof CheckBoxType]

export type CheckboxProps = AbstractCheckboxProps & {
  className?: string
  theme?: CheckBoxTheme
  preset?: TCheckboxPresets
  textPreset?: CheckBoxTextPreset
  isSwitch?: boolean
  isRound?: boolean
  isSquared?: boolean
}

function Checkbox({
  className,
  preset,
  theme,
  textPreset,
  isSwitch,
  isRound,
  isSquared,
  ...rest
}: CheckboxProps) {
  const defaultTextPreset = GlobalTextPreset.Input12GroteskRegular
  const defaultColorPreset = GlobalThemeColors.Gray
  const labelStyle = useStyle({
    color: theme ?? defaultColorPreset,
    textPreset: textPreset ?? defaultTextPreset,
  })

  const { label, ...props } = useCheckboxPreset(preset, rest)

  return (
    <div
      className={cx(css.Checkbox, className, css?.[`${theme}Theme`], {
        isSwitch,
        round: isRound,
        squared: isSquared,
      })}>
      <AbstractCheckbox
        className={css.container}
        errorClassname={cx(css.error)}
        checkmarkClassName={cx(css.checkmark)}
        contentClassName={cx(css.content)}
        checkmarkActiveClassName={cx(css.checkmarkActive)}
        labelClassName={cx(css.label, labelStyle)}
        label={label}
        checkmarkActive={<CheckIcon className={cx(css.checkIcon)} />}
        {...props}
      />
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}

Checkbox.defaultProps = {
  theme: GlobalThemeColors.Black,
  isSwitch: false,
}

export default Checkbox

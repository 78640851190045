import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

export const LAYOUT = {
  MEDIUM: 'medium',
  SMALL: 'small',
  BIG: 'big',
} as const

export type LAYOUT = typeof LAYOUT[keyof typeof LAYOUT]

type LayoutSwitcherProviderContext = {
  step?: LAYOUT
  setStep?: (value: LAYOUT) => void
}

const Context = createContext<LayoutSwitcherProviderContext>({})

export function useLayoutSwitcherProvider() {
  return useContext(Context)
}

export type LayoutSwitcherProviderProps = {
  children?: ReactNode | ReactNode[]
  defaultLayout: LAYOUT
}

function LayoutSwitcherProvider({
  children,
  defaultLayout,
}: LayoutSwitcherProviderProps) {
  const [step, setStep] = useState(defaultLayout ?? LAYOUT.MEDIUM)

  const providerValue = useMemo(
    () => ({
      step,
      setStep,
    }),
    [step],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

LayoutSwitcherProvider.defaultProps = {}

export default LayoutSwitcherProvider

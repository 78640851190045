import React, { createContext, ReactNode, useContext, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { ToastProps } from '~/components/UI/Toast'

export const ToastContext = createContext<any>({})

export type ToastProviderProps = {
  children?: ReactNode | ReactNode[]
}

function ToastProvider({ children }: ToastProviderProps) {
  const [alerts, setAlerts] = useState([])

  const debouncedCloseAlert = useDebouncedCallback((uid: string) => {
    setAlerts((alerts: ToastProps[]) =>
      alerts?.filter((alert) => alert.uid !== uid),
    )
  }, 10000)

  const addAlert = (toast: ToastProps) => {
    debouncedCloseAlert.flush()
    debouncedCloseAlert(toast?.uid)

    setAlerts((alerts: ToastProps[]) => {
      return alerts?.find((alert) => alert.uid === toast.uid)
        ? alerts
        : [...(alerts ? alerts : []), toast]
    })
  }

  return (
    <ToastContext.Provider value={{ alerts, addAlert, setAlerts }}>
      {children}
    </ToastContext.Provider>
  )
}

ToastProvider.defaultProps = {}

export default ToastProvider

export function useToastProvider() {
  return useContext(ToastContext)
}

import { iconFromReactSvg } from '~/components/Abstracts/Icons'

import css from './styles.module.scss'
import ArrowRight from './svgs/arrow-right.svg'
import Arrow from './svgs/arrow.svg'
import BigClose from './svgs/big-close.svg'
import BigLayout from './svgs/big-layout.svg'
import Close from './svgs/close.svg'
import Down from './svgs/down.svg'
import StarEmpty from './svgs/empty-star.svg'
import StarFull from './svgs/full-star.svg'
import HorizontalArrow from './svgs/horizontal-arrow.svg'
import LessThan from './svgs/less-than.svg'
import MediumLayout from './svgs/medium-layout.svg'
import MenuClose from './svgs/menu-close.svg'
import MenuOpen from './svgs/menu-open.svg'
import MonoLayout from './svgs/mono-layout.svg'
import NavArrow from './svgs/nav-arrow.svg'
import QuestionMark from './svgs/question-mark.svg'
import SmallLayout from './svgs/small-layout.svg'

export const ArrowIcon = iconFromReactSvg(Arrow, css.ArrowIcon)
export const LeftIcon = iconFromReactSvg(LessThan, css.LeftIcon)
export const RightIcon = iconFromReactSvg(LessThan, css.RightIcon)
export const BottomIcon = iconFromReactSvg(LessThan, css.BottomIcon)
export const TopIcon = iconFromReactSvg(LessThan, css.TopIcon)
export const NavArrowIcon = iconFromReactSvg(NavArrow, css.NavArrowIcon)
export const StarEmptyIcon = iconFromReactSvg(StarEmpty, css.StarEmptyIcon)
export const StarFullIcon = iconFromReactSvg(StarFull, css.StarFullIcon)
export const QuestionMarkIcon = iconFromReactSvg(
  QuestionMark,
  css.QuestionMarkIcon,
)
export const HorizontalArrowIcon = iconFromReactSvg(
  HorizontalArrow,
  css.HorizontalArrowIcon,
)

export const MenuOpenIcon = iconFromReactSvg(MenuOpen, css.MenuOpenIcon)
export const CloseIcon = iconFromReactSvg(Close, css.Close)
export const MenuCloseIcon = iconFromReactSvg(MenuClose, css.MenuCloseIcon)
export const BigCloseIcon = iconFromReactSvg(BigClose, css.BigCloseIcon)
export const ArrowRightIcon = iconFromReactSvg(ArrowRight, css.ArrowRightIcon)
export const DownIcon = iconFromReactSvg(Down, css.DownIcon)
export const BigLayoutIcon = iconFromReactSvg(BigLayout, css.BigLayoutIcon)
export const MonoLayoutIcon = iconFromReactSvg(MonoLayout, css.MonoLayoutIcon)
export const MediumLayoutIcon = iconFromReactSvg(
  MediumLayout,
  css.MediumLayoutIcon,
)
export const SmallLayoutIcon = iconFromReactSvg(
  SmallLayout,
  css.SmallLayoutIcon,
)

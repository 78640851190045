import { getBranchEnv } from '~/utils/get-branch-env'

export function isProduction(): boolean {
  return process.env.NODE_ENV === 'production'
}

export function isProductionEnv(): boolean {
  return getBranchEnv() === 'production'
}

export function isProductionBranch(): boolean {
  return (
    process.env.NEXT_PUBLIC_BRANCH === 'production' &&
    process.env.NODE_ENV === 'production'
  )
}

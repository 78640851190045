import { RichTextBlock } from '~/components/UI/RichText'

export function stringIsNotEmpty(str: string) {
  return (
    str !== '' && str !== null && str !== undefined && typeof str === 'string'
  )
}

export function isRTFilled(richText: RichTextBlock) {
  if (!richText) return false

  return (
    //@ts-ignore
    richText?.content?.filter((item) => item.content || item.type === 'blok')
      ?.length > 0
  )
}

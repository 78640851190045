import classnames from 'classnames'
import { usePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalInputPreset } from '~/@types/input-preset'
import type { NavigationProps } from '~/@types/navigation'
import { BottomMobileLink } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { SINGLE_ROUTES } from '~/lib/constants'

import { Link } from '@unlikelystudio/react-abstract-components'

import PopIn from '~/components/Abstracts/PopIn'
import Input from '~/components/Form/Input'
import ContactPopin from '~/components/Popins/ContactPopin'
import { CloseIcon } from '~/components/UI/Icons'

import { useMarketContext } from '~/providers/MarketProvider'
import { usePopIn } from '~/providers/PopInProvider'
import { useStyle } from '~/providers/StyleProvider'

import useMarketCookie from '~/hooks/useMarketCookie'

import { ACCOUNT, GENERAL, LANG_SWITCHER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export function NavigationBottomPanel({
  accountLink,
  wishlistLink,
  isLoggedIn,
  bottomMobileLinks,
  delayBeforeDestroy = 0,
  locale,
}: NavigationProps) {
  const { add: addPopin } = usePopIn()
  const t = useTranslate()

  const onClickItem = (e) => {
    e.preventDefault()

    addPopin(
      <PopIn
        className={cx(css.popin)}
        isCloseButton={true}
        closeButton={<CloseIcon className={css.popinClose} />}>
        <ContactPopin className={cx(css.contactPopin)} />
      </PopIn>,
    )
  }

  const [isPresent, safeToRemove] = usePresence()

  const langage = t(LANG_SWITCHER.LANGUAGE(locale))
  const symbol = t(LANG_SWITCHER.LANGUAGE_SYMBOL(locale))

  const { setDisplayMarketPopin } = useMarketContext()
  const { setMarketPopinDisplayed } = useMarketCookie()

  const openMarketPopin = (e) => {
    e.preventDefault()
    setMarketPopinDisplayed(true)
    setDisplayMarketPopin(true)
  }

  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const copyrightStyle = useStyle({
    textPreset: GlobalTextPreset.Cta10Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  let timeoutID: NodeJS.Timeout
  useEffect(() => {
    clearTimeout(timeoutID)
    !isPresent && (timeoutID = setTimeout(safeToRemove, delayBeforeDestroy))
  }, [isPresent])

  return (
    <div className={css.navigationPanelBottom}>
      <div className={css.navigationPanelBottomTop}>
        {wishlistLink && (
          <Link
            className={cx(css.navigationPanelBottomTopItem, linkStyle)}
            {...wishlistLink}>
            {t(GENERAL.WISHLIST)}
          </Link>
        )}

        {accountLink && (
          <Link
            className={cx(css.navigationPanelBottomTopItem, linkStyle)}
            {...accountLink}>
            {isLoggedIn ? t(ACCOUNT.MY_ACCOUNT) : t(ACCOUNT.LOGIN)}
          </Link>
        )}
      </div>
      {bottomMobileLinks?.length > 0 && (
        <div className={css.navigationPanelBottomBody}>
          {bottomMobileLinks?.map((link: BottomMobileLink, index) => {
            return (
              <Link
                onClick={
                  link?.isContactLink
                    ? (e) => {
                        onClickItem(e)
                      }
                    : null
                }
                key={`bottom_mobile_link_${index}`}
                className={cx(css.navigationPanelBottomBodyItem, linkStyle)}
                {...link}
              />
            )
          })}
        </div>
      )}
      <div className={css.navigationPanelBottomTop}>
        <Link
          href={null}
          className={cx(css.navigationPanelBottomTopItem, linkStyle)}
          onClick={openMarketPopin}>
          {`${langage} (${symbol})`}
        </Link>
      </div>
      <div className={css.navigationPanelBottomBottom}>
        <span className={cx(css.copyright, copyrightStyle)}>
          {t(GENERAL.COPYRIGHT)}
        </span>
      </div>
    </div>
  )
}

export default function NavigationPanel({
  className,
  links,
  onOpenSubpanel,
  locale,
}: NavigationProps) {
  const router = useRouter()
  const [searchValue, setSearchValue] = useState('')
  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const inputStyle = useStyle({
    textPreset: GlobalTextPreset.Input30_56SangBleu,
  })
  const plusStyle = useStyle({
    textPreset: GlobalTextPreset.Cta16Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const onInputChange = (e) => {
    setSearchValue(e.currentTarget.value)
  }

  const onSearchSubmit = (e) => {
    e.preventDefault()

    router.push(
      { pathname: SINGLE_ROUTES.search.href, query: { q: searchValue } },
      null,
      {
        locale,
      },
    )
  }

  useEffect(() => {
    return () => {
      setSearchValue('')
    }
  }, [])
  return (
    <div className={cx(className, css.NavigationPanel)}>
      <div className={css.navigationPanelTop}>
        <form action="" onSubmit={onSearchSubmit} className={css.search}>
          <Input
            preset={GlobalInputPreset.Search}
            value={searchValue ?? null}
            onChange={onInputChange}
            className={css.searchBar}
            inputClassName={cx(css.searchInput, inputStyle)}
          />
        </form>
        {links?.length > 0 && (
          <div className={cx(css.navigationPanelFirstLevel)}>
            {links?.map((item, index) => {
              const hasSubnav = !!item?.secondLevelItems

              return (
                <div
                  className={css.navigationPanelFirstLevelItem}
                  key={`link_first_level_mobile_${index}`}>
                  <Link
                    className={cx(css.link, linkStyle)}
                    onClick={
                      hasSubnav
                        ? () => {
                            onOpenSubpanel(index)
                          }
                        : null
                    }
                    {...item?.link}>
                    <>
                      {item?.label}
                      {hasSubnav && (
                        <p className={cx(css.linkMore, plusStyle)}>+</p>
                      )}
                    </>
                  </Link>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

import classnames from 'classnames/bind'
import React from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type SpinnerProps = React.SVGProps<SVGSVGElement> & {
  className?: string
  color?: string
}

export default function Spinner({
  className,
  color = '#456d45',
}: SpinnerProps) {
  return (
    <svg className={cx(css.spinner, className)} viewBox="0 0 42 42">
      <circle
        className={cx(css.path)}
        cx="21"
        cy="21"
        r="20"
        fill="none"
        strokeWidth="1"
        stroke={color}
      />
    </svg>
  )
}

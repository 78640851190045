import classnames from 'classnames'
import { AnimatePresence, m } from 'framer-motion'
import { useState } from 'react'
import {
  FirstLevelNavItem,
  SecondLevelNavItem,
  ThirdLevelNavItem,
} from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Link } from '@unlikelystudio/react-abstract-components'

import NavigationBottomLinks from '~/components/Navigation/Navigation/NavigationBottomLinks'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 1 / 3 }]

export function ThirdLevelItem({
  className,
  link,
  label,
  mobileImage,
}: ThirdLevelNavItem) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Cta10Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const hasImage = !!mobileImage

  return (
    <Link
      {...link}
      className={cx(
        className,
        css.thirdLevelItem,
        hasImage ? null : css.hasNoImage,
      )}>
      {mobileImage && hasImage && (
        <Image
          {...mobileImage}
          layout="fill"
          objectFit="cover"
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          asPlaceholder
        />
      )}
      <span className={cx(css.label, labelStyle)}>{label}</span>
    </Link>
  )
}

export function SecondLevelItem({
  className,
  label,
  link,
  thirdLevelItems,
  activeIndex,
  index,
  onItemActive,
  onItemInactive,
}: SecondLevelNavItem) {
  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.Cta30_SangBleu,
  })
  const triggerStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Grotesk,
  })

  const hasSubLevelItems = !!thirdLevelItems

  const isOpen = activeIndex === index

  // Calculating the empty spaces to fill
  const emptySpacesToFill = thirdLevelItems?.length
    ? ((Math.floor(thirdLevelItems?.length / 3) + 1) * 3) %
      thirdLevelItems?.length
    : 0

  const variants = {
    show: { height: 'auto' },
    hide: { height: 0 },
  }

  return (
    <AnimatePresence>
      <div className={cx(className, css.secondLevelItem)}>
        <div className={css.secondLevelLink}>
          <Link {...link} className={cx(css.link, linkStyle)}>
            {label}
          </Link>
          {hasSubLevelItems && (
            <button
              className={cx(css.trigger, triggerStyle)}
              onClick={() => {
                if (isOpen) {
                  onItemInactive()
                } else {
                  onItemActive()
                }
              }}>
              {isOpen ? `(–)` : `(+)`}
            </button>
          )}
        </div>
        {hasSubLevelItems && (
          <m.div
            variants={variants}
            className={cx(css.secondLevelEntries, {
              isOpen: isOpen && hasSubLevelItems,
            })}
            initial={false}
            animate={isOpen && hasSubLevelItems ? 'show' : 'hide'}
            transition={{ duration: 0.7, ease: [0.85, 0.0, 0.15, 1.0] }}>
            <div className={cx(css.secondLevelEntriesWrapper)}>
              {thirdLevelItems?.map((item, index) => {
                return (
                  <ThirdLevelItem {...item} key={`third_level_item_${index}`} />
                )
              })}
              {Array(emptySpacesToFill)
                .fill(null)
                .map((_, index) => {
                  return (
                    <div
                      className={css.emptySpace}
                      key={`empty_item_${index}`}></div>
                  )
                })}
            </div>
          </m.div>
        )}
      </div>
    </AnimatePresence>
  )
}

export default function SubPanel({
  className,
  seeAllLink,
  secondLevelItems,
  bottomItems,
}: FirstLevelNavItem) {
  const [activeLinkIndex, setActiveLinkIndex] = useState(null)
  return (
    <div className={cx(className, css.NavigationSubPanel)}>
      <div className={css.panelTop}>
        {seeAllLink && (
          <SecondLevelItem className={css.seeAllLink} {...seeAllLink} />
        )}
        {secondLevelItems?.map((item, index) => {
          return (
            <SecondLevelItem
              key={`nav_item_mobile_${index}`}
              activeIndex={activeLinkIndex}
              index={index}
              onItemActive={() => {
                setActiveLinkIndex(index)
              }}
              onItemInactive={() => {
                setActiveLinkIndex(null)
              }}
              {...item}
            />
          )
        })}
      </div>
      {bottomItems && (
        <NavigationBottomLinks
          className={css.panelBottom}
          title={bottomItems?.title}
          links={bottomItems?.links}
        />
      )}
    </div>
  )
}

import classnames from 'classnames/bind'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import {
  getLangsForMarketValue,
  getMarketAndLocaleFromCountryISO,
  getMarketCountriesValues,
} from '~/lib/shopify-market-countries'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import Select from '~/components/Form/Select'
import SquaredCta from '~/components/UI/SquaredCta'

import { removeFiltersQueryParams, useFilter } from '~/providers/FilterProvider'
import { useGeolocation } from '~/providers/GeolocationProvider'
import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useMarketContext } from '~/providers/MarketProvider'
import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import getAlternateUrl from '~/utils/alternate-url'

import { FORM, POPIN } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type MarketsPopinProps = {
  className?: string
  title?: RichTextBlock
  text?: RichTextBlock
  onGlobalChange?: (data) => void
}

export const getLangFromLocaleString = (locale: string) =>
  locale?.split(',')?.[0].split('-')?.[0].toLowerCase() ?? null
function MarketsPopin({
  className,
  title,
  text,
  onGlobalChange,
}: MarketsPopinProps) {
  const locale = useLocale()
  const t = useTranslate()
  const { metas } = useGlobalData()
  const { facets } = useFilter()

  const { isLocaleSameAsUserPreferences, showPopin, userPreferences } =
    useMarketContext()

  const { countryISO } = useGeolocation()

  const localeFromCountryISO = getMarketAndLocaleFromCountryISO(countryISO)

  const marketPreferences =
    showPopin && isLocaleSameAsUserPreferences
      ? {
          countryCode: userPreferences?.countryCode,
          marketValue: userPreferences?.marketName,
        }
      : localeFromCountryISO
      ? { countryCode: countryISO, marketValue: localeFromCountryISO.market }
      : null

  const chosenLocalePreferences =
    showPopin && isLocaleSameAsUserPreferences ? locale : null

  const [chosenMarket, setChosenMarket] = useState(marketPreferences)
  const [chosenLocale, setChosenLocale] = useState(chosenLocalePreferences)

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title24SangBleuLight,
    color: GlobalThemeColors.Black,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label12SangBleu,
    textStyling: GlobalTextStyling.Italic,
    color: GlobalThemeColors.Gray,
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Grotesk,
  })

  const redirectToLang = () => {
    const selectedLang = metas?.alternateLanguages?.find(
      (altLng) => altLng?.locale === chosenLocale,
    )
    const actualParams = removeFiltersQueryParams(facets)
    const params = qs.stringify(actualParams, {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })

    const processedParams = `${params ? `?${params}` : ''}`
    const pathname = selectedLang?.url
      ? `${selectedLang?.url}${processedParams}`
      : getAlternateUrl(`/${chosenLocale}${processedParams}`)

    window.location.href = pathname
  }

  const onChangeCountry = (e) => {
    const chosenValue = JSON.parse(e.currentTarget.value)

    setChosenLocale(null)
    setChosenMarket(chosenValue)
  }

  const onChangeLang = useCallback((e) => {
    const chosenValue = e.currentTarget.value

    setChosenLocale(chosenValue)
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()

    onGlobalChange({
      countryCode: chosenMarket.countryCode,
      locale: chosenLocale,
      marketName: chosenMarket.marketValue,
    })

    if (locale !== chosenLocale) {
      redirectToLang()
    }
  }

  const hasChosenMarket = chosenMarket && chosenMarket?.marketValue
  const availableLangsForMarket = hasChosenMarket
    ? getLangsForMarketValue(locale, chosenMarket.marketValue)
    : []

  const memoizedValues = {
    countries: getMarketCountriesValues(locale),
    availableLangsForMarket,
  }

  //@ts-ignore
  // Client language
  const browserLang = navigator?.language || navigator?.userLanguage
  const languageDetected = getLangFromLocaleString(browserLang) ?? null

  const foundedLocaleFromBrowserLang =
    memoizedValues?.availableLangsForMarket?.find((item) => {
      return getLangFromLocaleString(item.value) === languageDetected
    }) ?? null

  useEffect(() => {
    // Setting default value is not triggering the onChangeLang
    // So we have to set it like this
    if (foundedLocaleFromBrowserLang && !chosenLocale) {
      setChosenLocale(foundedLocaleFromBrowserLang.value)
    }
  }, [foundedLocaleFromBrowserLang, chosenLocale])

  return (
    <div className={cx(css.MarketsPopin, className)}>
      <div className={css.content}>
        {title && (
          <RichText className={cx(css.title, titleStyle)} render={title} />
        )}
        {text && <RichText className={cx(css.text, textStyle)} render={text} />}
        <div className={css.selectors}>
          <div className={css.item}>
            <p className={cx(css.label, labelStyle)}>
              {t(POPIN.MARKET_COUNTRIES)}
            </p>
            <Select
              className={css.select}
              placeholder={t(POPIN.MARKET_CHOOSE_COUNTRY)}
              defaultValue={
                marketPreferences ? JSON.stringify(chosenMarket) : null
              }
              options={memoizedValues.countries}
              onChange={onChangeCountry}
            />
          </div>
          {chosenMarket &&
            chosenMarket?.marketValue &&
            memoizedValues.availableLangsForMarket.length > 0 && (
              <div className={css.item}>
                <p className={cx(css.label, labelStyle)}>
                  {t(POPIN.MARKET_LANGS)}
                </p>
                <Select
                  className={css.select}
                  placeholder={t(POPIN.MARKET_CHOOSE_LANG)}
                  defaultValue={
                    chosenLocalePreferences
                      ? chosenLocale
                      : foundedLocaleFromBrowserLang
                      ? foundedLocaleFromBrowserLang.value
                      : null
                  }
                  key={`select_${chosenMarket.countryCode}`}
                  options={memoizedValues?.availableLangsForMarket ?? []}
                  onChange={onChangeLang}
                />
              </div>
            )}
        </div>
      </div>
      <div className={css.bottom}>
        <SquaredCta
          className={css.button}
          theme={GlobalThemeColors.Black}
          disabled={!chosenLocale || !chosenMarket}
          withBackground
          withBorder={false}
          onClick={onSubmit}
          withHover>
          {t(FORM.SUBMIT)}
        </SquaredCta>
      </div>
    </div>
  )
}

MarketsPopin.defaultProps = {}

export default MarketsPopin

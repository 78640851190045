import classnames from 'classnames/bind'
import React, { CSSProperties } from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type RatioProps = {
  className?: string
  preset?: string
  styleContainer?: CSSProperties
  ratio?: string
  children?: (params: any) => void | any
}
/**
 * Ratio component to keep a proportion
 * @param props
 * @returns Component
 */
function Ratio({
  ratio,
  className,
  styleContainer = {},
  children,
}: RatioProps) {
  return (
    <div className={cx(css.Ratio, className)}>
      <div className={cx(css.ratioContainer, ratio)} style={styleContainer}>
        {children?.(css.children)}
      </div>
    </div>
  )
}

Ratio.defaultProps = {}

export type { Ratios } from './maths'
export default Ratio

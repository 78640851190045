import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { SINGLE_ROUTES } from '~/lib/constants'

import InlineCta, { InlineCtaTextPreset } from '~/components/UI/InlineCta'

import useGetCart from '~/hooks/cart/useGetCart'
import useSingleRoutesRedirect from '~/hooks/useSingleRoutesRedirect'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type CartButtonProps = {
  className?: string
  theme?: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  textPreset?: InlineCtaTextPreset
}

function CartButton({
  className,
  theme,
  textPreset,
  ...rest
}: CartButtonProps) {
  const { cart } = useGetCart()
  const redirect = useSingleRoutesRedirect()
  const t = useTranslate()

  return (
    <InlineCta
      className={cx(className)}
      textPreset={textPreset}
      lineProps={{ initialLineState: 'none' }}
      theme={theme}
      {...rest}
      onClick={() => {
        redirect(SINGLE_ROUTES.cart)
      }}
      {...{
        children:
          cart?.productsQuantity > 0
            ? `${t(CTA.CART)} (${cart?.productsQuantity})`
            : `${t(CTA.CART)} (0)`,
      }}
    />
  )
}

CartButton.defaultProps = {}

export default CartButton

import classnames from 'classnames/bind'
import React from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ErrorListProps = {
  className?: string
  name?: string
  id?: string
}

export function ErrorList({ className, name, id }: ErrorListProps) {
  const t = useTranslate()

  const { formState } = useFormContext() || {}
  const error: FieldErrors = formState?.errors?.[name] ?? null

  const hasErrors = error?.type || error?.types?.length > 0

  const divProps = {
    className: cx(css.ErrorList, className),
    ...(id ? { id: id } : {}),
  }

  return (
    hasErrors && (
      <div {...divProps}>
        {error?.type && <div>{t(`error.${error?.type}`)}</div>}
        {error?.types?.map((err, index) => (
          <div key={`${err}${index}`}>{t(`error.${err}`)}</div>
        ))}
      </div>
    )
  )
}

ErrorList.defaultProps = {}

export default ErrorList

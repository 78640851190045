import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { FirstLevelNavItem, SecondLevelNavItem } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'

import { Link } from '@unlikelystudio/react-abstract-components'

import NavigationBottomLinks from '~/components/Navigation/Navigation/NavigationBottomLinks'
import { useNavBannerContext } from '~/components/Navigation/NavigationBanner'
import { BigCloseIcon } from '~/components/UI/Icons'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 520 / 1440 }]

export function NavItemCta({
  className,
  link,
  label,
  thirdLevelItems,
  onItemActive,
  onItemInactive,
  onItemMouseEnter,
  onItemMouseOut,
  activeIndex,
  index,
}: SecondLevelNavItem) {
  const t = useTranslate()
  const hasSubLevelItems = !!thirdLevelItems

  const isOpen = activeIndex === index

  const linkStyle = useStyle({ textPreset: GlobalTextPreset.Title44SangBleu })

  const variants = {
    open: {
      width: 'auto',
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    idle: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.4,
      },
    },
  }

  return (
    <div className={cx(css.NavigationItemCta, className)} onMouseEnter={onItemActive} onMouseLeave={onItemInactive}>
      <Link
        className={cx(css.link, linkStyle)}
        {...link}
        onMouseEnter={onItemMouseEnter}
        onMouseOut={onItemMouseOut}>
        {label}
      </Link>
      {hasSubLevelItems && (
        <div className={css.thirdLevelLinks}>
          <AnimatePresence>
            <m.div
              initial={'idle'}
              variants={variants}
              animate={isOpen ? 'open' : 'idle'}
              key="animation"
              className={css.thirdLevelItems}
              >
              {thirdLevelItems?.map((thirdLevelLink, index) => {
                return (
                  <InlineCta
                    {...thirdLevelLink?.link}
                    key={`third_level_${index}`}
                    lineProps={{ initialLineState: 'none' }}
                    className={css.thirdLevelLink}>
                    {thirdLevelLink.label}
                  </InlineCta>
                )
              })}
            </m.div>
          </AnimatePresence>
        </div>
      )}
    </div>
  )
}

export default function NavigationPanel({
  closePanel,
  seeAllLink,
  secondLevelItems,
  bottomItems,
}: FirstLevelNavItem) {
  const t = useTranslate()
  const [activeLinkIndex, setActiveLinkIndex] = useState(null)
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null)
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const [isVisible] = useNavBannerContext()
  const asLink = (index) =>
    index !== null && !!secondLevelItems?.[index]?.desktopImage

  const hasLinkOpened = asLink(activeLinkIndex)
  const hasLinkHovered = asLink(hoveredLinkIndex)

  const candidateImage = hasLinkHovered
    ? secondLevelItems?.[hoveredLinkIndex]?.desktopImage
    : null

  return (
    <div className={cx(css.NavigationPanel, { withBanner: isVisible })}>
      <div className={cx(gridStyle, css.panelContent)}>
        <div className={css.panelTop}>
          <button
            className={css.close}
            onClick={closePanel}
            aria-label={t(CTA.CLOSE) ?? 'close'}>
            <BigCloseIcon className={css.icon} />
          </button>

          {seeAllLink && (
            <NavItemCta
              className={cx(css.navItemCta, { isSeeAll: true })}
              {...seeAllLink}
            />
          )}
          {secondLevelItems?.map((item, index) => {
            return (
              <NavItemCta
                key={`nav_item_desktop_${index}`}
                className={css.navItemCta}
                activeIndex={activeLinkIndex}
                index={index}
                onItemMouseEnter={() => {
                  if (!hasLinkOpened) {
                    setHoveredLinkIndex(index)
                  }
                }}
                onItemMouseOut={() => {
                  if (!hasLinkOpened) {
                    setHoveredLinkIndex(null)
                  }
                }}
                onItemActive={() => {
                  setActiveLinkIndex(index)
                }}
                onItemInactive={() => {
                  setActiveLinkIndex(null)
                }}
                {...item}
              />
            )
          })}
        </div>

        {bottomItems && (
          <NavigationBottomLinks
            className={css.panelBottom}
            title={bottomItems?.title}
            links={bottomItems?.links}
          />
        )}

        <AnimatePresence>
          {candidateImage && (
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
              exit={{ opacity: 0, transition: { duration: 0.4 } }}
              className={css.bottomImage}>
              <Image
                {...candidateImage}
                className={css.image}
                ratio={css.ratio}
                sizesFromBreakpoints={IMAGE_SIZES}
                layout="fill"
                objectFit="contain"
                objectPosition="bottom right"
              />
            </m.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

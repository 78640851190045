import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalTextPreset } from '~/@types/text-preset'
import { FACETS } from '~/lib/algolia/sync/set-settings'

import { FacetsResult } from '@unlikelystudio/horizon-algolia'
import {
  Accordion,
  AccordionPanel,
} from '@unlikelystudio/react-abstract-components'

import { CheckBoxType } from '~/components/Form/Checkbox'
import CustomRangeSlider from '~/components/UI/FiltersForm/Filter/CustomRangeSlider'
import FilterGroup from '~/components/UI/FiltersForm/Filter/FilterGroup'

import {
  getMinMaxValuesFromFacet,
  OrderingFilters,
} from '~/providers/FilterProvider'
import { useStyle } from '~/providers/StyleProvider'

import { FILTER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type FilterGroup = Omit<FacetsResult, 'values'> & {
  inputType?: CheckBoxType
  values?: {
    value: string
    label?: string
  }[]
}
export type FiltersPanelProps = {
  className?: string
  filtersGroups?: FilterGroup[]
}

function getColumnFilterItem({ type, values, filtersGroups, ...rest }) {
  switch (type) {
    case 'numeric_price':
      return (
        <CustomRangeSlider
          className={cx(css.slider)}
          type={type}
          defaultValue={getMinMaxValuesFromFacet(type, filtersGroups)}
        />
      )
    case 'order_by':
      return (
        <FilterGroup
          className={cx(css.radio)}
          type={type}
          withViewMore={false}
          inputType={CheckBoxType.RADIO}
          values={values}
          {...rest}
        />
      )
    case 'metal':
      return (
        <FilterGroup
          className={cx(css.checkbox)}
          type={type}
          values={values}
          showAllItems
          withViewMore={
            type !== FACETS.CATEGORIES_V2 && type !== FACETS.SUB_CATEGORIES
          }
          {...rest}
        />
      )
    default:
      return (
        <FilterGroup
          className={cx(css.checkbox)}
          type={type}
          values={values}
          withViewMore={
            type !== FACETS.CATEGORIES_V2 && type !== FACETS.SUB_CATEGORIES
          }
          {...rest}
        />
      )
  }
}

function Filter({ className, filtersGroups }: FiltersPanelProps) {
  const t = useTranslate()

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18SangBleu,
  })
  const charStyle = useStyle({
    textPreset: GlobalTextPreset.Text12GroteskMedium,
  })

  const getFilterGroupByType = (type: string) => {
    const index = filtersGroups?.findIndex((item) => item?.type === type)
    return index >= 0 ? filtersGroups[index] : null
  }

  const filters = filtersGroups?.filter((item) => {
    return (
      item?.type !== FACETS.PRICE && item?.type !== OrderingFilters.ORDER_BY
    )
  })

  const priceRange = getFilterGroupByType(FACETS.PRICE)
  const orderBy = getFilterGroupByType(OrderingFilters.ORDER_BY)

  const renderOptions = (entry) => {
    return (
      entry?.values?.length > 0 && (
        <div className={cx(css.columnFilters, css?.[entry?.type])}>
          {getColumnFilterItem({
            type: entry?.type,
            values: entry?.values,
            filtersGroups,
            ...entry,
          })}
        </div>
      )
    )
  }

  const renderIsolatedFilter = (entry) => {
    return (
      <div className={cx(css.column, css.isolatedFilter)}>
        <div className={cx(css.columnTitle, titleStyle)}>
          {t(FILTER.FACET(entry?.type))}
        </div>
        {renderOptions(entry)}
      </div>
    )
  }

  return (
    <div className={cx(className, css.Filter)}>
      {renderIsolatedFilter(priceRange)}
      <Accordion
        autoHide={false}
        /** Pre open accordion panel for facets categories or sub categories */
        index={[
          filters?.findIndex(
            (entry) =>
              entry?.type === FACETS.CATEGORIES_V2 ||
              entry?.type === FACETS.SUB_CATEGORIES,
          ),
        ]?.filter((index) => index !== -1)}>
        {filters?.map(({ type, values, ...rest }, index) => {
          return (
            <AccordionPanel
              className={css.column}
              key={`column_${index}`}
              index={index}
              head={(isActive) => {
                const char = isActive ? '-' : '+'

                return (
                  <div className={css.head}>
                    <div className={cx(css.columnTitle, titleStyle)}>
                      {t(FILTER.FACET(type))}
                    </div>
                    <span className={cx(charStyle, css.stateIndicator)}>
                      (<span className={css.char}>{char}</span>)
                    </span>
                  </div>
                )
              }}
              body={renderOptions({ type, values, ...rest })}
            />
          )
        })}
      </Accordion>
      {renderIsolatedFilter(orderBy)}
    </div>
  )
}

export default Filter

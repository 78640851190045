import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { ErrorOption } from 'react-hook-form'
import { COOKIE_KEYS } from '~/lib/constants'
import {
  requestNextApiEndpoint,
  UNLIKELY_WISHLIST_CREATE_PARAMS,
} from '~/lib/handle-api'

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import useGetWishlistQueryKey from '~/hooks/account/wishlist/useGetWishlistQueryKey'
import useCurrency from '~/hooks/useCurrency'
import useLocale from '~/hooks/useLocale'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useCreateWishlist(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const wishlistQueryKeys = useGetWishlistQueryKey()
  const queryClient = useQueryClient()
  const onMutationError = useOnMutationError(setError)
  const { customerAccessToken } = useCustomerContext()
  const currency = useCurrency()
  const locale = useLocale()

  return useMutation<any, any, any>(
    async () => {
      if (!customerAccessToken) return null

      return await requestNextApiEndpoint(UNLIKELY_WISHLIST_CREATE_PARAMS, {
        accessToken: customerAccessToken,
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }
        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        const wishlistId = data?.wishlist?.id
        Cookies.set(COOKIE_KEYS.wishlistId(currency, locale), wishlistId)
        queryClient.invalidateQueries(wishlistQueryKeys)
        mutationOptions?.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}

/* eslint-disable react/prop-types */
// @ts-nocheck
import { ElementType, forwardRef } from 'react'
import { Richtext as RichTextBlock } from 'storyblok-js-client'
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer'
import { STORYBLOK_COMPONENTS } from '~/lib/storyblok-types'

import { Link } from '@unlikelystudio/react-abstract-components'

import InformationTag from '~/components/UI/InformationTag'

import useLocale from '~/hooks/useLocale'

import { isRTFilled } from '~/utils/check-empty-string'

import serializeLink from '~/data/serialize-link'

type ReplacePattern = {
  stringToReplace: string
  replacementString: string
}
export type RichTextProps = {
  className?: string
  children?: undefined
  innerRef?: any
  style?: any
  as?: ElementType
  render?: RichTextBlock
  replacePatterns?: ReplacePattern[]
}
/**
 * Richtext component
 * @param props
 * @returns Component
 */
function RichText(
  {
    className,
    as: Tag = 'div',
    style,
    render: renderText,
    replacePatterns = null,
  }: RichTextProps,
  ref: any,
) {
  const locale = useLocale()

  return isRTFilled(renderText) ? (
    <Tag ref={ref} className={className} style={style}>
      {render(renderText, {
        ...(replacePatterns
          ? {
              textResolver: (text) => {
                let replacedText = text ?? ''

                replacePatterns?.forEach((pattern) => {
                  const regexp = new RegExp(pattern.stringToReplace, 'g')

                  replacedText =
                    replacedText.replace(regexp, pattern.replacementString) ??
                    text
                })

                return replacedText
              },
            }
          : {}),
        blokResolvers: {
          [STORYBLOK_COMPONENTS.PRODUCT_INFORMATION_TAG]: (props) => {
            return (
              <InformationTag
                className="information-tag"
                label={props?.label}
              />
            )
          },
        },
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            const linkProps = serializeLink(
              { id: 'link-wysiwyg', ...props },
              locale,
            )
            return <Link {...linkProps}>{children}</Link>
          },
        },
      })}
    </Tag>
  ) : null
}

export type { Richtext as RichTextBlock } from 'storyblok-js-client'
export default forwardRef(RichText)

import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { useEffect } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useStableVh } from '@unlikelystudio/react-hooks'

import ConditionalCrawlerDetector from '~/components/Abstracts/ConditionalCrawlerDetector'
import FixCss from '~/components/Abstracts/FixCss'
import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import NextLoadingBar from '~/components/Abstracts/NextLoadingBar'
import ThirdPartyScripts from '~/components/Abstracts/ThirdPartyScripts'
import Tracking from '~/components/Abstracts/Tracking'
import Page from '~/components/Page'

import AppProviders from '~/providers/AppProviders'
import { useTracker } from '~/providers/TrackerProvider'

import useCursorLoading from '~/hooks/useCursorLoading'
import useLocale from '~/hooks/useLocale'

import waitBeforeTransition from '~/utils/wait-before-transition'

import '~/styles/globals.scss'

waitBeforeTransition()

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath, pathname, isFallback } = useRouter()
  const key = asPath.split('?')?.[0]
  const locale = useLocale()
  const tracker = useTracker()
  useCursorLoading()
  useStableVh()

  useEffect(() => {
    //@ts-ignore
    if (pageProps?.document) tracker.emit(TRACKING_EVENTS.PAGE_VIEW, asPath)
  }, [])

  useEffect(() => {
    // Disable setting cookie when coming from redirect routes to get the last one filled
    if (pathname !== '/redirects/[uid]') {
      Cookies.set('NEXT_LOCALE', locale, {
        expires: 365,
      })
    }
  }, [locale])

  return (
    <React.StrictMode>
      <AppProviders pageProps={pageProps}>
        {
          //@ts-ignore
          !pageProps?.document?.isPreview && (
            <ConditionalCrawlerDetector>
              <Head>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
                  }}
                />
              </Head>
            </ConditionalCrawlerDetector>
          )
        }
        <FixCss />
        <NextLoadingBar />
        {isFallback ? (
          <LoadingComponent />
        ) : (
          <Page key={key} {...pageProps}>
            <Component {...pageProps} />
          </Page>
        )}
        <Tracking key="tracking" />
        <ThirdPartyScripts />
      </AppProviders>
    </React.StrictMode>
  )
}

export default MyApp

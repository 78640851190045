import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { NavigationProps } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'

import CartButton from '~/components/Navigation/Navigation/CartButton'
import NavigationPanel from '~/components/Navigation/Navigation/NavigationDesktop/Panel'
import NavigationLogo from '~/components/Navigation/Navigation/NavigationLogo'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'

import { useLogoScrollProvider } from '~/providers/LogoScrollProvider'
import { useMarketContext } from '~/providers/MarketProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'
import { useINPTrick } from '~/hooks/useINPTrick'
import useMarketCookie from '~/hooks/useMarketCookie'
import useOnKeyDown from '~/hooks/useOnKeyDown'

import { ACCOUNT, GENERAL, LANG_SWITCHER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type NavigationDesktopProps = {
  className?: string
}

function NavigationDesktop({
  className,
  hasScrolled,
  links,
  accountLink,
  searchLink,
  wishlistLink,
  theme,
  logoClassName,
  isLoggedIn = false,
  scrolledTheme,
  locale,
}: NavigationProps) {
  const t = useTranslate()
  const [panelIndex, setPanelIndex] = useState(null)
  const [bodyScrollLock, setBodyScrollLock] = useState(false)
  const router = useRouter()
  const { onInteract } = useINPTrick()

  const langage = t(LANG_SWITCHER.LANGUAGE(locale))
  const symbol = t(LANG_SWITCHER.LANGUAGE_SYMBOL(locale))

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const { logoState, setLogoState, setHideScrollLogo } = useLogoScrollProvider()
  const prevLogoState = useRef(logoState)

  const asPanel = (index) => index !== null && !links?.[index]?.link

  const { setMarketPopinDisplayed } = useMarketCookie()
  const { setDisplayMarketPopin } = useMarketContext()

  const openMarketPopin = (e) => {
    e.preventDefault()

    setMarketPopinDisplayed(true)
    setDisplayMarketPopin(true)
  }

  const isOpen = asPanel(panelIndex)

  const navigationStyle = useStyle({
    backgroundColor: isOpen
      ? GlobalThemeColors.Concrete
      : GlobalThemeColors.White,
  })

  // Listen router to handle route change hide panel
  useEffect(() => {
    const handleRouteChange = () => {
      setPanelIndex(null)
    }

    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [])

  useOnKeyDown(27, () => {
    setPanelIndex(null)
  })

  useEffect(() => {
    if (panelIndex !== null) {
      prevLogoState.current = logoState
      setLogoState(true)
    }
  }, [panelIndex])

  useEffect(() => {
    setHideScrollLogo(isOpen)
    setBodyScrollLock(isOpen)
  }, [isOpen])

  useBodyScrollLock(bodyScrollLock, null, 'navigation')

  const closePanelProps = {
    onMouseEnter: () => {
      setPanelIndex(null)
    },
  }
  const serviceLinkProps = {
    className: cx(css.link),
    textPreset: GlobalTextPreset.Cta11_12Grotesk,
    lineProps: { initialLineState: 'none' },
    theme: isOpen ? GlobalThemeColors.Black : theme,
  }

  return (
    <div
      className={cx(
        css.NavigationDesktop,
        className,
        theme,
        `${scrolledTheme}Scroll`,
        {
          hasScrolled,
          isOpen,
        },
      )}>
      <AnimatePresence onExitComplete={() => setLogoState(false)}>
        {isOpen && (
          <m.div
            className={cx(css.background, navigationStyle)}
            initial={{ opacity: 0 }}
            onClick={() => setPanelIndex(null)}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {hasScrolled && (
          <m.div
            className={cx(css.topBackground, navigationStyle)}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: [0.195, 0.59, 0.435, 0.825],
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
                delay: 0.25,
                ease: [0.46, 0.255, 0.74, 0.48],
              },
            }}
          />
        )}
      </AnimatePresence>
      <div className={cx(gridStyle, css.navigationTop)}>
        <div className={css.leftContent}>
          {links?.length > 0 && (
            <div className={cx(css.linksContainer)}>
              {links?.map((item, index) => {
                const hasSubnav = !!item?.secondLevelItems
                return (
                  <InlineCta
                    className={cx(css.link, { hasSubnav })}
                    textPreset={GlobalTextPreset.Cta11_12Grotesk}
                    key={`link_${index}`}
                    isDiv={hasSubnav}
                    forcedUnderlined={index === panelIndex}
                    lineProps={{ initialLineState: 'none' }}
                    theme={isOpen ? GlobalThemeColors.Black : theme}
                    onMouseEnter={
                      hasSubnav
                        ? () => {
                            setPanelIndex(index)
                          }
                        : () => {
                            setPanelIndex(null)
                          }
                    }
                    {...item?.link}>
                    {item?.label}
                  </InlineCta>
                )
              })}
            </div>
          )}
        </div>
        <div className={cx(css.centerContent)}>
          <NavigationLogo
            theme={isOpen ? GlobalThemeColors.Black : theme}
            className={cx(css.logo, logoClassName)}
            locale={locale}
            {...closePanelProps}
          />
        </div>
        <div className={cx(css.rightContent)}>
          <div className={cx(css.linksContainer)}>
            {searchLink && (
              <InlineCta
                {...(serviceLinkProps as InlineCtaProps)}
                {...searchLink}
                {...closePanelProps}>
                {t(GENERAL.SEARCH)}
              </InlineCta>
            )}
            {accountLink && (
              <InlineCta
                {...(serviceLinkProps as InlineCtaProps)}
                {...accountLink}
                {...closePanelProps}>
                {!isLoggedIn ? t(ACCOUNT.LOGIN) : t(ACCOUNT.MY_ACCOUNT)}
              </InlineCta>
            )}
            {wishlistLink && (
              <InlineCta
                {...(serviceLinkProps as InlineCtaProps)}
                {...wishlistLink}
                {...closePanelProps}>
                {t(GENERAL.WISHLIST)}
              </InlineCta>
            )}

            <CartButton
              className={cx(css.link)}
              textPreset={GlobalTextPreset.Cta11_12Grotesk}
              theme={isOpen ? GlobalThemeColors.Black : theme}
              {...closePanelProps}
            />

            <div className={css.currencySwitch}>
              <InlineCta
                className={cx(css.link)}
                textPreset={GlobalTextPreset.Cta11_12Grotesk}
                lineProps={{ initialLineState: 'none' }}
                theme={isOpen ? GlobalThemeColors.Black : theme}
                onClick={(e) => onInteract(() => openMarketPopin(e))}
                {...closePanelProps}>
                {`${langage} (${symbol})`}
              </InlineCta>
            </div>
          </div>
        </div>
      </div>

      {/* Subpanel */}
      <AnimatePresence>
        {isOpen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }}
            className={cx(css.panelContainer, { isOpen })}>
            <NavigationPanel
              {...links[panelIndex]}
              closePanel={() => {
                setPanelIndex(null)
              }}
            />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

NavigationDesktop.defaultProps = {}

export default NavigationDesktop

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import useWishlistId from '~/hooks/account/wishlist/useWishlistId'
import useCurrency from '~/hooks/useCurrency'
import useLocale from '~/hooks/useLocale'

export default function useGetWishlistQueryKey() {
  const locale = useLocale()
  const currency = useCurrency()
  const wishlistId = useWishlistId()
  const { customerAccessToken } = useCustomerContext()

  return ['get-wishlist', wishlistId, locale, currency, customerAccessToken]
}

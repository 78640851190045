export const STORYBLOK_TYPES = {
  ERROR_PAGE: 'error_page',
  ARCHIVES: 'archives_page',
  HOMEPAGE: 'home_page',
  COMING_SOON: 'coming_soon_page',
  ENABLE_ACCOUNT: 'enable_account_page',
  UNIVERSAL: 'universal_page',
  PRODUCT: 'product_page',
  CATEGORY_PAGE: 'category_page',
  NEW_IN: 'new_in_page',
  CART_PAGE: 'cart_page',
  PRACTICAL_INFORMATIONS_PAGE: 'practical_informations_page',
  INGREDIENT: 'ingredient_page',
  ACCOUNT: 'account_page',
  REGISTER: 'register_page',
  LOGIN: 'login_page',
  SEARCH: 'search_page',
  SHOP: 'shop_page',
  FORGOT_PASSWORD: 'forgot_password_page',
  RESET_PASSWORD: 'reset_password_page',
  DESIGNER: 'designer_page',
  ATTRIBUTE_DESIGNER: 'product_designer',
  BLOG_CATEGORY: 'blog_category',
  PRESS_CATEGORY: 'press_category',
  ROOT_CATEGORY_PAGE: 'root_category_page',
  BLOG_ARTICLE_PAGE: 'blog_article_page',
  BLOG_LIST_PAGE: 'blog_list_page',
  DESIGNER_LIST_PAGE: 'designer_list_page',
  METAL_STONE_PAGE: 'metal_stone_page',
  METAL_STONE_LIST_PAGE: 'metal_stone_list_page',
  WISHLIST: 'wishlist_page',
  PRESS_ARTICLE_PAGE: 'press_article_page',
  PRESS_LIST_PAGE: 'press_list_page',
  SHARED_WISHLIST_PAGE: 'shared_wishlist_page',
} as const

export type TStoryblokTypesKey =
  typeof STORYBLOK_TYPES[keyof typeof STORYBLOK_TYPES]

export const ANALYTICS_PAGE_TYPES = {
  HOMEPAGE: 'homepage',
  FICHE_PRODUIT: 'fiche_produit',
  DESIGNER: 'designer',
  DESIGNER_LIST: 'designer_list',
  ARTICLE_CATEGORY: 'article_category',
  ARTICLE: 'article',
  JOURNAL: 'journal',
  PRESS: 'press',
  CATEGORY: 'category',
  CART: 'cart',
  LEGAL: 'Legal',
  ARCHIVES: 'archives',
  NEW: 'new-in',
  METALS_STONES: 'metals_stones',
  METALS_STONES_LIST: 'metals_stones_list',
  SHOP: 'shop',
  SEARCH: 'Search',
  LOGIN: 'Login',
  REGISTER: 'Register',
  FORGOT: 'Forgot password',
  RESET: 'Reset password',
  ENABLE: 'Enable account',
  USER: 'user',
  EDITO: 'edito',
}

export const PAGE_TYPE_MATCHING_ANALYTICS_TYPE = {
  [STORYBLOK_TYPES.DESIGNER]: ANALYTICS_PAGE_TYPES.DESIGNER,
  [STORYBLOK_TYPES.CATEGORY_PAGE]: ANALYTICS_PAGE_TYPES.CATEGORY,
  [STORYBLOK_TYPES.METAL_STONE_PAGE]: ANALYTICS_PAGE_TYPES.METALS_STONES,
}

export const STORYBLOK_SUBTYPES = {
  PRODUCT_ATTRIBUTES: 'product_attributes',
  PRODUCT_CATEGORY: 'product_category',
  PRODUCT_CATEGORIES: 'product_categories',
  PRODUCT_DESIGNERS: 'product_designers',
  PRODUCT_METALS: 'product_metals',
  PRODUCT_SELECTION: 'product_selections',
  PRODUCT_STONES: 'product_stones',
} as const

export const STORYBLOK_REPEATABLE_TYPES = [
  STORYBLOK_TYPES.UNIVERSAL,
  STORYBLOK_TYPES.PRACTICAL_INFORMATIONS_PAGE,
  STORYBLOK_TYPES.PRODUCT,
  STORYBLOK_TYPES.CATEGORY_PAGE,
  STORYBLOK_TYPES.METAL_STONE_PAGE,
  STORYBLOK_TYPES.BLOG_ARTICLE_PAGE,
  STORYBLOK_TYPES.DESIGNER,
  STORYBLOK_TYPES.SHOP,
]

export const SITEMAP_STORYBLOK_TYPES = [
  STORYBLOK_TYPES.ACCOUNT,
  STORYBLOK_TYPES.ARCHIVES,
  STORYBLOK_TYPES.NEW_IN,
  STORYBLOK_TYPES.ENABLE_ACCOUNT,
  STORYBLOK_TYPES.FORGOT_PASSWORD,
  STORYBLOK_TYPES.HOMEPAGE,
  STORYBLOK_TYPES.BLOG_LIST_PAGE,
  STORYBLOK_TYPES.DESIGNER_LIST_PAGE,
  STORYBLOK_TYPES.METAL_STONE_LIST_PAGE,
  STORYBLOK_TYPES.LOGIN,
  STORYBLOK_TYPES.REGISTER,
  STORYBLOK_TYPES.RESET_PASSWORD,
  STORYBLOK_TYPES.SEARCH,
  ...STORYBLOK_REPEATABLE_TYPES,
]

export type TStoryblokSliceKey =
  typeof STORYBLOK_SLICES[keyof typeof STORYBLOK_SLICES]

export const STORYBLOK_SLICES = {
  BIG_TITLE: 'big_title',
  EDITORIAL_TITLE_PARAGRAPH_CTA: 'editorial_title_paragraph_cta',
  EDITORIAL_TEXT_READ_MORE: 'editorial_text_read_more',
  HERO_VIDEO: 'hero_video',
  HERO_SLIDER: 'hero_slider',
  HERO_SIMPLE_IMAGE: 'hero_simple_image',
  HERO_HALF: 'hero_half',
  HEADER_TWO_COLUMNS_LAYOUT: 'header_two_columns_layout',
  PANEL_CONTACT: 'panel_contact',
  PRODUCTS_GRID: 'products_grid',
  PRODUCTS_ROW_TITLE_LINK: 'product_cards_row_with_title_link',
  PRODUCTS_PINS_PUSH: 'pinned_products_push',
  PUSH_CATEGORIES: 'push_categories',
  PUSH_DESIGNERS: 'push_designers',
  PUSH_METALS_STONES: 'push_metals_stones',
  PUSH_GENERIC: 'push_generic',
  PUSH_STORES: 'push_stores',
  PUSH_SOCIAL: 'push_social',
  PUSH_NEWSLETTER: 'push_newsletter',
  THREE_COLUMNS_IMAGE_WYSIWYG: 'three_columns_image_wysiwyg',
  TWO_COLUMNS_LAYOUT_LINE: 'two_columns_layout_line',
  TWO_COLUMNS_IMAGE_TITLE_WYSIWYGS: 'two_columns_image_title_wysiwygs',
} as const

export const STORYBLOK_COMPONENTS = {
  PRODUCT_INFORMATION_TAG: 'product_information_tag',
  WYSIWYG: 'wysiwyg',
  TABLE: 'table',
  IMAGE_WITH_LABEL: 'image_with_label',
  TEXT_ACCORDION: 'text_accordion',
  PUSH_TITLE_LINK: 'push_title_link',
  QUOTE: 'quote',
  HERO_TWO_COLS_LAYOUT_TITLE_SUBTITLE: 'hero_two_cols_layout_title_subtitle',
  IMAGE_WITH_PINS: 'image_with_pins',
} as const

import markets from '~/../config/markets.json'
import multistore from '~/../config/multistore.json'

import { Option } from '~/components/Abstracts/Form/Select'

import getCountryNameFromCountryCode from '~/utils/get-country-name-from-country-code'

export function getLangsForMarketValue(
  locale: string,
  marketName: string,
): Option[] {
  const marketMultistoreEntries =
    Object.entries(multistore)
      ?.filter(([key, value]) => {
        const storeValues = value

        return storeValues?.market === marketName
      })
      ?.map(([key, value]) => {
        const storeLocale = key
        const storeValues = value

        return {
          label: storeValues?.name,
          value: storeLocale,
        }
      })
      ?.sort((a, b) => {
        if (a?.label < b?.label) return -1
        if (a?.label > b?.label) return 1
        return 0
      }) ?? []

  return marketMultistoreEntries
}

export function getMarketCountriesValues(locale: string): Option[] {
  const countries =
    Object.entries(markets)
      .map(([key, value]) => {
        const currentMarketName = key
        const currentMarketCountries = value

        const foundCountries = currentMarketCountries?.map((country) => {
          return {
            label: getCountryNameFromCountryCode(locale, country),
            value: JSON.stringify({
              countryCode: country,
              marketValue: currentMarketName,
            }),
          }
        })

        return foundCountries
      })
      ?.flat()
      ?.sort((a, b) => {
        return a?.label.localeCompare(b?.label)
      }) ?? []

  return countries
}

/**
 * It takes a country ISO code as an argument and returns a locale object
 * @param {string} countryISO - The country ISO code.
 * @returns the locale object that matches the market and countryISO.
 */
export function getMarketAndLocaleFromCountryISO(countryISO: string) {
  let foundedLocale = null

  /* Looping through the markets and checking if the countryISO is in the currentMarketCountries. If it
  is, it is looping through the multistore and checking if the locale.market is the same as the market. If it is, it is setting
  the foundedLocale to the locale. */
  for (const market in markets) {
    const currentMarketCountries = markets?.[market]
    if (currentMarketCountries.includes(countryISO)) {
      for (const loc in multistore) {
        const locale = multistore?.[loc]
        if (locale.market === market) {
          foundedLocale = locale
          // Break to the first founded entity of market handled locale
          break
        }
      }
    }
  }

  return foundedLocale
}

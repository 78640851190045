import Script from 'next/script'

declare global {
  interface Window {
    Sentry: any
  }
}

function ThirdPartyScripts() {
  return (
    <>
      {/* Polyfill */}
      <Script
        strategy="beforeInteractive"
        src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=Math.hypot%2CObject.assign%2CObject.keys%2CArray.prototype.findIndex%2CArray.prototype.find%2CrequestAnimationFrame%2CIntersectionObserver%2CResizeObserver%2CObject.entries"
      />
    </>
  )
}

export default ThirdPartyScripts

import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors, ThemePageColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import { CloseIcon } from '~/components/Abstracts/Icons'
import RichText, { RichTextBlock } from '~/components/UI/RichText'

import { useStyle } from '~/providers/StyleProvider'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type NavBannerProps = {
  className?: string
  backgroundColor?: ThemePageColors
  enabled?: boolean
  texts?: RichTextBlock[]
  theme: GlobalThemeColors
}

const NavBannerContext = createContext(null)
export type NavBannerContextProviderProps = {
  children?: ReactNode | ReactNode[]
  enabled?: boolean
}
export function NavBannerContextProvider({
  children,
  enabled,
}: NavBannerContextProviderProps) {
  const useBannerState = useState(enabled)
  return (
    <NavBannerContext.Provider value={useBannerState}>
      {children}
    </NavBannerContext.Provider>
  )
}

export function useNavBannerContext() {
  return useContext(NavBannerContext)
}

function NavBanner({ className, texts, theme }: NavBannerProps) {
  const t = useTranslate()
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12SangBleu,
    color: theme,
  })
  const [index, setIndex] = useState(0)
  const [, setIsVisible] = useNavBannerContext()

  useEffect(() => {
    const autoIncrementValues = () => {
      setIndex((prevIndex) => {
        const maxValue = texts?.length - 1
        let newCurrent = null
        if (prevIndex < maxValue && prevIndex >= 0) {
          newCurrent = prevIndex + 1
        } else {
          newCurrent = 0
        }
        return newCurrent
      })
    }
    const interval = setInterval(autoIncrementValues, 6000)
    return () => {
      clearInterval(interval)
    }
  }, [texts, setIndex])

  const themeStyle = useStyle({
    color: theme,
    backgroundColor:
      theme === GlobalThemeColors.Black
        ? GlobalThemeColors.White
        : GlobalThemeColors.Black,
  })

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return texts ? (
    <div className={cx(css.NavBanner, themeStyle, className)}>
      <div className={cx(css.grid, gridStyle)}>
        <div className={css.slider}>
          <AnimatePresence>
            <m.span
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.25, duration: 0.25 },
              }}
              exit={{ opacity: 0, transition: { duration: 0.25 } }}
              className={cx(css.textWrapper)}
              key={`text_item_${index}`}>
              <RichText
                render={texts[index]}
                className={cx(textStyle, css.text)}
              />
            </m.span>
          </AnimatePresence>
        </div>
        <button
          onClick={() => {
            setIsVisible(false)
          }}
          className={cx(css.closeButton)}
          aria-label={t(CTA.CLOSE) ?? 'close'}>
          <CloseIcon theme={theme} className={cx(css.icon)} />
        </button>
      </div>
    </div>
  ) : null
}

NavBanner.defaultProps = {}

export default NavBanner

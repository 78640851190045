import classNames from 'classnames/bind'
import { useMemo } from 'react'
import { Controller, FieldErrors } from 'react-hook-form'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import { ThemeTextColors } from '~/@types/colors'

import { InputProps } from '~/components/Abstracts/Form/Input'

import useLocale from '~/hooks/useLocale'

import css from './styles.module.scss'

const cx = classNames.bind(css)

export type PhoneProps = InputProps & {
  className?: string
  phoneProps?: PhoneInputProps
  theme?: ThemeTextColors
  dropdownClassName?: string
  inputClassName?: string
}

function Phone({
  className,
  phoneProps,
  placeholder,
  defaultValue,
  required,
  inputClassName,
  dropdownClassName,
  errorClassname = css.errors,
  name,
  ...inputProps
}: PhoneProps) {
  const locale = useLocale()

  const country = useMemo(() => {
    const splittedLocale = locale?.split('-')
    return 'fr'
  }, [locale])

  return (
    <Controller
      name="phone"
      rules={{ required }}
      defaultValue={defaultValue ?? null}
      render={({ field: { onChange, value, name }, formState }) => {
        const errorField: FieldErrors = formState?.errors?.[name] ?? null
        const cssError =
          (errorField?.type || errorField?.types?.length > 0) && errorClassname
            ? errorClassname
            : null

        return (
          <PhoneInput
            jumpCursorToEnd
            country={country}
            value={value}
            onChange={onChange}
            preferredCountries={['fr', 'us']}
            regions={['europe', 'america', 'middle-east', 'asia', 'oceania']}
            specialLabel={null}
            containerClass={cx(css.Phone, className, css.important)}
            buttonClass={cx(css.button)}
            dropdownClass={cx(dropdownClassName)}
            inputClass={cx(css.input, inputClassName, cssError)}
            inputProps={{ ...inputProps, name }}
            {...{ ...phoneProps, placeholder }}
          />
        )
      }}
    />
  )
}

Phone.defaultProps = {
  required: false,
  name: 'phone',
}

export default Phone

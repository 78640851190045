import classnames from 'classnames/bind'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import getCurrency from '~/lib/get-currency'

import { FacetsResult } from '@unlikelystudio/horizon-algolia'

import Input from '~/components/Abstracts/Form/Input'

import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import serializePrice from '~/data/serialize-price'

import css from './styles.module.scss'

const cx = classnames.bind(css)
export type CustomRangeSlider = Omit<FacetsResult, 'values'> & {
  className?: string
  defaultValue?: number[]
}

function CustomRangeSlider({
  className,
  type,
  defaultValue,
}: CustomRangeSlider) {
  const indicatorStyle = useStyle({
    borderColor: GlobalThemeColors.Black,
    textPreset: GlobalTextPreset.Cta12Grotesk,
  })

  const locale = useLocale()
  const currency = getCurrency(locale)

  const [min, max] = defaultValue
  const [[currentMin, currentMax], setminMax] = useState<number[]>(defaultValue)

  const { reset, watch, getValues } = useFormContext()

  const currentInputValue = watch(type)

  useEffect(() => {
    if (!currentInputValue) return
    setminMax(JSON.parse(currentInputValue))
  }, [currentInputValue])

  return (
    <div className={cx(css.Slider, className)}>
      <div className={css.sliderContainer}>
        <Slider
          range
          min={min}
          max={max}
          allowCross={false}
          defaultValue={defaultValue}
          {...(currentInputValue
            ? { value: JSON.parse(currentInputValue) }
            : {})}
          draggableTrack
          onChange={(val) =>
            reset({
              ...getValues(),
              [type]: JSON.stringify(val),
            })
          }
        />
      </div>
      <Input
        type="hidden"
        name={type}
        id={type}
        defaultValue={JSON.stringify(defaultValue)}
      />
      <div className={cx(css.indicatorsContainer)}>
        <div className={cx(css.priceIndicator, indicatorStyle)}>
          {serializePrice(locale, currency, Math.floor(currentMin / 100) * 100)}
        </div>
        <div className={cx(css.priceIndicator, indicatorStyle)}>
          {serializePrice(locale, currency, Math.ceil(currentMax / 100) * 100)}
        </div>
      </div>
    </div>
  )
}

export default CustomRangeSlider

import useLocale from '~/hooks/useLocale'

import { getLang } from '~/utils/locales'

function useLang(): string {
  const locale = useLocale()
  return getLang(locale) ?? locale
}

export default useLang

import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useThrottledCallback } from 'use-debounce'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { NavigationProps } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import CartButton from '~/components/Navigation/Navigation/CartButton'
import NavigationLogo from '~/components/Navigation/Navigation/NavigationLogo'
import NavigationPanel, {
  NavigationBottomPanel,
} from '~/components/Navigation/Navigation/NavigationMobile/Panel'
import SubPanel from '~/components/Navigation/Navigation/NavigationMobile/SubPanel'
import { useNavBannerContext } from '~/components/Navigation/NavigationBanner'
import InlineCta from '~/components/UI/InlineCta'

import { useLogoScrollProvider } from '~/providers/LogoScrollProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'

import { CTA, GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function NavigationMobile({
  className,
  hasScrolled,
  scrolledTheme,
  accountLink,
  searchLink,
  wishlistLink,
  links,
  theme,
  logoClassName,
  isLoggedIn,
  bottomMobileLinks,
  locale,
}: NavigationProps) {
  const router = useRouter()
  const t = useTranslate()

  const delayBeforeDestroyComponent = 400
  const ease = {
    enter: [0.195, 0.59, 0.435, 0.825],
    exit: [0.46, 0.255, 0.74, 0.48],
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isSubpanelOpen, setIsSubpanelOpen] = useState(false)
  const [currentSubpanelIndex, setCurrentSubpanelIndex] = useState(null)
  const { logoState, setLogoState, setHideScrollLogo } = useLogoScrollProvider()
  const prevLogoState = useRef(logoState)

  const [isVisible] = useNavBannerContext()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.Cta16Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
    color: theme,
  })

  const navigationStyle = useStyle({
    backgroundColor: isOpen
      ? GlobalThemeColors.Concrete
      : GlobalThemeColors.White,
  })

  const onOpenSubpanel = (index) => {
    setIsSubpanelOpen(true)
    setCurrentSubpanelIndex(index)
  }

  // Listen router to handle route change hide panel
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
      setIsSubpanelOpen(false)
      setCurrentSubpanelIndex(null)
      setLogoState(false)
    }

    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    setHideScrollLogo(isOpen)
    if (isOpen) {
      prevLogoState.current = logoState
      setLogoState(true)
    }
  }, [isOpen])
  useBodyScrollLock(isOpen)

  const onClick = useThrottledCallback(() => {
    if (isOpen) {
      if (isSubpanelOpen) {
        setIsSubpanelOpen(false)
        setCurrentSubpanelIndex(null)
      } else {
        setIsOpen(!isOpen)
      }
    } else {
      setIsOpen(!isOpen)
    }
  }, delayBeforeDestroyComponent)

  return (
    <div
      className={cx(
        css.NavigationMobile,
        className,
        theme,
        `${scrolledTheme}Scroll`,
        {
          withBanner: isVisible,
          hasScrolled,
          isOpen,
        },
      )}>
      <AnimatePresence onExitComplete={() => setLogoState(false)}>
        {isOpen && (
          <m.div
            className={cx(css.background, navigationStyle)}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.1,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.1,
              },
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {hasScrolled && (
          <m.div
            className={cx(css.topBackground, navigationStyle)}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }}
          />
        )}
      </AnimatePresence>
      <div className={cx(css.top, gridStyle)}>
        <div className={css.left}>
          <InlineCta
            className={cx(css.link, linkStyle)}
            lineProps={{ initialLineState: 'none' }}
            textPreset={GlobalTextPreset.Cta16Grotesk}
            theme={isOpen ? GlobalThemeColors.Black : theme}
            onClick={onClick}>
            {isOpen
              ? isSubpanelOpen
                ? t(CTA.BACK)
                : t(CTA.CLOSE)
              : t(GENERAL.MENU)}
          </InlineCta>
        </div>
        <div className={css.center}>
          <NavigationLogo
            className={cx(css.logo, logoClassName, { isOpen })}
            theme={isOpen ? GlobalThemeColors.Black : theme}
            locale={locale}
          />
        </div>
        <div className={css.right}>
          <CartButton
            className={cx(css.link, linkStyle)}
            textPreset={GlobalTextPreset.Cta16Grotesk}
            theme={isOpen ? GlobalThemeColors.Black : theme}
          />
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.1 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.1 },
            }}
            className={cx(css.navigationPanel)}>
            {!isSubpanelOpen && (
              <NavigationPanel
                className={css.navigationPanelItem}
                links={links}
                onOpenSubpanel={(index) => {
                  onOpenSubpanel(index)
                }}
              />
            )}
            {isSubpanelOpen && (
              <SubPanel
                className={css.navigationPanelItem}
                {...links[currentSubpanelIndex]}
              />
            )}
            {!isSubpanelOpen && (
              <NavigationBottomPanel
                locale={locale}
                accountLink={accountLink}
                searchLink={searchLink}
                wishlistLink={wishlistLink}
                isLoggedIn={isLoggedIn}
                bottomMobileLinks={bottomMobileLinks}
                delayBeforeDestroy={delayBeforeDestroyComponent}
              />
            )}
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

NavigationMobile.defaultProps = {}

export default NavigationMobile

import classnames from 'classnames/bind'
import { forwardRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Line, LineProps } from '@unlikelystudio/react-abstract-components'

import Cta, { BaseProps } from '~/components/Abstracts/Cta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type InlineCtaTextStyling =
  |typeof GlobalTextStyling.UpperCase
  |typeof GlobalTextStyling.Capitalize
  |typeof GlobalTextStyling.None

export type InlineCtaTextPreset =
  | typeof GlobalTextPreset.Cta10_12Grotesk
  | typeof GlobalTextPreset.Cta10Grotesk
  | typeof GlobalTextPreset.Cta10GroteskRegular
  | typeof GlobalTextPreset.Cta11Grotesk
  | typeof GlobalTextPreset.Cta12Grotesk
  | typeof GlobalTextPreset.Cta11_12Grotesk
  | typeof GlobalTextPreset.Cta12GroteskRegular
  | typeof GlobalTextPreset.Cta16Grotesk
  | typeof GlobalTextPreset.Cta12_10GroteskRegular
  | typeof GlobalTextPreset.Cta10_12GroteskRegular
  | typeof GlobalTextPreset.Cta18SangBleu
  | typeof GlobalTextPreset.Title20_24SangBleu

export type InlineCtaColors =
  | typeof GlobalThemeColors.Black
  | typeof GlobalThemeColors.White
  | typeof GlobalThemeColors.Concrete
  | typeof GlobalThemeColors.SilverChalice
  | typeof GlobalThemeColors.Gray

export type InlineCtaProps = BaseProps & {
  className?: string
  id?: string
  theme?: InlineCtaColors | string
  textPreset?: InlineCtaTextPreset
  textStyling?: InlineCtaTextStyling
  lineProps?: LineProps
  active?: boolean
  forcedUnderlined?: boolean
  innerRef?: any
}

function InlineCta({
  className,
  children,
  lineProps,
  textPreset,
  textStyling,
  innerRef,
  active,
  forcedUnderlined,
  ...rest
}: InlineCtaProps) {
  const props = {
    css,
    cx,
  }

  const textStyle = useStyle({
    textPreset,
    color: rest.theme,
    textStyling: textStyling ?? null,
  })

  return (
    <Cta
      ref={innerRef}
      className={cx(className, textStyle, css.InlineCta, css?.[rest.theme], {
        isDiv: rest?.isDiv,
      })}
      {...props}
      {...rest}>
      {({ isHover }) => (
        <>
          <Line
            className={cx(css.line, rest.theme)}
            theme={rest.theme}
            isVisible={
              forcedUnderlined
                ? true
                : lineProps.initialLineState === 'underlined' || active
                ? !isHover
                : isHover
            }
            {...lineProps}
          />
          {children}
        </>
      )}
    </Cta>
  )
}

InlineCta.defaultProps = {
  textPreset: GlobalTextPreset.Cta10_12Grotesk,
  textStyling: GlobalTextStyling.UpperCase,
  theme: GlobalThemeColors.Black,
  lineProps: {
    isVisible: true,
    acumulator: 1,
    initialLineState: 'underlined',
  },
}

function InlineCtaForwarded(props, ref) {
  return <InlineCta innerRef={ref} {...props} />
}

export default forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  InlineCtaProps
>(InlineCtaForwarded)

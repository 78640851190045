import multistore from '~/../config/multistore.json'
import { isProductionEnv } from '~/lib/is-production'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import { uniq } from '~/utils/uniq'

export type SingleRouteParams = {
  type: string
  href: string
}
type SingleRoutesList = {
  [key: string]: SingleRouteParams
}

export const SINGLE_ROUTES: SingleRoutesList = {
  login: {
    type: STORYBLOK_TYPES.LOGIN,
    href: '/login',
  },
  register: {
    type: STORYBLOK_TYPES.REGISTER,
    href: '/register',
  },
  forgotPassword: {
    type: STORYBLOK_TYPES.FORGOT_PASSWORD,
    href: '/forgot-password',
  },
  resetPassword: {
    type: STORYBLOK_TYPES.RESET_PASSWORD,
    href: '/reset-password',
  },
  resetPasswordConfirmation: {
    type: STORYBLOK_TYPES.RESET_PASSWORD,
    href: '/reset-password-confirmation',
  },
  enableAccount: {
    type: STORYBLOK_TYPES.ENABLE_ACCOUNT,
    href: '/enable-account',
  },
  account: {
    type: STORYBLOK_TYPES.ACCOUNT,
    href: '/account',
  },
  search: {
    type: STORYBLOK_TYPES.SEARCH,
    href: '/search',
  },
  cart: {
    type: STORYBLOK_TYPES.CART_PAGE,
    href: '/cart',
  },
}

export const LOCALES = Object.keys(multistore)
export const CURRENCIES =
  uniq(Object.values(multistore).map((entry) => entry.currency)) ?? []
export const LANGUAGES =
  uniq(Object.values(multistore).map((entry) => entry.lang)) ?? []

export const MARKETS =
  uniq(Object.values(multistore).map((entry) => entry.market)) ?? []

export const BACK_IN_STOCK_PRODUCT_DURATION = 10
export const NEW_PRODUCT_DURATION = 15

export const COOKIE_KEYS = {
  recentlyViewed: 'recently-viewed-cookie',
  eventPopin: 'event-pop-in',
  cartId: 'shopify-checkout-id',
  customerAccessToken: 'customer-access-token',
  globalGeolocationPopIn: 'global-geolocation-pop-in',
  cartGeolocationPopIn: 'checkout-geolocation-pop-in',
  globalMarketPopinUserPreferences: 'global-market-pop-in-user-preferences',
  globalMarketPopinDisplayed: 'global-market-pop-in-displayed',
  wishlistId: (currency: string, locale: string) =>
    `${process.env.NEXT_PUBLIC_ENV}-${process.env.NEXT_PUBLIC_PROJECT_NAME}-wishlist-id-${currency}-${locale}`,
  newsletterPopIn: (currency) => `newsletter-pop-in_${currency}`,
  newsletterPopInAttempts: (currency) =>
    `newsletter-pop-in_attempts_${currency}`,
}

export const SHOPIFY_CART_ATTRIBUTES = {
  cartLang: 'checkout_lang',
  trackingData: 'tracking_data',
  giftsIds: 'gifts_ids',
}

export const SHOPIFY_ATTRIBUTES = {
  name: '_name',
  slug: '_slug',
  image: '_image',
  shopifyId: '_shopifyId',
  category: '_category',
  variant: '_variant',
  productVariantId: '_productVariantId',
  unserializedPrice: '_unserializedPrice',
  gift: '_gift',
  giftPrice: '_gift_price',
  trackingData: '_trackingData',
  sample: '_sample',
  designer: '_designer',
  reference: '_reference',
  isPreOrder: '_isPreOrder',
  isAdjusting: '_isAdjusting',
  isUniqueSize: '_isUniqueSize',
  selectedSize: '_selectedSize',
}

export const SHOPIFY_LOCALIZED_ATTRIBUTES = {
  adjustedSize_en: 'Adjusted size',
  adjustedSize_fr: 'Taille ajustée',
}

export const OriginType = {
  DETAIL_VIEW: 'detail_view',
  UPSELLS: 'upsells',
} as const

export type OriginType = typeof OriginType[keyof typeof OriginType]

export const AccountCreationOrigin = {
  REGISTER_PAGE: 'register_page',
}

export type AccountCreationOrigin =
  typeof AccountCreationOrigin[keyof typeof AccountCreationOrigin]

export const TRACKING_EVENTS = {
  ACCOUNT_CREATION: 'account_creation',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  CART_BEGIN: 'begin_checkout',
  CTA_CLICKED: 'cta_clicked',
  FILTERS_CHECK_FACET: 'filters_check_facet',
  FILTERS_SUBMIT: 'filters_submit',
  FILTERS_UNCHECK_FACET: 'filters_uncheck_facet',
  LOGIN: 'login',
  NEWSLETTER_SUBSCRIPTION: 'newsletter_subscription',
  OPEN_CART: 'view_cart',
  PAGE_VIEW: 'pageview',
  PRODUCT_ADD_TO_CART: 'add_to_cart',
  PRODUCT_DETAIL: 'view_item',
  PRODUCT_PAGE_VIEW: 'product pageview',
  PRODUCT_REMOVE_FROM_CART: 'remove_from_cart',
  SCROLL: 'scroll',
  SIGN_UP: 'sign_up',
}

export const NEWSLETTER_TAGS = {
  COMING_SOON: 'coming_soon',
  FOOTER: 'website_footer',
  POPIN: 'website_popin',
}

export const PRODUCT_NOTES = {
  ADJUSTABLE_FROM_SIZE_FOUND: 'adjustableFromSizeFound',
  NOT_ADJUSTABLE: 'notAdjustable',
  STOCK_ALERT: 'stockAlert',
  SPECIAL_ORDER: 'specialOrder',
  PRE_ORDER: 'preOrder',
}

export const COOKIE_PRO_ID = isProductionEnv()
  ? '04daf923-dc1f-4fc5-9aad-d260340d4bfe'
  : '6162d7c6-92ed-46c5-a6bd-33a4d2b5f72d'

export const PROJECT_NAME = 'whitebird'
export const IS_COMING_SOON = process.env.NEXT_PUBLIC_COMING_SOON === '1'
export const HAS_SAMPLES = process.env.NEXT_PUBLIC_CART_SAMPLES === '1'
export const ALGOLIA_HITS_PER_PAGE = 32
export const ALGOLIA_PRODUCT_INDEX = 'horizon_product'

export const contactEmailSubject = 'Demande de renseignements'

export const RECAPTCHA_PUBLIC_API_KEY = isProductionEnv()
  ? '6LfRTAwjAAAAAMS00AMpSKZGROicTnTeHl9w27i2'
  : '6LdBeZggAAAAACbAsR4RZPXav4DBFzJApimeOGDw'

export const DEFAULT_STORE_KEY = 'default'
export const DEFAULT_LOCALE = 'en-fr'
export const DEFAULT_LANG = 'en'
export const DEFAULT_INTL = 'en-US'
export const DEFAULT_MARKET = 'EU'
export const DEFAULT_COUNTRY_CODE = 'US'
export const DEFAULT_CURRENCY = 'eur'

export const QUERY_KEYS = {
  ORDER_ADMIN: 'gerAdminOrder',
}

export const SHOPIFY_STOREFRONT_API_VERSION = '2022-04'
export const SHOPIFY_ADMIN_API_VERSION = '2023-04'
export const STORYBLOK_PUBLIC_RECENTLY_VIEWED_KEY = isProductionEnv()
  ? 'jG4dkVq2fck8r7aVyrgl2Qtt'
  : 'vzSvMULnqBcZRSTIq6bRhgtt'
export const STORYBLOK_SPACE_ID = isProductionEnv() ? '168218' : '160931'
export const SHOPIFY_VARIANT_SELECTED_OPTION_SIZES_NAME = ['TAILLE', 'SIZE']
export const SHOPIFY_VARIANT_SIZE_DEFAULT_KEYS = [
  'taille',
  'size',
  'title',
  'titre',
]

export const STORYBLOK_SPACE_CACHE_ID = isProductionEnv() ? '168218' : '160931'

export const FOLDER_CACHE_ID = isProductionEnv() ? '484325616' : '484325122'

export const SHOPIFY_METAFIELDS_KEYS = {
  TITLE_EN: 'i18n.title_en',
  DETAILS_EN: 'i18n.details_en',
  DETAILS_FR: 'i18n.details_fr',
  DESCRIPTION_EN: 'i18n.description_en',
}

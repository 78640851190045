import classnames from 'classnames/bind'
import React, {
  ComponentType,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  ReactChild,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslate } from 'react-polyglot'

import { CloseIcon } from '~/components/Abstracts/Icons'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'

import { usePopIn } from '~/providers/PopInProvider'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PopInCloseIconProps = {
  className?: string
  onClick?(): void
  as?: JSX.Element
}

export const PopInCloseIcon = ({
  className,
  onClick,
  as,
}: PopInCloseIconProps) => {
  const { removeCurrent } = usePopIn()
  const t = useTranslate()
  const processedOnClick = () => {
    removeCurrent()
    onClick?.()
  }

  return (
    <button
      onClick={processedOnClick}
      className={cx(className, css.PopInCloseIcon)}
      aria-label={t(CTA.CLOSE) ?? 'close'}>
      {as ? as : <CloseIcon />}
    </button>
  )
}
export type customCloseProps = PropsWithChildren<{
  className?: string
  props?: InlineCtaProps
}>

export const CustomClose = ({ className, props }: customCloseProps) => {
  const { removeCurrent } = usePopIn()
  const { className: customClassName, children, ...rest } = props

  const processedOnClick = useCallback(() => {
    removeCurrent()
  }, [removeCurrent])

  return (
    <InlineCta
      className={cx(className, customClassName)}
      onClick={processedOnClick}
      {...rest}>
      {children}
    </InlineCta>
  )
}

export type PopInProps = PropsWithChildren<{
  className?: string
  isCloseButton?: boolean
  closeButton?: PopInCloseIconProps['as']
  customCloseButton?: InlineCtaProps
  closeClassName?: string
  onClickClose?(): void
}>

const PopIn: ForwardRefRenderFunction<HTMLDivElement, PopInProps> = (
  {
    className,
    children,
    isCloseButton = true,
    closeButton,
    closeClassName,
    customCloseButton,
    onClickClose,
  },
  ref,
) => {
  const popInRef = useRef(null)

  useImperativeHandle(ref, () => popInRef.current)

  return (
    <div ref={popInRef} className={cx(className, css.PopIn)}>
      {isCloseButton && !customCloseButton && (
        <PopInCloseIcon
          className={cx(closeClassName)}
          as={closeButton}
          onClick={() => {
            onClickClose?.()
          }}
        />
      )}
      {customCloseButton && (
        <CustomClose className={cx(css.close)} props={customCloseButton} />
      )}
      {children}
    </div>
  )
}

export default forwardRef<HTMLDivElement, PopInProps>(PopIn)

const uniq = (array: any[]): any[] => {
  const processed = []

  for (let i = 0; i < array.length; i++) {
    if (!processed?.find((item) => item === array?.[i]) ? true : false)
      processed.push(array[i])
  }
  return processed
}

const uniqBy = (array: any[], key: string): any[] => {
  const processed = []

  for (let i = 0; i < array.length; i++) {
    if (
      !processed?.find((item) => item?.[key] === array?.[i]?.[key])
        ? true
        : false
    )
      processed.push(array[i])
  }
  return processed
}

export { uniqBy, uniq }

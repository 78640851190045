import { encode } from 'qss'
import { getStorefrontShopObject } from '~/lib/shopify/public-credentials'

import { UnlikelyRequestHeaders } from '@unlikelystudio/commerce-connector'

// declare class UnlikelyError extends Error {
//   payload: ErrorPayload[]
//   constructor(message: string, payload: ErrorPayload[])
// }

export const QueryRoutes = {
  CONTACT: 'contact',
  COUNTRIES_LIST: 'countries-input-list',
  GEOLOCATION: 'geolocation',
  RECAPTCHA: 'verify-recaptcha',
  RECENTLY_VIEWED_PRODUCTS: 'get-recently-viewed-products',
  RECOMMENDED_PRODUCTS: 'get-recommended-products',

  // Stock Alert
  UNLIKELY_STOCK_ALERT_ADD: 'stock-alert/post',

  // Wishlist
  UNLIKELY_WISHLIST_ADD: 'wishlist/add-product',
  UNLIKELY_WISHLIST_CREATE: 'wishlist/upsert',
  UNLIKELY_WISHLIST_GET: 'wishlist/get',
  UNLIKELY_WISHLIST_DELETE: 'wishlist/remove-product',
  UNLIKELY_WISHLIST_GET_PUBLIC_WISHLIST: 'wishlist/get-public-wishlist',
  // Shopify
  SHOPIFY_CUSTOMERS_NEWSLETTER: 'customers/newsletter',
  SHOPIFY_CUSTOMERS_UPDATE_LOCALE: 'customers/update-locale',
  SHOPIFY_ORDER_ADMIN: 'orders/get-admin-order',

  // Google
  GOOGLE_RECAPTCHA: 'verify-recaptcha',
} as const

export type QueryRoutes = typeof QueryRoutes[keyof typeof QueryRoutes]

export const QueryMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export type QueryMethods = typeof QueryMethods[keyof typeof QueryMethods]

type EndpointSettings = {
  action: QueryRoutes
  method: QueryMethods
}

export type QueryPayload = Record<string, string | object | boolean>

type QueryParams = {
  payload?: QueryPayload
  accessToken?: string
  queryParams?: Record<string, string>
}

type GenerateQueryParams = QueryParams & {
  action: QueryRoutes | string
  method: QueryMethods
}

async function generateQuery({
  action,
  payload,
  accessToken,
  queryParams = null,
  method,
}: GenerateQueryParams) {
  const hasBody = method !== QueryMethods.GET
  const shop = getStorefrontShopObject()

  const req = await fetch(
    `/api/${action}/${queryParams ? `?${encode(queryParams)}` : ``}`,
    {
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken && {
          [UnlikelyRequestHeaders.AUTHORIZATION]: accessToken,
          [UnlikelyRequestHeaders.STORE_URL]: shop?.storeUrl,
          [UnlikelyRequestHeaders.STORE_TOKEN]: shop?.storefrontAccessToken,
        }),
      },
      method,
      ...(hasBody && { body: JSON.stringify(payload) }),
    },
  )

  const res = await req.json()

  //TODO: send relevant UnlikelyError (could be different than a Shopify error)
  // if (res.payload) throw new UnlikelyError('shopify', res.payload)

  return res
}

export const requestNextApiEndpoint = async (
  settings: EndpointSettings,
  params?: QueryParams,
) => {
  return await generateQuery({
    ...settings,
    ...(params ? params : {}),
  })
}

// Shopify
export const SHOPIFY_CUSTOMERS_UPDATE_LOCALE_PARAMS = {
  action: QueryRoutes.SHOPIFY_CUSTOMERS_UPDATE_LOCALE,
  method: QueryMethods.PUT,
}

export const SHOPIFY_CUSTOMERS_NEWSLETTER_PARAMS = {
  action: QueryRoutes.SHOPIFY_CUSTOMERS_NEWSLETTER,
  method: QueryMethods.POST,
}

export const SHOPIFY_ADMIN_ORDER_PARAMS = {
  action: QueryRoutes.SHOPIFY_ORDER_ADMIN,
  method: QueryMethods.GET,
}

// Stock Alert
export const UNLIKELY_STOCK_ALERT_ADD_PARAMS = {
  action: QueryRoutes.UNLIKELY_STOCK_ALERT_ADD,
  method: QueryMethods.POST,
}

// Wishlist
export const UNLIKELY_WISHLIST_GET_PARAMS = {
  action: QueryRoutes.UNLIKELY_WISHLIST_GET,
  method: QueryMethods.GET,
}

export const UNLIKELY_WISHLIST_CREATE_PARAMS = {
  action: QueryRoutes.UNLIKELY_WISHLIST_CREATE,
  method: QueryMethods.POST,
}

export const UNLIKELY_WISHLIST_ADD_PARAMS = {
  action: QueryRoutes.UNLIKELY_WISHLIST_ADD,
  method: QueryMethods.POST,
}

export const UNLIKELY_WISHLIST_DELETE_PARAMS = {
  action: QueryRoutes.UNLIKELY_WISHLIST_DELETE,
  method: QueryMethods.POST,
}

export const UNLIKELY_WISHLIST_GET_PUBLIC_WISHLIST_PARAMS = {
  action: QueryRoutes.UNLIKELY_WISHLIST_GET_PUBLIC_WISHLIST,
  method: QueryMethods.POST,
}

// Google
export const GOOGLE_RECAPTCHA_PARAMS = {
  action: QueryRoutes.GOOGLE_RECAPTCHA,
  method: QueryMethods.POST,
}

export async function submitContactForm(params: QueryParams) {
  return await generateQuery({
    ...params,
    action: QueryRoutes.CONTACT,
    method: QueryMethods.POST,
  })
}

export const getGeolocation = async (params?: QueryParams) => {
  return await generateQuery({
    ...params,
    action: QueryRoutes.GEOLOCATION,
    method: QueryMethods.GET,
  })
}

export const getCountriesList = async (locale: string) => {
  return await generateQuery({
    action: QueryRoutes.COUNTRIES_LIST,
    method: QueryMethods.GET,
    queryParams: {
      locale,
    },
  })
}

export const getRecentlyViewedProducts = async (params: QueryParams) => {
  return await generateQuery({
    ...params,
    action: `${QueryRoutes.RECENTLY_VIEWED_PRODUCTS}?locale=${params?.payload?.locale}&uids=${params?.payload?.uids}`,
    method: QueryMethods.GET,
  })
}

export const getRecommendedProducts = async (params: QueryParams) => {
  return await generateQuery({
    // ...params,
    action: `${QueryRoutes.RECOMMENDED_PRODUCTS}?${params?.payload?.query}`,
    method: QueryMethods.GET,
  })
}

import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import pathsMapping from 'config/paths-mapping.json'

import { useGlobalData } from '~/providers/GlobalDataProvider'

import useLocale from '~/hooks/useLocale'

import { stringIsNotEmpty } from '~/utils/check-empty-string'
import numberAsBoolean from '~/utils/number-as-boolean'

type RouterParams = {
  pathname: string
  query: {
    [key: string]: string | string[]
  }
}

type PaginationContextType = {
  algoliaPage?: number
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  routerPush?: (queryParams: RouterParams['query']) => void
  routerParams?: RouterParams
  getRouterParams?: (page?: number) => RouterParams
}

const DYNAMIC_PARAM_KEYS = {
  UID: 'uid',
  PAGE: 'page',
}

const FORMATTED_DYNAMIC_PARAM_KEYS = {
  UID: `/[${DYNAMIC_PARAM_KEYS.UID}]`,
  PAGE: `/[${DYNAMIC_PARAM_KEYS.PAGE}]`,
}

export const PaginationContext = createContext<PaginationContextType>({})

export function usePagination() {
  return useContext(PaginationContext)
}

type PaginationProviderProps = {
  defaultPage: number
  children: JSX.Element | JSX.Element[]
}

export default function PaginationProvider({
  defaultPage,
  children,
}: PaginationProviderProps) {
  const locale = useLocale()
  const { query, push, pathname } = useRouter()
  const { uid, page } = query ?? {}
  const { document } = useGlobalData()

  /**
   * Get page value from [page] param in url
   * The page param can also be a uid if the root category page is paginated
   * Hence page ?? uid
   */
  const processedPageParam = isNaN(Number(page ?? uid))
    ? null
    : Number(page ?? uid)

  // Initial page value (page currently set in url ?? page from server-side ?? 1)
  const initialValue = processedPageParam ?? defaultPage ?? 1

  // Set initial current page state
  const [currentPage, setCurrentPage] =
    useState<PaginationContextType['currentPage']>(initialValue)

  /**
   * Store a processed algolia page value
   * Considering algolia starts at page 0 and our urls at 1
   */
  const algoliaPage = currentPage > 1 ? currentPage - 1 : 0

  const getRouterParams = (page?: number) => {
    {
      /**
       * Use custom page sent as param and default to current page state
       */
      const pageValue = page ?? currentPage

      /**
       * Store the page param ( [page] ) for future use if we are on a paginated page
       */
      const pageParam =
        numberAsBoolean(pageValue) && pageValue > 0
          ? FORMATTED_DYNAMIC_PARAM_KEYS.PAGE
          : ''

      /**
       * The base path will be the baseline of our router params used during pagination
       * It follows our pages directory structure
       * If it already has the [page] param then we don't need to change it
       * If not, simply append the pageParam stored earlier
       */
      const basePath = pathname?.endsWith(pageParam)
        ? pathname
        : `${pathname}${pageParam}`

      /**
       * We need to use the pathMapping from linkresolver to get proper translated pagination / filters links
       * checking if the basePath as a source in pathmapping file and replacing the destination if translated
       *
       */

      // get path mapping for current document
      const currentPathMapping = pathsMapping?.[document?.type]?.[locale]
      const sourcePathMapping = currentPathMapping?.source ?? null
      const destinationPathMapping = currentPathMapping?.destination ?? null
      const translatedSource = sourcePathMapping?.split('/')?.[0] ?? null
      const translatedDestination =
        destinationPathMapping?.split('/')?.[0] ?? null

      // replace if needed
      const translatedBasePath =
        translatedSource && translatedDestination
          ? basePath.replace(translatedDestination, translatedSource)
          : basePath

      /**
       * Retrieve all remaining query params from url (mostly filters or current layout)
       */
      const processedQuery = Object.keys(query ?? {})?.reduce((acc, key) => {
        /**
         * This query already retrieve the additional query params following a '?'
         * Mostly filtering values
         * The [page] & [uid] values will be used separately
         */
        if (key === DYNAMIC_PARAM_KEYS.UID || key === DYNAMIC_PARAM_KEYS.PAGE) {
          return acc
        }
        /**
         * Basically we return all present query params except for uid & page
         */
        return {
          ...acc,
          [key]: query[key],
        }
      }, {})

      const hasValidUID = uid && stringIsNotEmpty(`${uid}`)
      const hasValidPageParam = pageParam && stringIsNotEmpty(pageParam)

      /**
       * If the uid query param is a valid number or if there is no uid
       * Then we are on a root page (because we cannot combine /[uid] & /[page] at the same level)
       */
      const isRoot = !isNaN(Number(uid)) || !uid

      if (!isRoot) {
        return {
          // eg: pathname -> /category/[uid] || /category/[uid]/[page]
          pathname: translatedBasePath,
          query: {
            // Spread all query params (filters)
            ...(processedQuery ?? {}),
            // Add uid param to fill [uid] value from base path
            ...(hasValidUID && { uid: [`${uid}`] }),
            // Add page param to fill [page] value from base path
            ...(hasValidPageParam && { page: [`${pageValue}`] }),
          },
        }
      }

      /**
       * If we are on the root page
       * Remove /[page] from pathname as we are expecting /[uid]
       */
      const processedPathname = translatedBasePath?.replace(
        FORMATTED_DYNAMIC_PARAM_KEYS.PAGE,
        '',
      )

      /**
       * If we are located on the root page
       * Then we need to add /[uid] to the base pathname
       */
      const processedPathnameWithUID = !processedPathname?.includes(
        FORMATTED_DYNAMIC_PARAM_KEYS.UID,
      )
        ? `${processedPathname}${FORMATTED_DYNAMIC_PARAM_KEYS.UID}`
        : processedPathname

      return {
        pathname: processedPathnameWithUID,
        query: {
          ...(query ?? {}),
          // Set the page value as [uid] param
          [DYNAMIC_PARAM_KEYS.UID]: [`${pageValue}`],
          // Override a possible [page] param
          page: [],
        },
      }
    }
  }

  const routerParams: RouterParams = useMemo(() => {
    return getRouterParams(currentPage)
  }, [pathname, uid, currentPage, query])

  // The router push method will mostly be used when setting filter/layout param
  // If the page value changes, then we will use an href from the Abstracts/Pagination
  const routerPush = (queryParams?: RouterParams['query']) => {
    // Get params with possible root setup
    const params = getRouterParams()

    push(
      {
        ...params,
        query: {
          ...(params?.query ?? {}),
          // We have an additional queryParams that can be passed as a method parameter
          // It will be used in the PageWithFilters params when filters values change
          ...(queryParams ?? {}),
        },
      },
      undefined,
      // Set as shallow push & pass locale value
      { shallow: true, locale },
    )
  }

  useEffect(() => {
    // If the initial value isn't set properly on first load
    if (initialValue !== currentPage) {
      // Set current page with appropriate value
      setCurrentPage(initialValue)
    }
  }, [initialValue, defaultPage, processedPageParam])

  const value: PaginationContextType = useMemo(
    () => ({
      algoliaPage,
      routerPush,
      routerParams,
      getRouterParams,
      currentPage,
      setCurrentPage,
    }),
    [
      algoliaPage,
      routerPush,
      routerParams,
      getRouterParams,
      currentPage,
      setCurrentPage,
    ],
  )

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  )
}

import classnames from 'classnames/bind'
import React, {
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'

import { ArrowSelectIcon } from '~/components/Abstracts/Icons'

import objectFilter from '~/utils/filter-object'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type Option = {
  label: string
  value: any
  disabled?: boolean
  selected?: boolean
}

export type SelectProps = Omit<SelectHTMLAttributes<any>, 'onError'> & {
  className?: string
  selectClassName?: string
  optionClassName?: string
  iconClassName?: string
  iconColor?: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  currentValue?: any
  forwardRef?: any
  options?: Option[]
  prefixLabel?: string
  addRequiredIndicatorOnLabel?: boolean
  errorClassname?: string
  withError?: boolean
  onError?: (node: FieldErrors) => void
}

function Select({
  className,
  selectClassName,
  optionClassName,
  iconClassName,
  iconColor,
  icon,
  addRequiredIndicatorOnLabel,
  currentValue,
  options,
  forwardRef,
  placeholder,
  autoFocus,
  onChange,
  prefixLabel,
  required,
  name,
  errorClassname,
  withError,
  onError,
  ...selectProps
}: SelectProps) {
  const { register, formState } = useFormContext() || {}
  const error: FieldErrors = formState?.errors?.[name]
  const selectRef = useRef(null)

  const placeHolder = placeholder
    ? placeholder + (addRequiredIndicatorOnLabel && required ? ' *' : '')
    : null

  const findIndexFromValue = (value: any) => {
    return options?.findIndex((option) => option.value == value)
  }

  const [valueIndex, setValueIndex] = useState(
    currentValue !== null ? findIndexFromValue(currentValue) : null,
  )

  const customOnChange = (e) => {
    const value = e.target.value
    setValueIndex(findIndexFromValue(value))
    onChange?.(e)
  }

  const cssError =
    (withError || error?.type || error?.types?.length > 0) && errorClassname
      ? errorClassname
      : null

  useEffect(() => {
    onError?.(error)
  }, [cssError])

  const validations = {
    required,
  }

  const filteredValidations = objectFilter(
    validations,
    ([, val]) => val !== undefined,
  )

  const setRef = useCallback(
    (node) => {
      if (forwardRef) forwardRef.current = node
      if (selectRef) selectRef.current = node
    },
    [filteredValidations],
  )

  const Icon = icon ?? ArrowSelectIcon

  return (
    <div className={cx(className, css.Select, cssError)}>
      <select
        ref={setRef}
        className={cx(selectClassName, css.select, cssError)}
        onChange={customOnChange}
        autoFocus={autoFocus}
        name={name}
        {...(currentValue && { value: currentValue })}
        {...selectProps}
        {...(name && register?.(name, filteredValidations))}>
        {placeHolder && (
          <option
            className={cx(css.option, optionClassName, cssError)}
            value=""
            disabled
            selected>
            {`${prefixLabel ? `${prefixLabel} ` : ''}${placeHolder}`}
          </option>
        )}
        {options?.map(({ value, label, ...rest }, index) => {
          return (
            <option
              className={cx(css.option, optionClassName, cssError)}
              key={`${value}-${index}`}
              label={label}
              value={value}
              {...rest}>
              {`${
                prefixLabel && index === valueIndex ? `${prefixLabel} ` : ''
              }${label}`}
            </option>
          )
        })}
      </select>
      <Icon
        fill={iconColor || '#000000'}
        className={cx(css.icon, iconClassName, cssError)}
      />
    </div>
  )
}

Select.defaultProps = {
  addRequiredIndicatorOnLabel: true,
}

export default Select

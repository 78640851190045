import classnames from 'classnames/bind'
import AbstractRichText, {
  RichTextProps,
} from 'src/components/Abstracts/RichText'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function RichText({
  className,
  as,
  style,
  render,
  replacePatterns = null,
}: RichTextProps) {
  return (
    <AbstractRichText
      className={cx(css.RichText, className)}
      as={as}
      style={style}
      render={render}
      replacePatterns={replacePatterns}
    />
  )
}

RichText.defaultProps = {}

export default RichText
export type { ISbRichtext as RichTextBlock } from 'storyblok-js-client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LazyMotion } from 'framer-motion'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { I18n } from 'react-polyglot'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANG,
  RECAPTCHA_PUBLIC_API_KEY,
} from '~/lib/constants'
import { getStorefrontShopObject } from '~/lib/shopify/public-credentials'

import {
  ShopifyCurrencyCode,
  ShopifySFCountryCode,
  ShopifySFLanguageCode,
} from '@unlikelystudio/commerce-connector'
import { AlertProvider } from '@unlikelystudio/react-abstract-components'
import {
  CustomerProvider,
  StoreProvider,
  CartProvider,
} from '@unlikelystudio/react-ecommerce-hooks'
import { AnalyticsProvider } from '@unlikelystudio/react-ecommerce-hooks'

import { NavBannerContextProvider } from '~/components/Navigation/NavigationBanner'

import BannerProvider from '~/providers/BannerProvider'
import { BodyScrollLockContextProvider } from '~/providers/BodyScrollLockProvider'
import BreadcrumbProvider from '~/providers/BreadcrumbProvider'
import ClickOutsideProvider from '~/providers/ClickOutsideProvider'
import CssVariablesProvider from '~/providers/CssVariablesProvider'
import FilterProvider from '~/providers/FilterProvider'
import GeolocationProvider from '~/providers/GeolocationProvider'
import GlobalDataProvider from '~/providers/GlobalDataProvider'
import LayoutSwitcherProvider from '~/providers/LayoutSwitcherProvider'
import LogoScrollProvider from '~/providers/LogoScrollProvider'
import MarketProvider from '~/providers/MarketProvider'
import NavigationProvider from '~/providers/NavigationProvider'
import PaginationProvider from '~/providers/PaginationProvider'
import PanelProvider from '~/providers/PanelProvider'
import PopInProvider from '~/providers/PopInProvider'
import { GoogleReCaptchaProvider } from '~/providers/RecaptchaProvider/google-recaptcha-provider'
import SettingsProvider from '~/providers/SettingsProvider'
import { ShopifyAnalyticsProvider as ShopifyAnalytics } from '~/providers/ShopifyAnalyticsProvider'
import StyleProvider from '~/providers/StyleProvider'
import ThemeProvider from '~/providers/ThemeProvider'
import ToastProvider from '~/providers/ToastCenterProvider'
import TrackerProvider from '~/providers/TrackerProvider'

import useCurrency from '~/hooks/useCurrency'
import useMarketCookie from '~/hooks/useMarketCookie'
import ProductsViewedProvider from '~/hooks/useProductsRecentlyViewed/context'

import { getCountryCode, getLang } from '~/utils/locales'

import { DefaultPageData } from '~/data/page-data/serializer'

import gridPresets from '~/styles/grid-presets/styles.module.scss'
import ratioPresets from '~/styles/ratio-presets/styles.module.scss'
import textPresets from '~/styles/text-presets/styles.module.scss'
import stylingPresets from '~/styles/text-presets/styling.module.scss'
import backgroundColors from '~/styles/theme-classes/background-color.module.scss'
import borderColors from '~/styles/theme-classes/border-color.module.scss'
import colors from '~/styles/theme-classes/colors.module.scss'
import themePresets from '~/styles/theme-presets/styles.module.scss'

const motionAsyncFeatures = () =>
  import('~/utils/motion-features').then((res) => res.default)

export type AppProviderProps = {
  children: JSX.Element | JSX.Element[]
  pageProps: DefaultPageData
}

export const stylePresets = {
  textPreset: textPresets,
  textStyling: stylingPresets,
  color: colors,
  grid: gridPresets,
  ratio: ratioPresets,
  backgroundColor: backgroundColors,
  borderColor: borderColors,
} as const

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type ReCaptchaProps = {
  nonce?: string
  defer?: boolean
  async?: boolean
  appendTo?: 'head' | 'body'
  id?: string
}

function AppProvider({ children, pageProps }: AppProviderProps) {
  const { locale } = useRouter()
  const { getMarketPopinUserPreferences, getMarketPopinDisplayed } =
    useMarketCookie()

  const memoizedGlobalProviderData = useMemo(
    () => ({
      global: pageProps?.global,
      document: pageProps?.document,
      metas: pageProps?.metas,
      theme: pageProps?.theme,
      tracking: pageProps?.tracking,
    }),
    [pageProps],
  )

  const cssVariables = useMemo(
    () => ({ 'scroll-bar': '0px', 'scroll-bar-grid': '0px', vh100: '100vh' }),
    [],
  )

  const navigationValues = useMemo(
    () => ({
      productHeaderVisible: false,
    }),
    [],
  )

  const recaptachaProps: ReCaptchaProps = useMemo(
    () => ({
      async: true,
      defer: true,
      appendTo: 'body',
    }),
    [],
  )

  const shop = getStorefrontShopObject()

  const userHasAlreadySeenMarketPopin = getMarketPopinDisplayed()
  const marketUserPreferences = getMarketPopinUserPreferences()

  let countryCode = getCountryCode(locale) ?? DEFAULT_COUNTRY_CODE

  if (
    userHasAlreadySeenMarketPopin &&
    marketUserPreferences &&
    locale === marketUserPreferences?.locale
  ) {
    countryCode = marketUserPreferences?.countryCode
  }

  const lang = ((getLang(locale) ?? DEFAULT_LANG) as string)?.toUpperCase()
  const currency = useCurrency()

  return (
    <LazyMotion strict features={motionAsyncFeatures}>
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_PUBLIC_API_KEY}
        scriptProps={recaptachaProps}>
        <BodyScrollLockContextProvider>
          <NavBannerContextProvider
            enabled={pageProps?.global?.navigationBanner?.enabled}>
            <MarketProvider>
              <QueryClientProvider client={queryClient}>
                <GeolocationProvider>
                  <StoreProvider
                    country={countryCode as ShopifySFCountryCode}
                    language={lang as ShopifySFLanguageCode}
                    currency={currency as ShopifyCurrencyCode}
                    storeUrl={shop?.storeUrl}
                    storefrontAccessToken={shop?.storefrontAccessToken}
                    locale={locale}>
                    <AnalyticsProvider
                      filtersWhiteList={[]}
                      debug={process.env.NODE_ENV === 'development'}>
                      <CartProvider>
                        <CustomerProvider>
                          <TrackerProvider>
                            <I18n
                              locale={locale}
                              messages={pageProps?.global?.dictionary ?? {}}
                              allowMissing={true}>
                              <GlobalDataProvider
                                value={memoizedGlobalProviderData}>
                                <SettingsProvider
                                  settings={
                                    memoizedGlobalProviderData?.global?.settings
                                  }>
                                  <StyleProvider value={stylePresets}>
                                    <CssVariablesProvider value={cssVariables}>
                                      <ThemeProvider value={themePresets}>
                                        <ToastProvider>
                                          <LayoutSwitcherProvider
                                            defaultLayout={
                                              pageProps?.defaultLayout
                                            }>
                                            <AlertProvider>
                                              <PaginationProvider
                                                defaultPage={
                                                  pageProps?.defaultPage
                                                }>
                                                <FilterProvider
                                                  defaultFilters={
                                                    pageProps?.defaultFilters
                                                  }
                                                  defaultFacets={
                                                    pageProps?.defaultFacets
                                                  }>
                                                  <PanelProvider
                                                    data={
                                                      pageProps?.global?.panels
                                                    }>
                                                    <PopInProvider>
                                                      <BreadcrumbProvider
                                                        links={
                                                          pageProps?.breadcrumb
                                                        }>
                                                        <NavigationProvider
                                                          value={
                                                            navigationValues
                                                          }>
                                                          <LogoScrollProvider>
                                                            <BannerProvider>
                                                              <ClickOutsideProvider>
                                                                <ProductsViewedProvider>
                                                                  {children}
                                                                </ProductsViewedProvider>
                                                              </ClickOutsideProvider>
                                                            </BannerProvider>
                                                          </LogoScrollProvider>
                                                        </NavigationProvider>
                                                      </BreadcrumbProvider>
                                                    </PopInProvider>
                                                  </PanelProvider>
                                                </FilterProvider>
                                              </PaginationProvider>
                                            </AlertProvider>
                                          </LayoutSwitcherProvider>
                                        </ToastProvider>
                                      </ThemeProvider>
                                    </CssVariablesProvider>
                                  </StyleProvider>
                                </SettingsProvider>
                              </GlobalDataProvider>
                            </I18n>
                          </TrackerProvider>
                        </CustomerProvider>
                      </CartProvider>
                      <ShopifyAnalytics />
                    </AnalyticsProvider>
                  </StoreProvider>
                </GeolocationProvider>
              </QueryClientProvider>
            </MarketProvider>
          </NavBannerContextProvider>
        </BodyScrollLockContextProvider>
      </GoogleReCaptchaProvider>
    </LazyMotion>
  )
}

AppProvider.defaultProps = {}

export default AppProvider

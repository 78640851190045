import Algolia from '@unlikelystudio/horizon-algolia'

export const algoliaSearch = new Algolia({
  applicationId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  adminApiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
})

export const algoliaAdmin = new Algolia({
  applicationId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  adminApiKey: process.env.ALGOLIA_ADMIN_API_KEY,
})

/**
 * Fix css transition on first paint trying to animate
 */
export default function waitBeforeTransition() {
  if (typeof window !== 'undefined') {
    const body = document.querySelector('body')

    const onLoaded = () => {
      body.classList.remove('preload')
    }

    window.onload = onLoaded
  }
}

export const GlobalTextPreset = {
  /** Ctas */
  Cta10Grotesk: 'cta-10-grotesk',
  Cta11Grotesk: 'cta-11-grotesk',
  Cta10GroteskRegular: 'cta-10-grotesk-regular',
  Cta12Grotesk: 'cta-12-grotesk',
  Cta12GroteskRegular: 'cta-12-grotesk-regular',
  Cta16Grotesk: 'cta-16-grotesk',
  Cta18SangBleu: 'cta-18-sang-bleu',
  Cta16_12SangBleu: 'cta-16-12-sang-bleu',
  Cta10_12Grotesk: 'cta-10-12-grotesk',
  Cta11_12Grotesk: 'cta-11-12-grotesk',
  Cta10_12GroteskRegular: 'cta-10-12-grotesk-regular',
  Cta12_10GroteskRegular: 'cta-12-10-grotesk-regular',
  Cta30_SangBleu: 'cta-30-sang-bleu',

  /** Labels */
  Label8Grotesk: 'label-8-grotesk',
  Label10Grotesk: 'label-10-grotesk',
  Label10_12Grotesk: 'label-10-12-grotesk',
  Label11GroteskRegular: 'label-11-grotesk-regular',
  Label12Grotesk: 'label-12-grotesk',
  Label24Grotesk: 'label-24-grotesk',
  Label12SangBleu: 'label-12-sang-bleu',
  Label8_12GroteskRegular: 'label-8-12-grotesk-regular',
  Label12_16Grotesk: 'label-12-16-grotesk',

  /** Titles */
  Title15SangBleu: 'title-15-sang-bleu',
  Title18SangBleu: 'title-18-sang-bleu',
  Title20_16SangBleu: 'title-20-16-sang-bleu',
  Title20SangBleuLight: 'title-20-sang-bleu-light',
  Title20SangBleu: 'title-20-sang-bleu',
  Title24SangBleu: 'title-24-sang-bleu',
  Title24SangBleuLight: 'title-24-sang-bleu-light',
  Title30SangBleu: 'title-30-sang-bleu',
  Title30_40SangBleu: 'title-30-40-sang-bleu',
  Title40SangBleu: 'title-40-sang-bleu',
  Title44SangBleu: 'title-44-sang-bleu',
  Title18_24SangBleu: 'title-18-24-sang-bleu',
  Title20_24SangBleu: 'title-20-24-sang-bleu',
  Title20_24SangBleuRegular: 'title-20-24-sang-bleu-regular',
  Title20_18SangBleu: 'title-20-18-sang-bleu',
  // Fluid
  TitleFluid20_40SangBleu: 'title-fluid-20-40-sang-bleu',
  TitleFluid24_40SangBleu: 'title-fluid-24-40-sang-bleu',
  TitleFluid34_56SangBleu: 'title-fluid-34-56-sang-bleu',
  TitleFluid38_144SangBleu: 'title-fluid-38-144-sang-bleu',
  TitleFluid64_214SangBleu: 'title-fluid-64-214-sang-bleu',
  TitleFluid90_240SangBleu: 'title-fluid-90-240-sang-bleu',

  /** Texts */
  Text12GroteskMedium: 'text-12-grotesk-medium',
  Text16_12GroteskMedium: 'text-16-12-grotesk-medium',
  Text12SangBleu: 'text-12-sang-bleu',
  Text12GroteskRegular: 'text-12-grotesk-regular',
  Text14GroteskRegular: 'text-14-grotesk-regular',
  Text12_20SangBleu: 'text-12-20-sang-bleu',
  Text20_24SangBleu: 'text-20-24-sang-bleu',

  /** WYSIWYG */
  RichTextEditorial: 'rich-text-editorial',

  /** Inputs */
  Input12GroteskRegular: 'input-12-grotesk-regular',
  Input30_56SangBleu: 'input-30-56-sang-bleu',
} as const

export type GlobalTextPreset =
  typeof GlobalTextPreset[keyof typeof GlobalTextPreset]

import { useRouter } from 'next/router'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import type { FacetsResult } from '~/lib/algolia/fetch-facets'
import {
  ATTRIBUTES_FOR_FACETING,
  FACETS,
} from '~/lib/algolia/sync/set-settings'

import { Facets } from '@unlikelystudio/horizon-algolia'

import { getResetFieldValue } from '~/components/UI/FiltersForm'
import { FilterGroup } from '~/components/UI/FiltersForm/Filter'

import {
  LAYOUT,
  useLayoutSwitcherProvider,
} from '~/providers/LayoutSwitcherProvider'

import { getQueryParams } from '~/utils/get-query-params'

import type { FacetFilters } from '~/data/serialize-default-filters'

export const FilterContext = createContext<FilterType>({})

export function useFilter() {
  return useContext(FilterContext)
}

type ProviderFilterProps = {
  defaultFilters?: Facets
  defaultFacets?: FacetsResult[]
  children: JSX.Element | JSX.Element[]
}

export const SortByValues = {
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  SUSTAINABILITY: 'sustainability',
  DEFAULT: 'default',
}

export const FacetsFilters = {
  STONE: 'stone',
  METAL: 'metal',
  SELECTIONS: 'selections',
  DESIGNER: 'designer',
  CATEGORIES: 'categories',
  CATEGORIES_V2: 'categories_v2',
  SUB_CATEGORIES: 'sub_categories',
  SUSTAINABILITY: 'sustainability_tags',
  IN_SHOP: 'in_shop_v2',
  IS_ARCHIVES: 'is_archives',
  HIDDEN_SELECTIONS: 'hidden_selections',
  SCORE: 'score',
} as const

export type TFacetsFiltersKey = typeof FacetsFilters[keyof typeof FacetsFilters]

export const NumericFilters = {
  PRICE: 'numeric_price',
}

export const OrderingFilters = {
  ORDER_BY: 'order_by',
}

export const HITS_PER_PAGE = {
  [LAYOUT.BIG]: 27,
  [LAYOUT.MEDIUM]: 36,
  [LAYOUT.SMALL]: 72,
  FILTERED: 10,
}

export const GRID_HITS = {
  [LAYOUT.MEDIUM]: HITS_PER_PAGE[LAYOUT.MEDIUM],
  [LAYOUT.BIG]: HITS_PER_PAGE[LAYOUT.BIG],
  FILTERED: 6,
}

export const FACETS_ORDER = [
  NumericFilters.PRICE,
  FacetsFilters.CATEGORIES,
  FacetsFilters.CATEGORIES_V2,
  FacetsFilters.SUB_CATEGORIES,
  FacetsFilters.DESIGNER,
  FacetsFilters.STONE,
  FacetsFilters.METAL,
  FacetsFilters.SUSTAINABILITY,
  FacetsFilters.SELECTIONS,
  FacetsFilters.IN_SHOP,
]

function formatFacetsFilters(filters: FacetFilters) {
  return Object.keys(filters)
    .filter((filter: keyof FacetFilters) => {
      const filterValuesAreDefined = filters?.[filter]
      const hasAtLeastOneFilterValue = filters?.[filter]?.length > 0
      const facetIsAllowedInFilterPanel =
        Object.values(FacetsFilters).includes(filter) ||
        // This facet won't be displayed in the panel but it is a default filter to exclude stones produts
        filter === FACETS.IS_ONLY_METAL

      const isNotCatOrSubCat =
        filter !== FACETS.CATEGORIES_V2 && filter !== FACETS.SUB_CATEGORIES

      return (
        filterValuesAreDefined &&
        hasAtLeastOneFilterValue &&
        facetIsAllowedInFilterPanel &&
        isNotCatOrSubCat
      )
    })
    .map((filter) => {
      const processedValues = Array.isArray(filters[filter])
        ? (filters[filter] as string[])
        : ([filters[filter]] as string[])

      return processedValues.map((value) => `${filter}:${value}`)
    })
}

export function getFacetsFiltersFromFilters(filters: FacetFilters) {
  // If filters value is not defined return null
  if (filters === undefined || filters === null) return null

  // Process and format filters into facets values
  // --> `facet:value` // `category:Rings`
  const facetFilters = formatFacetsFilters(filters)

  // To allow cross search on root category page
  // We need to cumulate categories & sub categories in the same array
  // This allows us to set them as OR operator (eg: Category A or SubCategory C)
  const categoriesAndSubCategoriesFacet = Object.keys(filters)
    ?.filter((key) => {
      // Filter to execute this action only on categories and sub categories
      return key === FACETS.CATEGORIES_V2 || key === FACETS.SUB_CATEGORIES
    })
    ?.reduce((acc, key) => {
      // Isolate current entry ([key]: [...value] || [key]: 'value')
      const entry = filters?.[key]
      // Check if the current entry is an array & convert to an array if it is a string
      const processedArray = Array.isArray(entry) ? entry : [entry]
      // Process values to fit Algolia requirements (attribute:value)
      const values =
        (processedArray ?? [])
          // Filter through values to remove falsy ones
          ?.filter(Boolean)
          ?.map((val) => `${key}:${val}`) ?? []
      // Return all processed entries
      return [...acc, ...values]
    }, [])
    /**
     * Since we are using an OR operator on all categories and sub categories
     * If you select a category & one of its subcategories then your results won't make sense
     * eg: if you select Rings OR Rings Alliances you'll end up with every rings in your hits
     * To fix this we need to always remove the parent of a sub category
     * eg: you've selected Rings before, then you've added Rings Alliances, then we will remove Rings from the next search
     */
    ?.filter((entry, _, array) => {
      // ENTRY = 'attribute:value'
      // ARRAY = ['attribute:value', 'attribute:value', ...]
      // Find through array
      const hasASubCategoryValueSelected = array?.find((item) => {
        // Trim facet value to isolate category name
        // eg: 'category:Rings' -> Rings
        const categoryName = entry?.replace(`${FACETS.CATEGORIES_V2}:`, '')
        // If the item includes a set category name, then return it
        return item?.includes(`${FACETS.SUB_CATEGORIES}:${categoryName} > `)
      })
      // Exclude category if it has a sub category selected
      return !hasASubCategoryValueSelected
    })

  return [...facetFilters, categoriesAndSubCategoriesFacet]
}

export type FilterResultType = Record<string, string | string[] | false>

// Need to be updated with the same keys as OrderResultType
const ALLOWED_SORT_BY = ['order_by']

export type FilterType = {
  userFiltering?: boolean
  filters?: FilterResultType
  hitsPerPage?: number
  setHitsPerPage?: React.Dispatch<React.SetStateAction<number>>
  reset?(): void
  setFilters?: React.Dispatch<React.SetStateAction<FilterResultType>>
  hasFilters?: boolean
  defaultFilters?: Facets
  defaultFacets?: FacetsResult[]
  facets?: FacetsResult[]
}

export function getMinMaxValuesFromFacet(
  filter: string,
  facets: FilterGroup[],
): number[] {
  const values = facets?.find((facet) => facet.type === filter)?.values ?? null

  const numericValues =
    values?.map(({ value }) => parseFloat(value))?.sort((a, b) => a - b) ?? null
  const min = numericValues?.[0] ?? null
  const max = numericValues?.[numericValues?.length - 1] ?? null

  return [min, max]
}

export function getExcludedIdsFilter(ids: number[]) {
  if (ids?.length < 1) return null
  return ids?.reduce((acc, curr, index) => {
    return (acc += `storyID != ${curr} ${
      index < ids?.length - 1 ? `AND ` : ``
    }`)
  }, '')
}

export function hasFilterSelected(filter: FilterResultType[string]) {
  return (
    filter !== undefined &&
    filter !== false &&
    filter !== null &&
    filter.length > 0
  )
}

function hasFiltersSelected(filters: FilterResultType) {
  // Get all values from filters
  const filterCount = Object.values(filters)
    // Filter non string array values
    ?.filter((item) => hasFilterSelected(item))
    // Flatten the array of string
    ?.flat(3)?.length
  // If there is at least 1 string there is a filter enable
  return filterCount > 0
}

export function getFilteredAlgoliaParams(
  filters: FilterResultType,
): FilterResultType {
  if (!filters) return {}
  return (
    Object.keys(filters)
      // Test if the query filter is a valid key for algolia
      ?.filter(
        (filter) =>
          (filter !== null &&
            (ATTRIBUTES_FOR_FACETING.includes(filter) ||
              ALLOWED_SORT_BY?.includes(filter))) ||
          filter === 'layout',
      )
      ?.reduce((cur, key) => {
        let processedValue
        const val = filters[key]
        if (typeof val === 'string')
          // Transform all + from nextJS query
          processedValue = val?.toString()?.replaceAll('+', ' ')
        else if (Array.isArray(val))
          // Transform all + from nextJS query
          processedValue = val.map((val) =>
            val?.toString()?.replaceAll('+', ' '),
          )

        return Object.assign(cur, { [key]: processedValue })
      }, {})
  )
}

export function removeFiltersQueryParams(facets: FacetsResult[]) {
  const params = getQueryParams()
  const facetsType = facets?.map((facet) => facet?.type) ?? []
  const deletableParams = [...facetsType, OrderingFilters.ORDER_BY]
  deletableParams?.length > 0 &&
    deletableParams?.forEach((facet) => {
      params?.[facet] && delete params?.[facet]
    })
  return params
}

export default function FilterProvider({
  defaultFilters,
  defaultFacets,
  children,
}: ProviderFilterProps) {
  const [filters, setFilters] = useState<FilterResultType>({})
  const { step } = useLayoutSwitcherProvider()
  const [hitsPerPage, setHitsPerPage] = useState(HITS_PER_PAGE?.[step])
  const router = useRouter()
  const params = useMemo(() => getQueryParams(), [router])
  const filteredParamsFromUrl = getFilteredAlgoliaParams(params ?? {})

  const userFilters = Object.keys(filteredParamsFromUrl).filter((key) => {
    return (
      Object.values({
        ...FacetsFilters,
        ...NumericFilters,
        sort: 'order_by',
      }).findIndex((el) => el === key) > -1
    )
  })

  const userFiltering = userFilters?.length > 0

  useEffect(() => {
    if (HITS_PER_PAGE?.[step] !== hitsPerPage) {
      setHitsPerPage(HITS_PER_PAGE?.[step])
    }
  }, [step])

  const reset = () => {
    const obj = userFilters.reduce(
      (acc, field) => ({
        ...acc,
        [field]: getResetFieldValue(field, defaultFacets),
      }),
      {},
    )
    setFilters(obj)
  }

  // Has filter enabled -> if filters were selected & panel was closed
  const hasFilters = hasFiltersSelected(filters)

  // Uncomment if we re-enable front-end fetching of facets
  // const { data: facets } = useFetchAlgoliaFacets(
  //   defaultFilters ?? {},
  //   defaultFacets ? false : true,
  // )
  const value: FilterType = useMemo(
    () => ({
      userFiltering,
      filters,
      setFilters,
      hasFilters,
      facets: defaultFacets,
      defaultFilters,
      reset,
      hitsPerPage,
      setHitsPerPage,
    }),
    [defaultFacets, filters, hasFilters, defaultFilters, reset, hitsPerPage],
  )

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { getGeolocation } from '~/lib/handle-api'

type useGeolocationOptions = {
  throwError?: boolean
  keys?: any[]
}

const defaultUseGeolocationOption = {
  throwError: false,
  keys: [],
}

export default function useGeolocationMutation(
  options?: UseQueryOptions<any, any, any>,
  useGeolocationOptions?: useGeolocationOptions,
) {
  const useGeolocationOptionsWithDefault = {
    ...defaultUseGeolocationOption,
    ...useGeolocationOptions,
  }

  const keys = useGeolocationOptionsWithDefault?.keys ?? []
  const queryKey = ['getGeolocation', ...keys]

  return useQuery(
    queryKey,
    async () => {
      try {
        const geolocation = await getGeolocation()

        return {
          ...(geolocation?.data ?? {}),
        }
      } catch (error) {
        if (useGeolocationOptionsWithDefault?.throwError) {
          throw new Error(error)
        }
      }
    },
    options,
  )
}

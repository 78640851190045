export const GlobalThemeColors = {
  White: 'white',
  Black: 'black',
  Concrete: 'concrete',
  Scorpion: 'scorpion',
  SilverChalice: 'silver-chalice',
  Gray: 'gray',
  Transparent: 'transparent',
  William: 'william',
  Eunry: 'eunry',
  IrishCoffee: 'irish-coffee',
  Amber: 'amber',
} as const

export type GlobalThemeColors =
  typeof GlobalThemeColors[keyof typeof GlobalThemeColors]

export type ThemeTextColors =
  | typeof GlobalThemeColors.Black
  | typeof GlobalThemeColors.White
  | typeof GlobalThemeColors.Concrete
  | typeof GlobalThemeColors.Scorpion
  | typeof GlobalThemeColors.SilverChalice
  | typeof GlobalThemeColors.William
  | typeof GlobalThemeColors.Gray

export type ThemePageColors =
  | typeof GlobalThemeColors.Black
  | typeof GlobalThemeColors.White
  | typeof GlobalThemeColors.Concrete
  | typeof GlobalThemeColors.Scorpion

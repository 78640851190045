import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const CssVariablesContext = createContext<ValueType>({})

type CssVariablesProviderProps = {
  value: object
  children: JSX.Element | JSX.Element[]
}

export type VariablesType = Record<string, any>

export type ValueType = {
  variables?: VariablesType
  update?: (id: string, value: any) => void
}

export default function CssVariablesProvider({
  value,
  children,
}: CssVariablesProviderProps) {
  const [variables, setVariables] = useState({})

  const update = useCallback(
    (id, val) => {
      setVariables((variables) => ({ ...variables, [id]: val }))
    },
    [setVariables],
  )

  const processedValue: ValueType = useMemo(
    () => ({
      variables: { ...value, ...variables },
      update,
    }),
    [variables, value, update],
  )

  return (
    <CssVariablesContext.Provider value={processedValue}>
      {children}
    </CssVariablesContext.Provider>
  )
}

export function useCssVariables() {
  return useContext(CssVariablesContext)
}

import { GlobalThemeColors } from '~/@types/colors'

export function ctaNegativeTheme(
  theme: GlobalThemeColors | string,
): GlobalThemeColors {
  switch (theme) {
    case GlobalThemeColors.Black:
      return GlobalThemeColors.White

    case GlobalThemeColors.White:
      return GlobalThemeColors.Black

    case GlobalThemeColors.Transparent:
      return GlobalThemeColors.Black
  }
}

export function inlineCtaNegativeTheme(
  theme: GlobalThemeColors | string,
): GlobalThemeColors {
  return theme === GlobalThemeColors.Black
    ? GlobalThemeColors.White
    : GlobalThemeColors.Black
}

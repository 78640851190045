import { PathRegExp } from '@marvinh/path-to-regexp'
import { encode } from 'qss'

import pathsMapping from 'config/paths-mapping.json'

import { createUrl } from '~/utils/ create-url'
import { getLang, isDefaultLocale } from '~/utils/locales'
import { objectKeys } from '~/utils/objects-keys'

import {
  STORYBLOK_SUBTYPES,
  STORYBLOK_TYPES,
  TStoryblokTypesKey,
} from './storyblok-types'

function getPath(
  locale: string,
  type: string,
  slug: string,
  defaultSource: string,
  query: QueryParam,
  disableRewrites?: boolean,
) {
  const sourcePathMapping = pathsMapping?.[type]?.[locale]?.source ?? null
  const pathRegExp = new PathRegExp(
    sourcePathMapping && !disableRewrites ? sourcePathMapping : defaultSource,
  )

  const params = query ? `?${encode(query)}` : ''

  return `${locale ? `/${locale}/` : '/'}${createUrl(pathRegExp, {
    slug: slug ?? '',
    page: '',
  })}${params}`
}

// Delete architecture repo imbrication
// Example replace universal_page/ma-page by ma-pagefunction deleteStoryblokSlugFolder(slug, type) {
function deleteStoryblokSlugFolder(slug, type) {
  return (slug = slug?.replace(`${type}/`, ''))
}

function findComponentFromFullSlug(fullSlug: string | null) {
  if (!fullSlug) {
    return undefined
  }

  // Get all keys from the SB_PAGE_STORIES object
  const keys = objectKeys(STORYBLOK_TYPES)

  // Initialize variables to keep track of the most relevant key, maximum match length, and maximum depth
  let mostRelevantKey: TStoryblokTypesKey | undefined
  let maxMatchLength = 0
  let maxDepth = 0

  // Iterate over each key in the SB_PAGE_STORIES object
  for (const key of keys) {
    // Get the rootSlug value for the current key
    const value = STORYBLOK_TYPES[key]

    // Check if the cached URL starts with the current rootSlug value
    if (fullSlug.startsWith(value)) {
      // Calculate the length of the matching part
      const matchLength = value.length
      // Calculate the depth of the current rootSlug by counting the number of slashes
      const depth = (value.match(/\//g) || []).length

      // Update the most relevant key if the current rootSlug has a greater depth
      // or if it has the same depth but a longer match length
      if (
        depth > maxDepth ||
        (depth === maxDepth && matchLength > maxMatchLength)
      ) {
        mostRelevantKey = STORYBLOK_TYPES[key]
        maxMatchLength = matchLength
        maxDepth = depth
      }
    }
  }

  // Return the most relevant key or undefined if no match is found
  return mostRelevantKey
}

type QueryParam = Record<string, string | string[]>
export default function linkResolver(
  doc: any,
  locale?: string,
  query?: QueryParam,
  forceLocale?: boolean,
  disableRewrites?: boolean,
): string {
  const lang = getLang(locale)

  let componentName
  let slug

  if (doc?.content?.component) {
    componentName = doc?.content?.component
    slug =
      deleteStoryblokSlugFolder(
        doc?.default_full_slug,
        // Type of the document eg product_page (name of the storyblok folder)
        doc?.content?.component,
      ) ?? doc?.slug

    // Find if there is a translation available into your current locale / lang
    const translatedSlug =
      doc?.translated_slugs?.find((item) => item.lang === lang) ?? null

    // If there is a translation apply it
    if (translatedSlug)
      slug =
        deleteStoryblokSlugFolder(
          translatedSlug?.path,
          doc?.content?.component,
        ) ?? doc?.slug
  } else {
    const defaultFullSlug = doc?.full_slug
    const lang = getLang(locale)

    const fullSlug = defaultFullSlug?.startsWith(lang)
      ? defaultFullSlug?.replace(`${lang}/`, '')
      : defaultFullSlug

    componentName = findComponentFromFullSlug(fullSlug)

    slug = deleteStoryblokSlugFolder(fullSlug, componentName) ?? doc?.slug
  }

  const defaultLocale = isDefaultLocale(locale)

  switch (componentName) {
    case STORYBLOK_TYPES.HOMEPAGE:
    case STORYBLOK_TYPES.COMING_SOON:
      return !forceLocale && defaultLocale ? '/' : `/${locale}`
    case STORYBLOK_TYPES.ACCOUNT:
      return getPath(locale, componentName, null, 'account', query)
    case STORYBLOK_TYPES.REGISTER:
      return getPath(locale, componentName, null, 'register', query)
    case STORYBLOK_TYPES.LOGIN:
      return getPath(locale, componentName, null, 'login', query)
    case STORYBLOK_TYPES.SEARCH:
      return getPath(locale, componentName, null, 'search', query)
    case STORYBLOK_TYPES.CART_PAGE:
      return getPath(locale, componentName, null, 'cart', query)
    case STORYBLOK_TYPES.FORGOT_PASSWORD:
      return getPath(
        locale,
        componentName,
        null,
        'forgot-password',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.RESET_PASSWORD:
      return getPath(
        locale,
        componentName,
        null,
        'reset-password',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.ENABLE_ACCOUNT:
      return getPath(
        locale,
        componentName,
        null,
        'enable-account',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.PRACTICAL_INFORMATIONS_PAGE:
      if (slug === undefined) return ''

      return getPath(
        locale,
        componentName,
        slug,
        'practical-informations/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.PRODUCT:
      return getPath(
        locale,
        componentName,
        slug,
        'products/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.ARCHIVES:
      return getPath(
        locale,
        componentName,
        doc?.slug,
        'archives',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.NEW_IN:
      return getPath(
        locale,
        componentName,
        doc?.slug,
        'new-in',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.CATEGORY_PAGE:
      return getPath(
        locale,
        componentName,
        slug,
        'category/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.ROOT_CATEGORY_PAGE:
      return getPath(
        locale,
        componentName,
        slug,
        'category',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.BLOG_LIST_PAGE:
      return getPath(locale, componentName, null, 'journal', query)
    case STORYBLOK_TYPES.PRESS_LIST_PAGE:
      return getPath(locale, componentName, null, 'press', query)
    case STORYBLOK_TYPES.BLOG_CATEGORY:
      return getPath(
        locale,
        componentName,
        slug,
        'journal/category/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.PRESS_CATEGORY:
      return getPath(
        locale,
        componentName,
        slug,
        'press/category/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.DESIGNER_LIST_PAGE:
      return getPath(locale, componentName, null, 'designers', query)
    case STORYBLOK_TYPES.BLOG_ARTICLE_PAGE:
      return getPath(
        locale,
        componentName,
        slug,
        'journal/articles/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.PRESS_ARTICLE_PAGE:
      return getPath(
        locale,
        componentName,
        slug,
        'press/articles/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.DESIGNER:
      return getPath(
        locale,
        componentName,
        slug,
        'designers/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.METAL_STONE_LIST_PAGE:
      return getPath(
        locale,
        componentName,
        null,
        'metals-stones',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.METAL_STONE_PAGE:
      return getPath(
        locale,
        componentName,
        slug,
        'metals-stones/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.SHOP:
      return getPath(
        locale,
        componentName,
        slug,
        'shop/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.ATTRIBUTE_DESIGNER:
      return getPath(
        locale,
        componentName,
        slug
          ?.replace(`${STORYBLOK_SUBTYPES.PRODUCT_ATTRIBUTES}/`, '')
          ?.replace(`${STORYBLOK_SUBTYPES.PRODUCT_DESIGNERS}/`, ''),
        'designers/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_SUBTYPES.PRODUCT_CATEGORY:
      return getPath(
        locale,
        componentName,
        slug
          ?.replace(`${STORYBLOK_SUBTYPES.PRODUCT_ATTRIBUTES}/`, '')
          ?.replace(`${STORYBLOK_SUBTYPES.PRODUCT_CATEGORIES}/`, ''),
        'category/:slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.UNIVERSAL:
      return getPath(
        locale,
        componentName,
        slug,
        ':slug',
        query,
        disableRewrites,
      )
    case STORYBLOK_TYPES.WISHLIST:
      return getPath(
        locale,
        componentName,
        slug,
        'wishlist',
        query,
        disableRewrites,
      )
    default:
      return getPath(locale, componentName, slug, '404', query, disableRewrites)
  }
}

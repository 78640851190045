import { Crawler } from 'es6-crawler-detect'
import { useEffect, useState } from 'react'

type ConditionalCrawlerDetectorProps = {
  children?: any
}

export default function ConditionalCrawlerDetector(
  props: ConditionalCrawlerDetectorProps,
) {
  const { children } = props
  const [isAvailable, setIsAvailable] = useState(false)
  useEffect(() => {
    const CrawlerDetector = new Crawler()
    if (!CrawlerDetector.isCrawler(navigator.userAgent)) setIsAvailable(true)
  }, [])

  return isAvailable ? children : null
}

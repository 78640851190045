export const ALERT_CODES = {
  UNIDENTIFIED_CUSTOMER: 'UNIDENTIFIED_CUSTOMER',
  BAD_DOMAIN: 'BAD_DOMAIN',
  BLANK: 'BLANK',
  CONTAINS_HTML_TAGS: 'CONTAINS_HTML_TAGS',
  CONTAINS_URL: 'CONTAINS_URL',
  CUSTOMER_DISABLED: 'CUSTOMER_DISABLED',
  INVALID: 'INVALID',
  INVALID_MULTIPASS_REQUEST: 'INVALID_MULTIPASS_REQUEST',
  NOT_FOUND: 'NOT_FOUND',
  PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE:
    'PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE',
  TAKEN: 'TAKEN',
  TOKEN_INVALID: 'TOKEN_INVALID',
  TOO_LONG: 'TOO_LONG',
  TOO_SHORT: 'TOO_SHORT',
  ALREADY_ENABLED: 'ALREADY_ENABLED',
  ENTITY_SAVED: 'ENTITY_SAVED',
  EMAIL_SENT: 'EMAIL_SENT',
  SERVER_ERROR: 'SERVER_ERROR',
  PASSWORD_RESET: 'PASSWORD_RESET',
  NEWSLETTER_SUBSCRIBE: 'NEWSLETTER_SUBSCRIBE',
  GOOGLE_RECAPTCHA_ERROR: 'GOOGLE_RECAPTCHA_ERROR',
  ALERT_STOCK_SUCCESS: 'ALERT_STOCK_SUCCESS',
  CART_NOTE_ERROR: 'CHECKOUT_NOTE_ERROR',
}

export const ALERT_PRESETS = Object.values(ALERT_CODES)

export type AlertPreset = typeof ALERT_PRESETS[number]

import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMeasure } from 'react-use'
import { GlobalGridPreset } from '~/@types/grid-preset'

import { clamp, map, useSpring } from '@unlikelystudio/react-hooks'

import { useNavBannerContext } from '~/components/Navigation/NavigationBanner'
import Logotype from '~/components/UI/LogoTypes/logo.svg'

import { useBodyScrollLockContext } from '~/providers/BodyScrollLockProvider'
import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useLogoScrollProvider } from '~/providers/LogoScrollProvider'
import { useStyle } from '~/providers/StyleProvider'

import { getAbsoluteCoordinates } from '~/hooks/useRelativePosition'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ScrollIntroProps = {
  className?: string
}

function ScrollIntro({ className }: ScrollIntroProps) {
  const parentRef = useRef<HTMLDivElement>()

  const scaleMarkerRef = useRef<HTMLDivElement>()
  const { metas } = useGlobalData()

  const { inView: markerInViewPosition } = useInView({
    threshold: 1,
    initialInView: true,
  })

  const [targetRef, targetBounds] = useMeasure()
  const [itemRef, itemBounds] = useMeasure()

  // The scale to apply to the initial logo to fit the navigation's logo size
  const scaleDelta = targetBounds.width / itemBounds.width

  const { setTransitionEnded, hideScrollLogo } = useLogoScrollProvider()

  const endPoint = useRef(null)

  const [isVisible] = useNavBannerContext()
  const [scaleValue, setScaleValue] = useState(null)

  useEffect(() => {
    setTransitionEnded(!markerInViewPosition)
  }, [markerInViewPosition])

  const raf = useRef(null)
  const [isLocked] = useBodyScrollLockContext()
  const [setProgressSpring] = useSpring({
    config: {
      interpolation: 'basic',
      friction: scaleValue ? 1.18 : 1,
      precisionStop: 0.000001,
    },
    progress: null,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      setScaleValue(progress)
    },
  })

  // Update logo scale based on scroll value and scale marker position
  const updateScale = useCallback(() => {
    const valueClamped = clamp(window.scrollY, 0, endPoint?.current?.y ?? 0)

    const valueMapped = map(
      valueClamped,
      0,
      endPoint?.current?.y ?? 0,
      1,
      scaleDelta,
    )
    setProgressSpring({ progress: valueMapped })
    raf.current = window.requestAnimationFrame(updateScale)
  }, [scaleDelta])

  useEffect(() => {
    if (isNaN(scaleDelta)) return
    cancelAnimationFrame(raf.current)
    endPoint.current = getAbsoluteCoordinates(scaleMarkerRef.current)
    endPoint.current.y -= itemBounds?.height
    if (!isLocked) {
      raf.current = window.requestAnimationFrame(updateScale)
    }

    return () => {
      cancelAnimationFrame(raf.current)
    }
  }, [scaleDelta, isLocked])

  // Delete raf
  useEffect(() => {
    return () => {
      cancelAnimationFrame(raf.current)
    }
  }, [])

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const fixedValue = parseFloat(scaleValue ?? 1).toFixed(2)

  return (
    <>
      <div
        className={cx(css.ScrollIntro, className, {
          hide: hideScrollLogo,
          withBanner: isVisible,
        })}
        ref={parentRef}>
        <m.div className={cx(css.logo)} style={{ position: 'sticky' }}>
          <h1 className={css.movingLogo}>
            {metas?.title && <span className={css.h1}>{metas?.title}</span>}
            <Logotype
              className={cx(css.logoItem, className, {
                displayed: !!scaleValue,
              })}
              style={{
                transform: `scale3d(${fixedValue}, ${fixedValue}, ${fixedValue})`,
              }}
            />
          </h1>
        </m.div>
        <div className={cx(css.markerWrapper, gridStyle)}>
          <div className={css.marker}>
            <div className={css.innerMarker} ref={targetRef}>
              <Logotype />
            </div>
          </div>
        </div>
      </div>
      <div className={cx(css.logo, css.hidden, { withBanner: isVisible })}>
        <div className={css.scaleMarker}>
          <div className={css.innerMarker} ref={scaleMarkerRef}></div>
        </div>
        <div className={css.logoContainer} ref={itemRef}>
          <Logotype className={cx(css.logoItem, className)} />
        </div>
      </div>
    </>
  )
}

ScrollIntro.defaultProps = {}

export default ScrollIntro

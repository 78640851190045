import { algoliaAdmin } from '~/lib/algolia/clients'
import {
  ALGOLIA_STORYBLOK_ID_PROPERTY_KEY,
  REPLICAS_SUFFIX,
} from '~/lib/algolia/constants'

export const FACETS = {
  [ALGOLIA_STORYBLOK_ID_PROPERTY_KEY]: ALGOLIA_STORYBLOK_ID_PROPERTY_KEY,
  CATEGORIES: 'categories',
  CATEGORIES_V2: 'categories_v2',
  SUB_CATEGORIES: 'sub_categories',
  METAL: 'metal',
  IS_ONLY_METAL: 'is_only_metal',
  STONE: 'stone',
  SELECTIONS: 'selections',
  SUSTAINABILITY_TAGS: 'sustainability_tags',
  DESIGNER: 'designer',
  IS_ARCHIVES: 'is_archives',
  IN_SHOP: 'in_shop_v2',
  HIDDEN_SELECTIONS: 'hidden_selections',
  SCORE: 'score',
  PRICE: 'numeric_price',
  IS_NEW: 'is_new',
}

export const FACETS_TO_RETRIEVE = [
  FACETS.CATEGORIES_V2,
  FACETS.SUB_CATEGORIES,
  FACETS.METAL,
  FACETS.STONE,
  FACETS.SELECTIONS,
  FACETS.SUSTAINABILITY_TAGS,
  FACETS.IS_ARCHIVES,
  FACETS.DESIGNER,
  FACETS.IN_SHOP,
  FACETS.HIDDEN_SELECTIONS,
  FACETS.SCORE,
  FACETS.PRICE,
]

const algoliaDefaultRanking = [
  'typo',
  'geo',
  'words',
  'filters',
  'proximity',
  'attribute',
  'exact',
  'custom',
]

const searchableAttributes = [
  'name',
  'unordered(designer)',
  'unordered(categories)',
  'unordered(stone)',
  'unordered(metal)',
  'unordered(description)',
  'unordered(storyID)',
]

const replicas = [
  {
    name: `_${REPLICAS_SUFFIX.PRICE_ASC}`,
    ranking: 'asc(numeric_price)',
  },
  {
    name: `_${REPLICAS_SUFFIX.PRICE_DESC}`,
    ranking: 'desc(numeric_price)',
  },
  {
    name: `_${REPLICAS_SUFFIX.RECOMMENDATIONS}`,
    ranking: 'desc(score)',
  },
  {
    name: `_${REPLICAS_SUFFIX.SUSTAINABILITY}`,
    ranking: 'desc(sustainability_tags)',
  },
  {
    name: `_${REPLICAS_SUFFIX.PUBLISHED_AT}`,
    ranking: 'desc(published_at)',
  },
]

export const ATTRIBUTES_FOR_FACETING = Object.values(FACETS)

/**
 *
 * @param indexName string
 * @returns null
 *
 * Generate Algolia replicas to be used for custom ranking
 * e.g.: order products by price from highest to lowest
 */
export default async function generateAlgoliaReplicas(
  indexName: string,
  locale: string,
) {
  await algoliaAdmin.setSettings({
    indice_name: indexName,
    lang: locale,
    attributesForFaceting: ATTRIBUTES_FOR_FACETING,
    searchableAttributes,
    sortFacetValuesBy: 'alpha',
    ranking: ['desc(is_new_ts)', 'desc(score)', ...algoliaDefaultRanking],
    replicas: replicas?.map((replica) => `${indexName}${replica.name}`),
  })

  await Promise.all(
    replicas?.map(
      async (replica) =>
        await algoliaAdmin.setSettings({
          indice_name: `${indexName}${replica.name}`,
          lang: locale,
          ranking: [replica.ranking, ...algoliaDefaultRanking],
          attributesForFaceting: ATTRIBUTES_FOR_FACETING,
          sortFacetValuesBy: 'alpha',
        }),
    ),
  )

  return
}

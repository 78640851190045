import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { submitContactForm } from '~/lib/handle-api'

import useOnMutationError from '~/hooks/useOnMutationError'

import { stringIsNotEmpty } from '~/utils/check-empty-string'

type CustomerContactFormInput = {
  firstName?: string
  lastName?: string
  email: string
  phone?: string
  message: string
}

export default function useContactFormMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  return useMutation<any, any, any>(
    async ({
      firstName,
      lastName,
      email,
      phone,
      message,
    }: CustomerContactFormInput) => {
      return await submitContactForm({
        payload: {
          email,
          message,
          ...(stringIsNotEmpty(firstName) && { firstName }),
          ...(stringIsNotEmpty(lastName) && { lastName }),
          ...(stringIsNotEmpty(phone) && { phone }),
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, variables, context) => {
        mutationOptions?.onSuccess(data, variables, context)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

const Context = createContext({})

export function useGlobalData(): any {
  const ctx = useContext(Context)

  return useMemo(() => ctx, [ctx])
}

export type GlobalDataProviderProps = {
  children: JSX.Element | JSX.Element[]
  value: any
}

export default function GlobalDataProvider({
  value,
  children,
}: GlobalDataProviderProps) {
  const [data, setData] = useState({})

  // this is used to overload a page global data
  // eg: In diagnostic page, when changing view and setting global themes in each question without changing page
  // Using this will require to use ** resetOverloadedGlobalData ** when leaving your view so that you avoid
  // overloading the next pages
  const setOverloadedGlobalData = useCallback(
    (newData) => {
      setData({
        ...data,
        ...newData,
      })
    },
    [data, setData],
  )

  const resetOverloadedGlobalData = useCallback(() => {
    setOverloadedGlobalData({})
  }, [setOverloadedGlobalData])

  const processedValue = useMemo(
    () => ({
      ...value,
      ...data,
      setOverloadedGlobalData,
      resetOverloadedGlobalData,
    }),
    [data, setOverloadedGlobalData, JSON.stringify(value)],
  )

  return <Context.Provider value={processedValue}>{children}</Context.Provider>
}

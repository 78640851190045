import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'

type LogoScrollProviderContext = {
  transitionEnded?: boolean
  logoState?: boolean
  hideScrollLogo?: boolean
  setTransitionEnded?: React.Dispatch<React.SetStateAction<boolean>>
  setLogoState?: React.Dispatch<React.SetStateAction<boolean>>
  setHideScrollLogo?: React.Dispatch<React.SetStateAction<boolean>>
}

const Context = createContext<LogoScrollProviderContext>({})

export function useLogoScrollProvider() {
  return useContext(Context)
}

export type LogoScrollProviderProps = {
  children?: ReactNode | ReactNode[]
}

function LogoScrollProvider({ children }: LogoScrollProviderProps) {
  const [transitionEnded, setTransitionEnded] = useState(false)
  const [logoState, setLogoState] = useState(false)
  const [hideScrollLogo, setHideScrollLogo] = useState(false)

  const providerValue = useMemo(
    () => ({
      transitionEnded,
      logoState,
      hideScrollLogo,
      setHideScrollLogo,
      setLogoState,
      setTransitionEnded,
    }),
    [
      transitionEnded,
      setLogoState,
      hideScrollLogo,
      setTransitionEnded,
      logoState,
    ],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

LogoScrollProvider.defaultProps = {}

export default LogoScrollProvider

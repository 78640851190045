import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useIsMountedState } from '@unlikelystudio/react-hooks'

import useLocale from '~/hooks/useLocale'
import useMarketCookie, { MarketCookie } from '~/hooks/useMarketCookie'

type MarketContextProps = {
  displayMarketPopin?: boolean
  userPreferences?: MarketCookie
  setDisplayMarketPopin?: (boolean) => void
  isLocaleSameAsUserPreferences?: boolean
  showPopin?: boolean
}

export const Context = createContext<MarketContextProps>({})

export function useMarketContext() {
  return useContext(Context)
}

export type MarketProviderProps = {
  children?: JSX.Element | JSX.Element[]
}

export default function MarketProvider({ children }: MarketProviderProps) {
  const {
    getMarketPopinUserPreferences,
    getMarketPopinDisplayed,
    setMarketPopinDisplayed,
  } = useMarketCookie()
  const mounted = useIsMountedState()
  const locale = useLocale()

  const [userPreferences, setUserPreferences] = useState(null)

  const cookieUserPreferences = getMarketPopinUserPreferences()
  const shouldShowMarketPopin = getMarketPopinDisplayed()

  // Checking the cookie on mount
  useEffect(() => {
    if (mounted) {
      if (!shouldShowMarketPopin) {
        if (!userPreferences && cookieUserPreferences) {
          setUserPreferences(cookieUserPreferences)
        }
      }
    }
  }, [mounted, shouldShowMarketPopin, userPreferences, cookieUserPreferences])

  // Locale state of the popin
  const [displayMarketPopin, setDisplayMarketPopin] = useState(null)
  const [isLocaleSameAsUserPreferences, setIsLocaleSameAsUserPreferences] =
    useState(null)

  // Need to display the popin
  const isSameLocale = locale === userPreferences?.locale

  useEffect(() => {
    setDisplayMarketPopin(shouldShowMarketPopin)
  }, [shouldShowMarketPopin])

  useEffect(() => {
    setIsLocaleSameAsUserPreferences(isSameLocale)
  }, [isSameLocale])

  const providerValue = useMemo(
    () => ({
      showPopin: shouldShowMarketPopin,
      isLocaleSameAsUserPreferences,
      userPreferences,
      displayMarketPopin,
      setDisplayMarketPopin,
    }),
    [displayMarketPopin],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

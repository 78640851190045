import classnames from 'classnames/bind'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { FACETS } from '~/lib/algolia/sync/set-settings'
import { TRACKING_EVENTS } from '~/lib/constants'

import { FacetsResult } from '@unlikelystudio/horizon-algolia'

import Checkbox, {
  CheckboxProps,
  CheckBoxType,
} from '~/components/Form/Checkbox'
import InlineCta from '~/components/UI/InlineCta'

import { useFilter } from '~/providers/FilterProvider'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import chunk from '~/utils/chunk'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const DEFAULT_VISIBLE_ITEMS = 4

export type FilterGroupProps = Omit<FacetsResult, 'values'> &
  Pick<FilterGroupCheckboxProps, 'inputType' | 'className' | 'subValues'> & {
    withViewMore?: boolean
    showAllItems?: boolean
    inputType?: CheckBoxType
    values?: {
      value?: string
      label?: string
    }[]
  }

type FilterGroupCheckboxProps = {
  className?: string
  inputType?: CheckBoxType
  type?: string
  value?: string
  label?: string
  subValues?: {
    type: string
    values: {
      [key: string]: {
        value?: string
        label?: string
      }[]
    }
  }
}

function FilterGroupCheckbox({
  className,
  type,
  value,
  label,
  inputType,
  subValues,
}: FilterGroupCheckboxProps) {
  const { filters } = useFilter()
  const defaultChecked =
    Array.isArray(filters?.[type]) &&
    (filters?.[type] as string[])?.includes(value)
  const [checked, setChecked] = useState<boolean>(defaultChecked)
  const tracker = useTracker()

  const checkboxStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
  })

  const defaultCheckboxProps = {
    theme: GlobalThemeColors.Black,
    textPreset: GlobalTextPreset.Text12GroteskMedium,
    type: inputType,
    isRound: inputType === CheckBoxType.CHECKBOX,
    isSquared: inputType === CheckBoxType.RADIO,
  }

  const trackEvent = (e) => {
    const { checked, name, value } = e.target ?? {}

    if (name === FACETS.CATEGORIES_V2) setChecked(checked)

    if (name && value) {
      const event = checked
        ? TRACKING_EVENTS.FILTERS_CHECK_FACET
        : TRACKING_EVENTS.FILTERS_UNCHECK_FACET

      tracker.emit(event, { type: name, value })
    }
  }

  return (
    <>
      <Checkbox
        {...(defaultCheckboxProps as CheckboxProps)}
        className={cx(className, checkboxStyle)}
        id={`${type}_${value}`}
        name={type}
        value={value}
        label={label}
        onClick={trackEvent}
      />
      {subValues?.values?.[value]?.length > 0 &&
        subValues?.values?.[value]?.map((subValue, index) => (
          <div
            key={`${type}_${subValue?.value}${index}`}
            className={cx(className, css.subValues, { visible: checked })}>
            <Checkbox
              {...(defaultCheckboxProps as CheckboxProps)}
              className={cx(checkboxStyle, css.subValue)}
              id={`${type}_${subValue?.value}`}
              name={subValues?.type}
              value={subValue?.value}
              label={subValue?.label}
              onClick={trackEvent}
              {...(!checked && { checked: false })}
            />
          </div>
        ))}
    </>
  )
}

function FilterGroup({
  className,
  type,
  values,
  withViewMore = true,
  inputType = CheckBoxType.CHECKBOX,
  showAllItems = false,
  subValues,
}: FilterGroupProps) {
  const t = useTranslate()
  const [showAll, setShowAll] = useState(false)

  const showViewMore = !showAll && withViewMore

  const inputValues =
    !showViewMore || showAllItems
      ? values
      : chunk(values, DEFAULT_VISIBLE_ITEMS)?.[0] ?? null

  return (
    <>
      {inputValues?.map((entry, index) => {
        return (
          <FilterGroupCheckbox
            key={`${type}_${index}`}
            className={className}
            inputType={inputType}
            type={type}
            subValues={subValues}
            {...entry}
          />
        )
      }) ?? null}
      {values?.length > DEFAULT_VISIBLE_ITEMS &&
        showViewMore &&
        !showAllItems && (
          <InlineCta
            className={cx(css.viewMore)}
            textPreset={GlobalTextPreset.Cta12Grotesk}
            onClick={() => setShowAll(true)}>{`${t(CTA.VIEW_ALL)} (${
            values?.length
          })`}</InlineCta>
        )}
    </>
  )
}

export default FilterGroup

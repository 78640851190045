import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { GlobalThemeColors } from '~/@types/colors'

export const PopInContext = createContext<PopInType>({})

type ProviderPopInProps = {
  children: JSX.Element | JSX.Element[]
}

export const PopInVerticalAlignement = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
} as const

type PopInVerticalAlignement =
  typeof PopInVerticalAlignement[keyof typeof PopInVerticalAlignement]

export const PopInHorizontalAlignement = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const

type PopInHorizontalAlignement =
  typeof PopInHorizontalAlignement[keyof typeof PopInHorizontalAlignement]

export type PopInOptions = {
  theme: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  withPadding: boolean
  withBackground?: boolean
  allowScroll?: boolean
  verticalAlignement?: PopInVerticalAlignement
  horizontalAlignement?: PopInHorizontalAlignement
}

export type PopInAction = {
  onClose?: () => void
  clickOutside?: boolean
}

export type PopIns = {
  content: JSX.Element
  options: PopInOptions
  actions: PopInAction
}

export type PopInType = {
  popIns?: PopIns[]
  add?: (
    popIn: JSX.Element,
    options?: Partial<PopInOptions>,
    actions?: Partial<PopInAction>,
  ) => void
  remove?: (popIn: JSX.Element) => void
  removeCurrent?: () => void
  position?: PositionPopIn
  setPosition?: React.Dispatch<React.SetStateAction<PositionPopIn>>
}

export type PositionPopIn = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

const DEFAULT_OPTIONS: PopInOptions = {
  theme: GlobalThemeColors.Black,
  withPadding: true,
  withBackground: true,
  allowScroll: false,
}

export default function PopInProvider({ children }: ProviderPopInProps) {
  const [popIns, setPopIns] = useState<PopIns[]>([])
  const [position, setPosition] = useState<PositionPopIn>({})

  const add = useCallback(
    (
      popIn: JSX.Element,
      options: Partial<PopInOptions> = {},
      actions: Partial<PopInAction> = { clickOutside: true },
    ) => {
      setPopIns((popIns) => [
        ...popIns,
        {
          content: popIn,
          options: {
            ...DEFAULT_OPTIONS,
            ...options,
          },
          actions,
        },
      ])
    },
    [],
  )

  const remove = useCallback((popIn: JSX.Element) => {
    setPopIns((popIns) => {
      const index = popIns.findIndex((p) => p.content === popIn)
      popIns?.[index]?.actions?.onClose?.()
      if (index > -1) popIns.splice(index, 1)
      return [...popIns]
    })
  }, [])

  const removeCurrent = useCallback(() => {
    setPopIns((popIns) => {
      popIns?.[popIns.length - 1]?.actions?.onClose?.()
      if (popIns.length > 0) popIns.pop()
      return [...popIns]
    })
  }, [popIns])

  const value: PopInType = useMemo(
    () => ({
      popIns,
      add,
      remove,
      removeCurrent,
      position,
      setPosition,
    }),
    [popIns, add, remove, removeCurrent, position, setPosition],
  )

  return <PopInContext.Provider value={value}>{children}</PopInContext.Provider>
}

export function usePopIn() {
  return useContext(PopInContext)
}

import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors, ThemeTextColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractInput, {
  InputProps as AbstratInputprops,
} from '~/components/Abstracts/Form/Input'
import {
  TInputPresets,
  useInputPreset,
} from '~/components/Abstracts/Form/Input/hooks'
import ErrorList from '~/components/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import { getCSSThemeClassName } from '~/utils/get-css-theme-classname'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type InputProps = AbstratInputprops & {
  className?: string
  inputClassName?: string
  preset?: TInputPresets
  inputStyle?: string
  theme?: ThemeTextColors
}

function Input({
  className,
  inputClassName,
  errorClassname,
  preset,
  inputStyle,
  theme,
  children,
  ...rest
}: InputProps) {
  const themeClassName = getCSSThemeClassName(css, theme, { suffix: 'Theme' })
  const defaultInputStyle = useStyle({
    color: GlobalThemeColors.Gray,
    textPreset: GlobalTextPreset.Input12GroteskRegular,
  })

  const props = useInputPreset(preset, rest)

  return (
    <div className={cx(css.Input, className, css?.[preset], themeClassName)}>
      <>
        <AbstractInput
          className={cx(
            css.input,
            inputStyle ? inputStyle : defaultInputStyle,
            inputClassName,
          )}
          errorClassname={cx(css.error, errorClassname)}
          {...props}
        />
        {children}
        <ErrorList className={cx(css.errorList)} name={props.name} />
      </>
    </div>
  )
}

Input.defaultProps = {}

export default Input

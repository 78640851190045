import classnames from 'classnames/bind'
import { useInView } from 'react-intersection-observer'
import { GlobalThemeColors } from '~/@types/colors'
import { NavigationProps } from '~/@types/navigation'

import { NavigationData } from '@unlikelystudio/react-abstract-components'
import { useGetCustomer } from '@unlikelystudio/react-ecommerce-hooks'

import NavigationDesktop from '~/components/Navigation/Navigation/NavigationDesktop'
import NavigationMobile from '~/components/Navigation/Navigation/NavigationMobile'
import NavBanner, {
  useNavBannerContext,
} from '~/components/Navigation/NavigationBanner'
import ScrollIntro from '~/components/UI/ScrollIntro'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useLogoScrollProvider } from '~/providers/LogoScrollProvider'

import useTextColorFromTheme from '~/hooks/useTextColorFromTheme'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function Navigation({
  className,
  banner,
  jsonLD,
  isHomePage,
  ...rest
}: NavigationProps) {
  const [inViewRef, inView] = useInView({ initialInView: true })
  const { theme } = useGlobalData()
  const navTheme = theme?.nav
  const { textColor: scrolledTheme } = useTextColorFromTheme()
  const { data: customer } = useGetCustomer()

  const hasScrolled = theme?.navHasForcedBackground || !inView

  const { logoState, transitionEnded } = useLogoScrollProvider()
  const [isVisible] = useNavBannerContext()

  return (
    <>
      <div ref={inViewRef} className={css.NavigationScrollRef} />
      {jsonLD && <NavigationData {...jsonLD} />}
      <header
        className={cx(
          css.Navigation,
          className,
          navTheme,
          `${scrolledTheme}ThemeScroll`,
          {
            hideLogo: isHomePage && !logoState,
            noClickLogo: isHomePage && !transitionEnded && !logoState,
          },
        )}>
        {isVisible && <NavBanner {...banner} className={css.banner} />}
        <div className={cx(css.container)}>
          <NavigationDesktop
            className={cx(css.desktopNavigation, css.hideOnSmallScreen)}
            logoClassName={cx(css.logo)}
            hasScrolled={hasScrolled}
            theme={navTheme ?? GlobalThemeColors.Black}
            scrolledTheme={scrolledTheme}
            isLoggedIn={Boolean(customer)}
            {...rest}
          />
          <NavigationMobile
            className={cx(css.mobileNavigation, css.hideOnLargeScreen)}
            hasScrolled={hasScrolled}
            logoClassName={cx(css.logo)}
            theme={navTheme ?? GlobalThemeColors.Black}
            scrolledTheme={scrolledTheme}
            isLoggedIn={Boolean(customer)}
            {...rest}
          />
        </div>
      </header>
      {isHomePage && <ScrollIntro />}
    </>
  )
}

Navigation.defaultProps = {}

export default Navigation

import Head from 'next/head'
import { DEFAULT_LOCALE } from '~/lib/constants'

import { ImageProps } from '~/components/UI/Image'

export type Language = {
  id: string
  uid: string
  lang: string
  regionLang?: string
  url: string
  type?: string
  locale: string
}

export type MetaProps = Language & {
  alternateLanguages: Language[]
  title?: string
  description?: string
  keywords?: string
  paginationPrev?: number
  paginationNext?: number
  page?: number
  image?: ImageProps
  asDuplicatedAlternateDefaultLang?: boolean
}

function generateAlternateMeta(language: Language) {
  return (
    <link
      rel="alternate"
      href={language?.url}
      hrefLang={
        language?.locale?.includes('ww') ? language?.regionLang : language?.lang
      }
      key={`alternate-${language?.locale}`}
    />
  )
}

function Meta(meta: MetaProps) {
  const {
    title,
    description,
    image,
    url,
    alternateLanguages,
    page,
    paginationPrev,
    paginationNext,
    keywords,
  } = meta

  const metaTitle = title ? `${title}` : ''
  const metaDesc = description ?? null
  const metaImage = image ?? null
  const metasUrl = url ?? null
  const metaKeywords = keywords ?? null

  // Get default alternate from locale
  const xDefault = alternateLanguages?.find(
    (item) => item.locale === DEFAULT_LOCALE,
  )

  return meta ? (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${metaTitle}`}</title>
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={`${metaTitle}`} key="title" />
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      {metaImage && <meta property="og:image" content={metaImage?.src} />}

      {alternateLanguages
        ?.filter(
          (item) =>
            meta.asDuplicatedAlternateDefaultLang ??
            item.locale !== DEFAULT_LOCALE,
        )
        ?.map((language) => generateAlternateMeta(language))}
      {/* Genereate x-default alternate */}
      {generateAlternateMeta({
        ...xDefault,
        lang: 'x-default',
      })}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metasUrl} />
      <meta property="twitter:title" content={`${metaTitle}`} />
      {metaDesc && <meta property="twitter:description" content={metaDesc} />}
      {metaImage && <meta property="twitter:image" content={metaImage?.src} />}
      {paginationPrev && metasUrl && (
        <link rel="prev" href={`${metasUrl}/${paginationPrev}`} />
      )}
      {paginationNext && metasUrl && (
        <link rel="next" href={`${metasUrl}/${paginationNext}`} />
      )}
      {metasUrl && (
        <link
          rel="canonical"
          href={`${metasUrl}${page ? `/${page}` : ''}`}
          key={`canonical-${metasUrl}`}
        />
      )}
    </Head>
  ) : null
}

export default Meta

export default function chunk<T>(
  array: T[] | null | undefined,
  size?: number | number[],
): T[][] {
  if (!array || !size) return null
  const processedArray = [...array]

  if (typeof processedArray !== 'object') {
    console.error('[chunk] array argument must be an array')
    return null
  }

  if (typeof size === 'string') {
    console.error('[chunk] array argument must be a number or an array')
    return null
  }

  const chunked = []
  let index = 0

  while (processedArray.length > 0) {
    const splitSize =
      typeof size === 'number' ? size : size[index % size.length]

    if (typeof splitSize !== 'number') {
      console.error(
        '[chunk] size argument must be a number or an array of number',
      )
      return null
    }

    index++
    chunked.push(processedArray.splice(0, splitSize))
  }

  return chunked
}

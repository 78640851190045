import Cookies from 'js-cookie'
import { COOKIE_KEYS } from '~/lib/constants'

import useCurrency from '~/hooks/useCurrency'
import useLocale from '~/hooks/useLocale'

export default function useWishlistId() {
  const locale = useLocale()
  const currency = useCurrency()
  const wishlistId = Cookies.get(COOKIE_KEYS.wishlistId(currency, locale))
  return wishlistId
}

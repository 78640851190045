import { PathRegExp } from '@marvinh/path-to-regexp'

/**
 * Build an url from a PathRegExp instance with custom parameters.
 * Added handling of wildcard parameters using :slug*
 */
export function createUrl(
  reg: PathRegExp,
  params: Record<string, string | number> = {},
) {
  let path = reg.path

  for (let i = 0; i < reg.params.length; i++) {
    // We need to replace * in patterns like :slug* which is not currently supported in the lib
    const name =
      reg.params[i]?.slice(-1) === '*'
        ? reg.params[i].replace('*', '')
        : reg.params[i]
    const needle = reg.params[i] === '*' ? '*' : ':' + reg.params[i]

    path = path.replace(needle, params[name] as any)
  }

  return path
}

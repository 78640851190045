import classnames from 'classnames/bind'
import React from 'react'
import { GlobalTextPreset } from '~/@types/text-preset'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type InformationTagProps = {
  className?: string
  label?: string
}

function InformationTag({ className, label }: InformationTagProps) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12GroteskRegular,
  })
  return (
    <div className={cx(css.InformationTag, className)}>
      <span className={labelStyle}>{label}</span>
    </div>
  )
}

InformationTag.defaultProps = {}

export default InformationTag

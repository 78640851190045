import type { UnlikelyCustomAttributes } from '@unlikelystudio/commerce-connector'

export function attributesAsObject(attributes: UnlikelyCustomAttributes): {
  [key: string]: string
} {
  return attributes
    ?.map(({ key, value }) => ({
      [key]: value,
    }))
    ?.reduce((obj, current) => {
      return {
        ...obj,
        ...current,
      }
    }, {})
}

export function objectAsAttributes(attributes: {
  [key: string]: string
}): UnlikelyCustomAttributes {
  return Object.keys(attributes ?? {})?.map((key) => ({
    key,
    value: attributes[key],
  }))
}

import { AlertPreset, ALERT_CODES } from '~/@types/alert'

export type Errors = Record<string, AlertPreset>

export default function useCustomerErrors() {
  const pushToObj = (key, obj, el) => {
    if (obj[key]) obj[key].push(el)
    else obj[key] = [el]
  }

  return (errors: any, formSetError?: any) => {
    const errsFields: Errors = {}
    const errsGlobals: Errors = {}

    const handleErrorMapping = (err) => {
      const fieldName = err?.field?.[1]
      if (fieldName && err?.code) pushToObj(fieldName, errsFields, err.code)
      else if (err?.code) errsGlobals[err.code] = err.code
      else errsGlobals[ALERT_CODES.SERVER_ERROR] = ALERT_CODES.SERVER_ERROR
    }

    if (errors?.payload) {
      errors.payload?.forEach(handleErrorMapping)
    }

    if (errors?.response) {
      errors?.response?.errors?.forEach((err) => {
        handleErrorMapping({
          ...err,
          ...(err?.extensions ?? {}),
        })
      })
    }

    if (errors?.payload || errors?.response) {
      Object.keys(errsFields)?.map((key) => {
        const obj = errsFields[key]

        if (formSetError) {
          formSetError(key, {
            types: obj,
          })
        }
      })
    }

    return {
      errsFields,
      errsGlobals,
    }
  }
}

import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { COOKIE_KEYS } from '~/lib/constants'

import { useGeolocation } from '~/providers/GeolocationProvider'

export default function useGeolocationCookie() {
  const GLOBAL_KEY = COOKIE_KEYS.globalGeolocationPopIn
  const CART_KEY = COOKIE_KEYS.cartGeolocationPopIn
  const { setDisplayGlobalPopIn, setDisplayCartPopIn } = useGeolocation()

  const acceptGlobalGeoLocation = useCallback(() => {
    Cookies.set(GLOBAL_KEY, '1', { expires: 365 })
    setDisplayGlobalPopIn(false)
  }, [])

  const getGlobalGeoLocation = useCallback(() => {
    return Cookies.get(GLOBAL_KEY) === '1'
  }, [])

  const acceptCartGeoLocation = useCallback(() => {
    Cookies.set(CART_KEY, '1', { expires: 365 })
    setDisplayCartPopIn(false)
  }, [])

  const getCartGeoLocation = useCallback(() => {
    return Cookies.get(CART_KEY) === '1'
  }, [])

  return {
    acceptGlobalGeoLocation,
    getGlobalGeoLocation,
    acceptCartGeoLocation,
    getCartGeoLocation,
  }
}

import multistore from '~/../config/multistore.json'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CURRENCY,
  DEFAULT_INTL,
  DEFAULT_LOCALE,
  DEFAULT_MARKET,
  LOCALES,
} from '~/lib/constants'
import getCurrency from '~/lib/get-currency'

import { ShopifySFLanguageCode } from '@unlikelystudio/commerce-connector'

export function getLang(locale: string) {
  return multistore?.[locale]?.lang ?? locale
}

export function getStoryblokShopifyKey(locale: string = DEFAULT_LOCALE) {
  const currency = getCurrency(locale) ?? DEFAULT_CURRENCY
  return `shopify_product_${currency}`
}

export function getAllStoryblokShopifyKeys() {
  return LOCALES.map((locale) => getStoryblokShopifyKey(locale)) ?? []
}

export function getStoryblokCardPriceKey(locale: string = DEFAULT_LOCALE) {
  const currency = getCurrency(locale) ?? DEFAULT_CURRENCY
  return `card_price_${currency ?? DEFAULT_CURRENCY}`
}

export function getAllStoryblokCardPriceKeys() {
  return LOCALES.map((locale) => getStoryblokCardPriceKey(locale)) ?? []
}

export function getIntlLocale(locale: string) {
  return multistore?.[locale ?? DEFAULT_LOCALE]?.intl ?? DEFAULT_INTL
}

export function getCountryName(locale: string) {
  return multistore?.[locale ?? DEFAULT_LOCALE]?.name
}

export function getCountryCode(locale: string) {
  return (
    multistore?.[locale ?? DEFAULT_LOCALE]?.countryCode ?? DEFAULT_COUNTRY_CODE
  )
}

export function getShopifyLanguageCodeFromLocale(
  locale: string,
): ShopifySFLanguageCode {
  const lang = getLang(locale)?.toUpperCase()
  return lang as ShopifySFLanguageCode
}

export function getMarketName(locale: string) {
  return multistore?.[locale ?? DEFAULT_LOCALE]?.market ?? DEFAULT_MARKET
}

export function getShopifyPriceKey(locale: string) {
  const marketName = getMarketName(locale)
  return `price_${marketName}`
}

export function isDefaultLocale(locale: string) {
  return locale === DEFAULT_LOCALE
}

import React, { useEffect, useState, useCallback } from 'react'

import PopIn from '~/components/Abstracts/PopIn'
import MarketsPopin, {
  MarketsPopinProps,
} from '~/components/Popins/MarketsPopin'
import { CloseIcon } from '~/components/UI/Icons'

import { useMarketContext } from '~/providers/MarketProvider'
import { usePopIn } from '~/providers/PopInProvider'

import useMarketCookie from '~/hooks/useMarketCookie'

import { isStoryblokPreview } from '~/utils/is-storyblok-preview'

export default function useMarketPopin(marketPopin: MarketsPopinProps) {
  const { add, removeCurrent } = usePopIn()
  const { displayMarketPopin, setDisplayMarketPopin } = useMarketContext()

  const { setMarketPopinUserPreferences, setMarketPopinDisplayed } =
    useMarketCookie()

  const [hasFirstClick, setHasFirstClick] = useState(false)

  const firstClick = useCallback(() => setHasFirstClick(true), [])

  useEffect(() => {
    window.addEventListener('click', firstClick)
    return () => window.removeEventListener('click', firstClick)
  }, [firstClick])

  useEffect(() => {
    if (isStoryblokPreview) return

    if (displayMarketPopin) {
      add(
        <PopIn
          isCloseButton
          onClickClose={() => {
            setDisplayMarketPopin?.(false)
            setMarketPopinDisplayed?.(false)
          }}
          closeButton={<CloseIcon />}>
          <MarketsPopin
            {...marketPopin}
            onGlobalChange={(data) => {
              setMarketPopinUserPreferences?.(data)
              setDisplayMarketPopin?.(false)
              setMarketPopinDisplayed?.(false)
              removeCurrent?.()
            }}
          />
        </PopIn>,
        null,
        {
          clickOutside: true,
          onClose: () => {
            setDisplayMarketPopin?.(false)
            setMarketPopinDisplayed?.(false)
          },
        },
      )
    }
  }, [hasFirstClick, add, displayMarketPopin, isStoryblokPreview])
}

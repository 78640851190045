import React from 'react'

import AbstractImage, { ImageProps } from '~/components/Abstracts/Image'
import { imageLoaderStoryblok } from '~/components/Abstracts/Image/loader'

import css from './styles.module.scss'

function Image(props: ImageProps) {
  return (
    <AbstractImage
      placeholderClassName={css.placeholder}
      loader={imageLoaderStoryblok}
      {...props}
    />
  )
}

Image.defaultProps = { asPlaceholder: true }

export type { ImageProps } from '~/components/Abstracts/Image'
export { imageLoaderStoryblok } from '~/components/Abstracts/Image/loader'
export type { Sizes } from '~/components/Abstracts/Image/maths'

export default Image

export const GlobalInputPreset = {
  AddressName: 'addressName',
  City: 'city',
  Country: 'country',
  Email: 'email',
  FirstName: 'firstName',
  InfoAddress: 'infoAddress',
  LastName: 'lastName',
  NewPassword: 'newPassword',
  Password: 'password',
  Region: 'region',
  RepeatPassword: 'repeatPassword',
  RepeatNewPassword: 'repeatNewPassword',
  Search: 'search',
  State: 'state',
  StreetName: 'streetName',
  Tel: 'tel',
  ZipCode: 'zipCode',
} as const

export type GlobalInputPreset =
  typeof GlobalInputPreset[keyof typeof GlobalInputPreset]

import { createContext, ReactNode, useContext, useState } from 'react'

const BodyScrollLockContext = createContext(null)
export type BodyScrollLockContextProviderProps = {
  children?: ReactNode | ReactNode[]
  enabled?: boolean
}
export function BodyScrollLockContextProvider({
  children,
  enabled,
}: BodyScrollLockContextProviderProps) {
  const useBodyScrollLockState = useState(enabled)
  return (
    <BodyScrollLockContext.Provider value={useBodyScrollLockState}>
      {children}
    </BodyScrollLockContext.Provider>
  )
}

export function useBodyScrollLockContext() {
  return useContext(BodyScrollLockContext)
}

let previousBodyPosition = null
let element = null

if (typeof window !== 'undefined') {
  element = document.getElementById('__next')
}

const restoreBodyPosition = () => {
  if (previousBodyPosition) {
    // Convert the position from "px" to Int
    const y = -parseInt(element.style.top, 10)
    const x = -parseInt(element.style.left, 10)

    // Restore styles
    element.style.position = previousBodyPosition.position
    element.style.top = previousBodyPosition.top
    element.style.left = previousBodyPosition.left

    // Restore scroll
    window.scrollTo(x, y)

    previousBodyPosition = null
  }
}

const lockScroll = () => {
  if (!previousBodyPosition) {
    previousBodyPosition = {
      position: element.style.position,
      top: element.style.top,
      left: element.style.left,
    }
  }

  const { scrollY, scrollX, innerHeight } = window
  element.style.position = 'fixed'
  element.style.top = `${-scrollY}px`
  element.style.left = `${-scrollX}px`
  element.style.width = `100%`

  setTimeout(
    () =>
      window.requestAnimationFrame(() => {
        // Attempt to check if the bottom bar appeared due to the position change
        const bottomBarHeight = innerHeight - window.innerHeight

        if (bottomBarHeight && scrollY >= innerHeight) {
          // Move the content further up so that the bottom bar doesn't hide it
          element.style.top = `${-(scrollY + bottomBarHeight)}px`
        }
      }),
    300,
  )
}

const unlockScroll = () => {
  restoreBodyPosition()
}

export { lockScroll, unlockScroll }

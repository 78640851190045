import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { BottomFirstLevelLinks } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'

import RoundedCta from '~/components/UI/RoundedCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type NavigationBottomLinksProps = BottomFirstLevelLinks & {
  className?: string
}

function NavigationBottomLinks({
  className,
  title,
  links,
}: NavigationBottomLinksProps) {
  const panelBottomTitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })

  return (
    <div className={cx(css.NavigationBottomLinks, className)}>
      {title && (
        <span className={cx(css.panelBottomTitle, panelBottomTitleStyle)}>
          {title}
        </span>
      )}
      <div className={css.panelBottomLinks}>
        {links?.map((link, index) => {
          return (
            <RoundedCta
              {...link}
              className={css.panelBottomLinkCta}
              textPreset={GlobalTextPreset.Cta16_12SangBleu}
              theme={GlobalThemeColors.Gray}
              key={`bottom_link_${index}`}
            />
          )
        })}
      </div>
    </div>
  )
}

NavigationBottomLinks.defaultProps = {}

export default NavigationBottomLinks
